

export class QueueRequest {
  tenant: string;
  action: string;
  userId: string;// "00000000-0000-0000-0000-000000000000",
  pageIndex: number;
  pageSize: number;
  queueIds: Array<string>;
  nodeIds: Array<string>;
  includeDeferred: boolean;
  isGetNextEnvelope: boolean;
  envelopeId: number;
  caseNumber: string;
  fromDate: string;
  toDate: string;
}
