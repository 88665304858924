import { Injectable } from '@angular/core';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DfcService {
  public isDfcReady: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  public dataFieldConfigs: iDataFieldConfig[] | undefined;

  constructor() { }

  getConfig(code: string, isVisible: boolean, isReadOnly: boolean = false, isRequired: boolean = false, regularExpression: string = '', validationErrorMessage = '') {
    var idx = this.dataFieldConfigs.findIndex(dfc => dfc.code == code);
    if (idx > -1) {
      return this.dataFieldConfigs[idx];
    }
    else {
      return new DataFieldConfigDefaults(isVisible, isReadOnly, isRequired, undefined, undefined, undefined, regularExpression, undefined, validationErrorMessage);
    }
  }
}
