import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { of as observableOf, of } from 'rxjs';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { FilingService } from 'src/app/common/service/filing.service';
import { Subscription } from 'rxjs';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { EnvelopeService } from 'src/app/common/service/envelope.service';

export class FileNode {
  children: FileNode[];
  originalFileName: string;
  fileID: string;
  type: any;
}

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit, OnDestroy {
  @ViewChild('tree') tree;

  fileTrees: FileTree[] = [];
  selectedDocument: FileTree;

  nestedDataSource: FileTree[];
  getChildren = (node: FileTree) => of(node.children);
  nestedTreeControl = new NestedTreeControl(this.getChildren);
  hasChild(_: number, node: FileTree) {
    return node.children != null && node.children.length > 0;
  }

  isFailedLock: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(private filingService: FilingService, private webviewerService: WebviewerService, private envelopeService: EnvelopeService) { }

  ngOnInit() {
    this.envelopeService.isFailedLock.subscribe(isFailedLock => {
      this.isFailedLock = isFailedLock
      this.webviewerService.selectedDocument.next(undefined);
    });
    this.subscriptions.push(this.webviewerService.documentFileTree.subscribe(result => {
      if (!result) {
        this.fileTrees = [];
      }
      else {
        this.fileTrees = result;
        this.nestedDataSource = this.fileTrees;
        this.nestedTreeControl.dataNodes = this.nestedDataSource;
        if (this.tree && this.tree.treeControl) {
          this.tree.treeControl.expandAll();
        }
        if (this.fileTrees[0] && this.fileTrees[0].children && this.fileTrees[0].children[0]) {
          //Autoselect the first filing's first document
          var tree = this.fileTrees[0].children[0];
          tree.isViewed = true;
          this.webviewerService.selectedDocument.next(tree);
          this.filingService.selectedFilingId.next(tree.filingId);
       }
      }
    }));
  }

  ngAfterViewInit() {
    if (this.nestedDataSource && this.nestedTreeControl.dataNodes) {
      this.tree.treeControl.expandAll();
    }
  }

  selectDocument(node: FileTree) {
    var isNewDocument = true;
    if (this.selectedDocument && this.selectedDocument.id == node.id) {
      isNewDocument = false;
    }
    node.isManuallySelected = true;
    this.selectedDocument = node;
    if (isNewDocument) {
      this.webviewerService.selectedDocument.next(node);
      this.filingService.selectedFilingId.next(node.filingId);
    }
 }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
