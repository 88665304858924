import { BaseRequest } from 'src/app/common/request/base-request';

export class FilingInput extends BaseRequest{
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public filingId: number
  )
  { super(tenant, messageId, connectionId, action) }
}

