export enum FilingState {
  Draft = 0,
  Submitted = 1,
  Submitting = 2,
  UnderReview = 3,
  Reviewed = 4,
  RejectedByCourt = 5,
  Error = 6,
  Cancelled = 7,
  Deferred = 8,
  AcceptedByCourt = 9,
  Served = 10,
  SubmissionFailed = 11,
  ManualAccept = 12,
  Granted = 13,
  Denied = 14,
  Moot = 15,
  Receipted = 16,
  DraftUnderReview = 17,
  Returned = 18,
  Deleted = 19
}

export class FilingStateHelper {

  //todo validate these states
  isActionCompleted(filingState: number) {
    if (filingState == FilingState.Reviewed ||
      filingState == FilingState.AcceptedByCourt ||
      filingState == FilingState.RejectedByCourt ||
      filingState == FilingState.SubmissionFailed ||
      filingState == FilingState.ManualAccept ||
      filingState == FilingState.Receipted ||
      filingState == FilingState.Granted ||
      filingState == FilingState.Moot ||
      filingState == FilingState.Denied) {
      return true;
    }
    else {
      return false;
    }
  }

  friendlyName(filingState: number) {
    switch (filingState) {
      case FilingState.Draft: return 'Draft';
      case FilingState.Submitted: return 'Submitted';
      case FilingState.AcceptedByCourt: return 'Accepted';
      case FilingState.RejectedByCourt: return 'Rejected';
      case FilingState.SubmissionFailed: return 'Failed';
      case FilingState.ManualAccept: return 'Manually Accepted';
      case FilingState.Cancelled: return 'Cancelled';
      case FilingState.Deferred: return 'Deferred';
      case FilingState.Submitting: return 'Submitting';
      case FilingState.Reviewed: return 'Reviewed';
      case FilingState.Served: return 'Served';
      case FilingState.UnderReview: return 'Under Review';
      case FilingState.Receipted: return 'Receipted';
      case FilingState.Granted: return 'Granted';
      case FilingState.Moot: return 'Moot';
      case FilingState.Denied: return 'Denied';
      case FilingState.Returned: return 'Returned';
      case FilingState.Error: return 'Error';
    }
  }

  //use this when localization in place
  //friendlyName(strings: Strings, filingState: FilingState): BehaviorSubject<string> {
  //  switch (filingState) {
  //    case FilingState.Draft: return strings.T('Draft');
  //    case FilingState.Submitted: return strings.T('Submitted');
  //    case FilingState.AcceptedByCourt: return strings.T('Accepted');
  //    case FilingState.RejectedByCourt: return strings.T('Rejected');
  //    case FilingState.SubmissionFailed: return strings.T('Submission Failed');
  //    case FilingState.ManualAccept: return strings.T('Manually Accepted');
  //    case FilingState.Cancelled: return strings.T('Cancelled');
  //    case FilingState.Deferred: return strings.T('Deferred');
  //    case FilingState.Submitting: return strings.T('Submitting');
  //    case FilingState.Reviewed: return strings.T('Reviewed');
  //    case FilingState.Served: return strings.T('Served');
  //    case FilingState.UnderReview: return strings.T('Under Review');
  //    case FilingState.Receipted: return strings.T('Receipted');
  //    case FilingState.Granted: return strings.T('Granted');
  //    case FilingState.Moot: return strings.T('Moot');
  //    case FilingState.Denied: return strings.T('Denied');
  //    case FilingState.Returned: return strings.T('Returned');
  //    case FilingState.Error: return strings.T('Error');
  //  }
  //}
}
