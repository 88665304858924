import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { FilingService } from 'src/app/common/service/filing.service';
import { FeesService } from 'src/app/common/service/fees.service';
import { Strings } from 'src/app/common/localization/strings';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { iEnvelope } from 'src/app/common/model/envelope/envelope';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { iServiceContact } from 'src/app/common/model/envelope/service-contact';
import { iDataFieldConfig } from 'src/app/common/model/config/data-field-config';
import { iOrgChart } from 'src/app/common/model/config/org-chart';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { IFeeAndPaymentInformation } from 'src/app/common/model/envelope/fee-and-payment-information';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { iPartyTypeCode } from 'src/app/common/model/config/party-type-code';
import { iAttorney } from 'src/app/common/model/data/attorney';
import { PartyService } from 'src/app/common/service/party.service';
import { ServiceContactService } from 'src/app/common/service/service-contact.service';
import { iParty } from 'src/app/common/model/envelope/party';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { iTenantConfig } from 'src/app/common/model/config/tenant-config';
import { iFilingCode } from 'src/app/common/model/config/filing-code';
import { DfcService } from 'src/app/common/service/dfc.service';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { EnvelopeInput } from 'src/app/common/request/envelope-input';
import { CaseTypeInput } from 'src/app/common/request/case-type-input';
import { WorkItemInput } from 'src/app/common/request/workitem-input';
import { EnvelopePagingInput } from 'src/app/common/request/envelope-paging-input';
import { FilingPagingInput } from 'src/app/common/request/filing-paging-input';
import { PartyPagingInput } from 'src/app/common/request/party-paging-input';
import { FilingCodeInput } from 'src/app/common/request/filing-code-input';
import { BaseRequest } from 'src/app/common/request/base-request';
import { NodeInput } from 'src/app/common/request/node-input';
import { iMessage } from 'src/app/common/response/message';
import { iCase } from 'src/app/common/model/envelope/case';
import { iParties } from 'src/app/common/model/envelope/parties';
import { PagedModel } from 'src/app/common/model/response/paged-model';
import { EditPartyComponent } from './party-information/edit-party/edit-party.component';
import { Router } from '@angular/router';
import { QueueService } from 'src/app/common/service/queue.service';

@Component({
  selector: 'app-envelope-view',
  templateUrl: './envelope-view.component.html',
  styleUrls: ['./envelope-view.component.css']
})
export class EnvelopeViewComponent implements OnInit, OnDestroy {
  public form: FormGroup = new FormGroup({});
  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();
  @Output() envPartyButtonEvent: EventEmitter<{
    component: string, key: number, partyTypes: iPartyTypeCode[], suffixes: iConfigCode[], countries: iConfigCode[], attorneys: iAttorney[],
    dlTypes: iConfigCode[], genders: iConfigCode[], languages: iConfigCode[], isNewParty: boolean
  }> =
    new EventEmitter<{
      component: string, key: number, partyTypes: iPartyTypeCode[], suffixes: iConfigCode[], countries: iConfigCode[], attorneys: iAttorney[],
      dlTypes: iConfigCode[], genders: iConfigCode[], languages: iConfigCode[], isNewParty: boolean
    }>();

  @ViewChild('editParty', { static: false }) editParty: EditPartyComponent | undefined;

  envelopeId: number = 0;
  workItemId: number = 0;
  envelopeIdRoute?: number;
  caseData: iCase;
  isFailedLock: boolean;

  //EditParty properties
  partyId: number;
  countries: iConfigCode[] | undefined;
  isNewParty: boolean;
  parties: iParty[];

  docVisible: boolean = true;
  caseVisible: boolean = true;
  envVisible: boolean = false;
  partyVisible: boolean = false;
  editPartyVisible: boolean = false;
  chargesVisible: boolean = false;
  fileVisible: boolean = false;
  editFileVisible: boolean = false;
  serveVisible: boolean = false;
  feesVisible: boolean = false;
  notesVisible: boolean = false;

  isCaseActive: boolean = true;
  isEnvelopeActive: boolean = false;
  isPartyActive: boolean = false;
  isFileActive: boolean = false;
  isServeActive: boolean = false;
  isFeesActive: boolean = false;

  key: number;
  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  partyTypes: iPartyTypeCode[] | undefined;
  suffixes: iConfigCode[] | undefined;
  attorneys: iAttorney[] | undefined;
  dlTypes: iConfigCode[] | undefined;
  dlStates: iConfigCode[] | undefined;
  genders: iConfigCode[] | undefined;
  languages: iConfigCode[] | undefined;

  isPartyTypesLoaded: boolean;
  isSuffixesLoaded: boolean;
  isDlTypesLoaded: boolean;
  isGendersLoaded: boolean;
  isLanguagesLoaded: boolean;

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, private filingService: FilingService, private webviewerService: WebviewerService, private dfcService: DfcService,
    private partyService: PartyService, private toaster: CustomToastrService, private strings: Strings, private serviceContactService: ServiceContactService, private feesService: FeesService,
    private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper, private router: Router, private route: ActivatedRoute, private queueService: QueueService) { }
  
  ngOnInit() {
    this.getApiMessage();

    var envelopeIdRoute = this.queueService.envelopeId;
    var workItemIdRoute = this.queueService.workItemId;

    if (!envelopeIdRoute) {
      this.envelopeId = 3450;
      this.envelopeService.envelopeMetadata.next({ envelopeId: 3450, workitemId: 3065 }); 
      this.queueService.envelopeId = 3450;
      this.queueService.workItemId = 3065;
   }
    else {
      if (envelopeIdRoute && +envelopeIdRoute !== this.envelopeId) {
        this.envelopeId = +envelopeIdRoute;
        this.workItemId = +workItemIdRoute;
        this.envelopeService.envelopeMetadata.next({ envelopeId: this.envelopeId, workitemId: this.workItemId });
      }
    }

    this.subscriptions.push(this.envelopeService.envelopeMetadata.subscribe(meta => {
      if (!meta) {
        return;
      }
      if (this.envelopeId !== meta.envelopeId) {
        this.envelopeId = meta.envelopeId;
        this.caseService.caseToReview.next(undefined);
        this.envelopeService.envelopeToReview.next(undefined);
        this.ngOnDestroy();
        this.router.navigateByUrl('/refresh').then(() => {
          this.queueService.envelopeId = meta.envelopeId;
          this.queueService.workItemId = meta.workitemId;
          this.router.navigate(["/viewer"]); 
        }); 
        return;
      }
      this.envelopeId = meta.envelopeId;
      this.workItemId = meta.workitemId;
      this.isFailedLock = false;
      this.envelopeService.isFailedLock.next(false);

      this.parties = [];
      this.partyService.parties.next(undefined);
      this.partyService.numberOfResultsNew.next(undefined);
      this.partyService.numberOfResultsExisting.next(undefined);
      this.caseService.caseToReview.next(undefined);
      this.webviewerService.documentFileTree.next(undefined);

      this.isPartyTypesLoaded = this.isSuffixesLoaded = this.isDlTypesLoaded = this.isGendersLoaded = this.isLanguagesLoaded = false;

      if (this.caseData) {
        this.callCaseApis();
      }

      const lockInput = new WorkItemInput(null, null, null, 'lockenvelope', this.workItemId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(lockInput));

      const inputCase = new EnvelopeInput(null, null, null, 'getcase', meta.envelopeId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCase));

      const documentDownloadApi = localStorage.getItem('documentDownloadApi');
      if (!documentDownloadApi || documentDownloadApi.indexOf('http') < 0) {
        var docDownloadInput = new BaseRequest(null, null, null, 'gettenantconfiguration');
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(docDownloadInput));
      }

      const inputEnvelope = new EnvelopeInput(null, null, null, 'getenvelope', this.envelopeId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputEnvelope));

      const inputDfc = new NodeInput(null, null, null, 'getdatafieldcodes', 0);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputDfc));

      const feesInput = new EnvelopeInput(null, null, null, 'getfees', this.envelopeId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(feesInput));

      const inputService = new EnvelopePagingInput(null, null, null, 'getservices', this.envelopeId, 0, 200);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputService));

      var countryInput = new NodeInput(null, null, null, 'getcountrycodes', 0);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(countryInput));
   }));
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getcase': {
            if (message.isSuccess === true) {
              this.caseData = message.taskresult as iCase;
              this.partyService.totalParties.next(this.caseData.partyCount);
              this.callCaseApis();
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting case: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'lockenvelope': {
            if (message.isSuccess === true) {
              this.isFailedLock = false;
              this.envelopeService.isFailedLock.next(false);
            }
            else {
              this.isFailedLock = true;
              this.envelopeService.isFailedLock.next(true);
              this.webviewerService.documentFileTree.next(undefined);
              if (message.error.cause.indexOf('Cannot insert') > -1) { //don't send sql msg
                message.error.cause = '';
              }
              this.toaster.pop('error', 'Error', 'An error occurred locking the envelope: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getenvelope': {
            if (message.isSuccess === true) {
              this.envelopeService.envelopeToReview.next(message.taskresult as iEnvelope)
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting envelope: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getparties': {
            if (message.isSuccess === true) {
              const parties = message.taskresult as iParties;
              this.partyService.parties.next(parties);
              this.partyService.numberOfResultsNew.next(parties.newParties.totalItems);
              this.partyService.numberOfResultsExisting.next(parties.existingParties.totalItems);

              if (parties.newParties && parties.newParties.totalItems) {
                parties.newParties.items.forEach(party => {
                  if (!party.partyName) {
                    party.isError = true;
                  }
                });
              }

              //allParties is temporary. need an api that returns all parties
              const allParties = parties.newParties.items && parties.existingParties.items ? parties.newParties.items.concat(parties.existingParties.items) :
                parties.newParties.items ? parties.newParties.items :
                  parties.existingParties.items
              //Only happens in dev if bad envelopeid entered
              if (!allParties) {
                break;
              }
              allParties.forEach(p => p.partyName = !p.partyName ? '' : p.partyName);
              this.parties = allParties.sort((a, b) => a.partyName.localeCompare(b.partyName));
              if (this.caseData) {
                this.caseData.parties = this.parties;
                this.caseService.caseToReview.next(this.caseData);
              }
              allParties.forEach((p) => {
                if (p.isError && !p.partyTypeCode.description) {
                  this.toaster.pop('error', 'Error', 'Party ' + p.partyName + ' has an invalid party type.');  
                }
              });
              if (allParties.some(p => p.isError && !p.partyName)) {
                this.toaster.pop('error', 'Error', 'One or more parties have incomplete names.'); 
              }
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting parties: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getfilings': {
            if (message.isSuccess === true) {
              const filings = message.taskresult as PagedModel<iFiling>;
              this.buildFileTree(filings.items);
              this.filingService.filings.next(filings);

              if (filings.items.some(f => f.isError && !f.filingCode.description)) {
                this.toaster.pop('error', 'Error', 'One more filings has an invalid filing code.');
              }   
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting filings: ' + message.error.code + ': ' + message.error.cause);
              this.sendErrorMessage(message, "filings");
           }
            break;
          }
          case 'getservices': {
            if (message.isSuccess === true) {
              this.serviceContactService.serviceContacts.next(message.taskresult as PagedModel<iServiceContact>)
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting service contacts: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getfees': {
            if (message.isSuccess === true) {
              const feesAndPayment = message.taskresult as IFeeAndPaymentInformation;
              this.feesService.fees.next(feesAndPayment);
            }
            else {
              this.sendErrorMessage(message, "fees");
            }
            break;
          }
          case 'getfilingcodes': {
            if (message.isSuccess === true) {
              var filingCodes = message.taskresult.items as iFilingCode[];
              this.filingService.filingCodes.next(filingCodes);
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting filing codes: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getdatafieldcodes': {
            if (message.isSuccess === true) {
              this.dfcService.dataFieldConfigs = message.taskresult.items as iDataFieldConfig[];
              this.dfcService.isDfcReady.next(true);
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting dfc codes: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getcountrycodes': {
            if (message.isSuccess === true) {
              this.countries = message.taskresult.items as iConfigCode[];
            }
            else {
              this.sendErrorMessage(message, "country codes");
            }
            break;
          }
          case 'getpartytypecodes': { //api request is made in party=information. get message here in case edit-party is selected before api message is returned 
            if (message.isSuccess === true) {
              this.partyTypes = message.taskresult.items as iPartyTypeCode[];
              this.partyTypes = this.partyTypes.filter(t => t.isAvailableForNewParties); //partyType dropdown only appears for new parties
              this.isPartyTypesLoaded = true;
              this.isPartyConfigLoaded();
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting party types: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getsuffixcodes': {
            if (message.isSuccess === true) {
              this.suffixes = message.taskresult.items as iConfigCode[];
              this.isSuffixesLoaded = true;
              this.isPartyConfigLoaded();
            }
            else {
              this.sendErrorMessage(message, "suffix codes");
           }
            break;
          }
          case 'getdriverslicensetypecodes': {
            if (message.isSuccess === true) {
              this.dlTypes = message.taskresult.items as iConfigCode[];
              this.isDlTypesLoaded = true;
              this.isPartyConfigLoaded();
            }
            else {
              this.sendErrorMessage(message, "drivers license types");
            }
            break;
          }
          case 'getgendercodes': {
            if (message.isSuccess === true) {
              this.genders = message.taskresult.items as iConfigCode[];
              this.isGendersLoaded = true;
              this.isPartyConfigLoaded();
            }
            else {
              this.sendErrorMessage(message, "gender types");
            }
            break;
          }
          case 'getlanguagecodes': {
            if (message.isSuccess === true) {
              this.languages = message.taskresult.items as iConfigCode[];
              this.isLanguagesLoaded = true;
              this.isPartyConfigLoaded();
            }
            else {
              this.sendErrorMessage(message, "language types");
            }
            break;
          }
          case 'gettenantconfiguration': {
            if (message.isSuccess === true) {
              var tenantConfig = message.taskresult as iTenantConfig;
              localStorage.setItem('documentDownloadApi', tenantConfig.downloadUrl);
            }
            else {
              this.sendErrorMessage(message, "document download");
            }
            break;
          }
          case 'savecase': {
            if (message.isSuccess === true) {
              //Refresh parties and fees after a case is saved
              this.refreshPartiesAndFees();
            }
          }
          case 'saveparty': {
            if (message.isSuccess === true) {
              //Refresh parties and fees after a party is saved
              this.refreshPartiesAndFees();
            }
          }
          case 'savefiling': {
            if (message.isSuccess === true) {
              //Refresh filings and fees after a party is saved
              const inputFiling = new FilingPagingInput(null, null, null, 'getfilings', this.envelopeId, this.caseData.location.nodeId, this.caseData.caseCategory.codeId, this.caseData.caseType.codeId, 0, 200);
              this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputFiling));

              var feesInput = new EnvelopeInput(null, null, null, 'getfees', this.envelopeId);
              this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(feesInput));

              this.filingService.filings.next(undefined);
            }
          }
        }
      });
  }

  callCaseApis() {

    const inputParty = new PartyPagingInput(null, null, null, 'getparties', this.envelopeId, 0, 10000, true, true, this.caseData.location.nodeId, this.caseData.caseType.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputParty));

    const inputFiling = new FilingPagingInput(null, null, null, 'getfilings', this.envelopeId, this.caseData.location.nodeId, this.caseData.caseCategory.codeId, this.caseData.caseType.codeId, 0, 200);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputFiling));

    const inputFilingCode = new FilingCodeInput(null, null, null, 'getfilingcodes', this.caseData.location.nodeId, this.caseData.caseCategory.codeId, this.caseData.caseType.codeId, true);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputFilingCode));
  }

  refreshPartiesAndFees() {
    const inputParty = new PartyPagingInput(null, null, null, 'getparties', this.envelopeId, 0, 10000, true, true, this.caseData.location.nodeId, this.caseData.caseType.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputParty));

    var feesInput = new EnvelopeInput(null, null, null, 'getfees', this.envelopeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(feesInput));

    this.partyService.parties.next(undefined);
  }

  sendErrorMessage(message: iMessage, title: string) {
    this.toaster.pop('error', 'Error', 'An error occurred getting ' + title + ': ' + message.error.code + ': ' + message.error.cause);
  }

  buildFileTree(filings: iFiling[]) {
    var fileTrees = [];
    filings.forEach(f => {
      var fileTree = new FileTree(f.filingId, '', '', f.filingCode.description, f.filingState, false, false, []);
      if (f.documents && f.documents.length) {
        f.documents.forEach(d => {
          if (d.filingComponent.description) {
            d.filingComponent.description.replace('Document', '').replace('Attachments', 'Attachment');
          }
          var documentTree = new FileTree(f.filingId, d.securityToken, d.fileName, d.filingComponent.description, f.filingState, false, false, []);
          fileTree.children.push(documentTree);
        })
      }
      else {
        var documentTree = new FileTree(f.filingId, 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF', 'Select No Document Filing', null, f.filingState, false, false, []);
        fileTree.children.push(documentTree);
     }
      fileTrees.push(fileTree);
    })
    this.webviewerService.documentFileTree.next(fileTrees);
  }

  onEnvButtonEvent($event: { component: string, key: number }): void {
    this.initializeToNotVisible()
    this.initializeSideBarToNotActive();

    this.key = $event.key;
    switch ($event.component) {
      case 'case':
        this.caseVisible = true;
        this.isCaseActive = true;
       break;
      case 'envelope':
        this.envVisible = true;
        this.isEnvelopeActive = true;
       break;
      case 'party':
        this.partyVisible = true;
        this.isPartyActive = true;
       break;
      case 'editParty':
        this.editPartyVisible = true;
        this.isPartyActive = true;
        this.partyId = this.key;
        break;
      case 'filing':
        this.fileVisible = true;
        this.isFileActive = true;
        break;
      case 'editFile':
        this.editFileVisible = true;
        this.isFileActive = true;
       break;
      case 'serve':
        this.serveVisible = true;
        this.isServeActive = true;
       break;
      case 'fees':
        this.feesVisible = true;
        this.isFeesActive = true;
        break;
    default:
        this.caseVisible = true;
        this.isCaseActive = true;
       break;
    }
  }

  onEnvPartyButtonEvent($event: {
    component: string, key: number, countries: iConfigCode[], isNewParty: boolean
  }): void {
    this.initializeToNotVisible()
    this.initializeSideBarToNotActive();
    this.partyId = $event.key;
    this.countries = $event.countries;
    this.isNewParty = $event.isNewParty;
    this.editPartyVisible = true;
    this.loadPartyConfigs();
 }

  loadPartyConfigs() {
    var partyTypeInput = new CaseTypeInput(null, null, null, 'getpartytypecodes', this.caseData.location.nodeId, this.caseData.caseType.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(partyTypeInput));

    const inputSuffix = new NodeInput(null, null, null, 'getsuffixcodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputSuffix));

    const inputDlTypes = new NodeInput(null, null, null, 'getdriverslicensetypecodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputDlTypes));

    const inputGender = new NodeInput(null, null, null, 'getgendercodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputGender));

    const inputLanguage = new NodeInput(null, null, null, 'getlanguagecodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputLanguage));
  }

  isPartyConfigLoaded() {
    if (this.isPartyTypesLoaded && this.editParty && this.isSuffixesLoaded && this.isDlTypesLoaded && this.isGendersLoaded && this.isLanguagesLoaded) {
      this.editParty.partyTypes = this.partyTypes;
      this.editParty.suffixes = this.suffixes;
      this.editParty.dlTypes = this.dlTypes;
      this.editParty.genders = this.genders;
      this.editParty.languages = this.languages;

      this.editParty.showEditablePage = true;
    }
  }

  initializeToNotVisible() {
    this.caseVisible = false;
    this.envVisible = false;
    this.partyVisible = false;
    this.editPartyVisible = false;
    this.fileVisible = false;
    this.editFileVisible = false;
    this.serveVisible = false;
    this.feesVisible = false;
  }


  initializeSideBarToNotActive() {
    this.isCaseActive = false;
    this.isEnvelopeActive = false;
    this.isPartyActive = false;
    this.isFileActive = false;
    this.isServeActive = false;
    this.isFeesActive = false;
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
