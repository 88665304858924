import { Component, OnInit } from '@angular/core';
import { QueueService } from '../../../common/service/queue.service';

@Component({
  selector: 'app-review-queue-filter-case-id',
  templateUrl: './review-queue-filter-case-id.component.html',
  styleUrls: ['./review-queue-filter-case-id.component.css']
})
export class ReviewQueueFilterCaseIdComponent implements OnInit {

  constructor(private queueService: QueueService) { }

  

  ngOnInit(): void {
  }

  setCaseNumberFilter(cid: any) {
    console.log(event);
    this.queueService.filterCaseNumber = cid;
  }

}
