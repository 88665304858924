import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, Input, Injectable } from '@angular/core';
import { FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Stamp } from 'src/app/common/model/data/stamp';
import { WebviewerService } from 'src/app/common/service/webviewer.service';

@Injectable()
export class StampScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(50, 100, 200);
  }
}

/*
export class Stamps
{
  
  constructor(
    public stampID: string,
    public image: string,
    public name: string ) {
    }

   
}
*/


@Component({
  selector: 'app-stamp-view',
  templateUrl: './stamp-view.component.html',
  styleUrls: ['./stamp-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: StampScrollStrategy }]
})
export class StampViewComponent implements OnInit {


  //items = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);

  @Input() stamps: Array<Stamp>;


  constructor(private webviewerService: WebviewerService) { }

  ngOnInit() {


    this.loadStamps();

    //this.scrollbarRef.update();
  }

  ngAfterViewInit(): void {
  }

  loadStamps(): void {
    this.stamps = [
      new Stamp("stamp01", "text", "images/stamp_text.svg", "stamp 01"),
      new Stamp("stamp02", "text", "images/stamp_text.svg", "stamp 02"),
      new Stamp("stamp03", "text", "images/stamp_text.svg", "stamp 03"),
      new Stamp("stamp04", "text", "images/stamp_text.svg", "stamp 04"),
      new Stamp("stamp05", "text", "images/stamp_text.svg", "stamp 05"),
      new Stamp("stamp06", "text", "images/stamp_text.svg", "stamp 06"),
      new Stamp("stamp07", "text", "images/stamp_text.svg", "stamp 07"),


           
      new Stamp("stamp12", "image", "images/stamp.svg", "stamp 12"),
      new Stamp("stamp13", "image", "images/stamp.svg", "stamp 13"),
      new Stamp("stamp14", "image", "images/stamp.svg", "stamp 14"),
      new Stamp("stamp15", "image", "images/stamp.svg", "stamp 15"),
      new Stamp("stamp16", "image", "images/stamp.svg", "stamp 16"),
      new Stamp("stamp17", "image", "images/stamp.svg", "stamp 17"),
    ];

    console.log(this.stamps.length);

  }


  onStamp() {
    console.log("onStamp");
    this.webviewerService.sendStamp(new Stamp("stamp12", "image", "images/stamp.svg", "stamp 12"));
  }

  


}
