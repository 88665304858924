import { Injectable } from '@angular/core';
import { LocalizationService } from './localization.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Strings {

  constructor (private localizationService: LocalizationService) { }

  /**
 * Translates a string ONCE.
 * WARNING: if translations are not loaded, this can result in an untranslated string.
 *
 * ONLY use this if you are translating for a (1) Toast (2) Confirm popup
 *
 * The reason for this is that we don't have any mechanism for data binding to strings in those contexts.
 *
 * FOR ALL OTHER PURPOSES, use T().
 *
 */
  translateOnce(originalText: string, args?: any[] | undefined): string {
    return this.T(originalText, args).value;
  }

  T (originalText: string, args?: any[] | undefined): BehaviorSubject<string> {
    const result = new BehaviorSubject<string>(originalText);

    this.localizationService.getLocalization().subscribe(poDictionary => {
      const entry = poDictionary.find((item) => item.originalText === originalText.toLowerCase());

      let newText = (entry === undefined || !entry.translation) ? originalText : entry.translation;

      // Developer localization test mode
      // newText += ' - Hola Mundo';

      result.next(args === undefined ? newText : this.format(newText, args));
    });

    return result;
  }

  format(value: string, args: any[]) {
    return value.replace(/{(\d+)}/g, function (_match, index) {
      return (args[index])
           ? args[index].toString()
           : '';
    });
  }
}
