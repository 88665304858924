import { BaseRequest } from 'src/app/common/request/base-request';

export class PartyPagingInput extends BaseRequest{
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public envelopeId: number,
    public pageIndex: number,
    public pageSize: number,
    public getNew: boolean,
    public getExisting: boolean,
    public nodeId: number,
    public caseTypeId: number
  )
  { super(tenant, messageId, connectionId, action) }
}

