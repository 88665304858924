
export class LogMessage {
  logLevel: LogLevel;
  timestamp: Date;
  message: string;
  additionalData: object;

  private constructor() {
    this.timestamp = new Date();
  }

  static create(logLevel: LogLevel, message: string, additionalData: object) {
    const newMessage = new LogMessage();
    newMessage.logLevel = logLevel;
    newMessage.message = message;
    newMessage.additionalData = additionalData;

    return newMessage;
  }

  asTextOutput(): string {

    if (null == this.additionalData || undefined == this.additionalData) {
      return this.message;
    }

    return `Message: ${this.message}\nData:    ${JSON.stringify(this.additionalData)}`;
  }


}


export enum LogLevel {
  Information,
  Warning,
  Error,
  Debug,
  Log
}

