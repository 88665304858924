import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { iServiceContact } from 'src/app/common/model/envelope/service-contact';
import { PagedModel} from 'src/app/common/model/response/paged-model';

@Injectable({
  providedIn: 'root'
})
export class ServiceContactService {
  public serviceContacts: BehaviorSubject<PagedModel<iServiceContact> | undefined>;

  constructor() {
    this.serviceContacts = new BehaviorSubject<PagedModel<iServiceContact> | undefined>(undefined);
  }
}
