import { iCode } from "./code";

export class Address implements iAddress {
  constructor(
    public addressId: number,
    public streetAddressLine1: string | undefined = undefined,
    public streetAddressLine2: string | undefined = undefined,
    public streetAddressLine3: string | undefined = undefined,
    public streetAddressLine4: string | undefined = undefined,
    public city: string | undefined = undefined,
    public region: iCode | undefined = undefined,
    public zipCode: string | undefined = undefined,
    public country: iCode | undefined = undefined,
    public cityStateZip: string | undefined = undefined,
  ) { }
}

export interface iAddress {
  addressId: number;
  streetAddressLine1: string | undefined;
  streetAddressLine2: string | undefined;
  streetAddressLine3: string | undefined;
  streetAddressLine4: string | undefined;
  city: string | undefined;
  region: iCode | undefined;
  zipCode: string | undefined;
  country: iCode | undefined;
  cityStateZip: string | undefined;
}
