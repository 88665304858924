import { Injectable } from '@angular/core';
import { iCase } from 'src/app/common/model/envelope/case';
import { BehaviorSubject, Subscription } from 'rxjs';
import { iOrgChart } from 'src/app/common/model/config/org-chart';


@Injectable({
  providedIn: 'root'
})
export class CaseService {
  public caseToReview: BehaviorSubject<iCase | undefined> = new BehaviorSubject<iCase | undefined>(undefined);
  public locations: BehaviorSubject<iOrgChart[] | undefined> = new BehaviorSubject<iOrgChart[] | undefined>(undefined);

  constructor() { }
}
