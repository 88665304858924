import { BaseRequest } from 'src/app/common/request/base-request';

export class FilingPagingInput extends BaseRequest{
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public envelopeId: number,
    public nodeId: number,
    public caseCategoryId: number,
    public caseTypeId: number,
    public pageIndex: number,
    public pageSize: number,
  )
  { super(tenant, messageId, connectionId, action) }
}

