import { BaseRequest } from 'src/app/common/request/base-request';

export class SaveCaseInput implements BaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public caseInformation: CaseInformation
  ) { }
}

export class CaseInformation {
  constructor(
    public caseDataId: number,
    public locationId: number,
    public caseCategoryCodeId: number,
    public caseTypeCodeId: number,
    public caseSubTypeCodeId: number | undefined,
    public caseNumber: string | undefined,
    public description: string | undefined,
    public lowerCourtCodeId: number | undefined,
    public otherCaseNumber: string | undefined,
    public otherCaseDescription: string | undefined,
    public lowerCourtCaseNumber: string | undefined,
    public lowerCourtCaseStyle: string | undefined,
    public lowerCourtName: string | undefined,
    public lowerCourtCaseJudge: string | undefined,
    public civilCaseProcedureCodes: number[] | undefined,
    public civilCaseAmountRange: number | undefined,
    //public caseCrossReferences: CrossReference[] | undefined,
    public willFiledDate: string | undefined,
    public judicialOfficerCodeId: number | undefined,
    public judicialOfficerName: string | undefined,
    public envelopeId: number,
    public workItemId: number,
  ) { }
}

export class CrossReference {
  constructor(
    public codeId: number,
    public value: string,
  ) { }
}


