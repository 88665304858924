import { Component, OnInit } from '@angular/core';
import { DataFieldBase } from '../data-field-base';
import { OnChanges } from '@angular/core/core';
import { OnDestroy } from '@angular/core/core';
import { Strings } from '../../../localization/strings';
import { ValidatorFn } from '@angular/forms/forms';
import { Constants } from '../../../constants';

@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss']
})
export class TimeFieldComponent extends DataFieldBase<string> implements OnInit, OnChanges, OnDestroy {

  constructor(strings: Strings) { super(strings) }

  getTimeFormat() {
    return Constants.TIME_FMT;
  }

  protected getPatternValidator(): ValidatorFn | undefined {
    return undefined;
  }
}
