import { Input, Output, EventEmitter, SimpleChanges, Directive } from '@angular/core';
import { FormGroup, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { iDataFieldConfig } from 'src/app/common/model/config/data-field-config';
import { Subscription } from 'rxjs';
import { failValidation } from 'src/app/common/validation-failed-validator';
import { Strings } from '../../localization/strings';

@Directive()
export abstract class DataFieldBase<V = string> {

  constructor(protected strings: Strings) { }

  protected id: string = '_' + Math.random().toString(36).substr(2, 9);

  @Input()
  public form: FormGroup = new FormGroup({});

  @Input()
  public label: string = "";
  public labelLocal: string = "";
  public labelSub: Subscription | undefined;

  @Input()
  public name: string = "";

  @Input()
  public config: iDataFieldConfig | undefined;

  @Input()
  public forceReadOnly: boolean = false;

  @Input()
  public forceRequired: boolean = false;

  @Input()
  public forceVisible: boolean = false;

  @Input()
  public forceNotRequired: boolean = false;

  @Input()
  public extraValidation: boolean = true;

  @Input()
  public extraValidationMessage: string | undefined;
  public extraValidationMessageLocal: string | undefined;
  public extraValidationSub: Subscription | undefined;

  @Input()
  public templateRequired: boolean = false;

  // Two-way binding for value
  @Input()
  public value: V | undefined;
  @Output()
  public valueChange = new EventEmitter<V>();

  protected subscriptions: Subscription[] = [];

  protected updateFormConfig() {
    const field = this.form.get(this.id);
    if (this.config && field) {
      const isDisabled = this.config.isReadOnly || (!this.config.isVisible && !this.forceVisible);
      if (isDisabled) {
        field.disable();
      }
      else {
        field.enable();
      }
      field.setValidators(this.getValidators());
      // Updating validity of the form component often triggers an expression changed error on a parent component which is relying on the isValid flag of the parent form component.
      setTimeout(() => {
        field.updateValueAndValidity();
      });
    }
  }

  protected getValidators(): ValidatorFn[] {
    var result: ValidatorFn[] = [];
    if ((this.config && this.config.isRequired && !this.forceNotRequired && !this.forceReadOnly) || (this.forceRequired && !this.forceReadOnly)) {
      result.push(Validators.required);
    }
    if (!this.extraValidation) {
      result.push(failValidation());
    }
    const validator = this.getPatternValidator(this.config);
    if (validator) {
      result.push(validator);
    }
    return result;
  }

  protected showRequired(): boolean {
    return !this.forceReadOnly
      && ((this.config && this.config.isRequired && !this.forceNotRequired) || this.forceRequired);
  }

  ngOnInit() {
    if (this.name) {
      this.id = this.name;
    }

    let formControl = new FormControl({ value: this.value, disabled: true });

    this.form.addControl(this.id, formControl);
    this.subscriptions.push(this.form.get(this.id)!.valueChanges.subscribe(val => { this.value = val; this.valueChange.emit(this.value); }));
    this.updateFormConfig();
  }

  ngOnDestroy() {

    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if (this.labelSub) {
      this.labelSub.unsubscribe();
    }
    if (this.extraValidationSub) {
      this.extraValidationSub.unsubscribe();
    }
    this.form.removeControl(this.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.setFormValue(changes['value'].currentValue);
    }
    if (changes['config'] || changes['forceRequired'] || changes['forceNotRequired'] || changes['forceRegex'] || changes['extraValidation']) {
      this.updateFormConfig();
    }
    if (changes['label']) {
      if (this.labelSub) {
        this.labelSub.unsubscribe();
      }
      if (this.label && this.label.length) {
        this.labelSub = this.strings.T(this.label).subscribe(labelLocal => this.labelLocal = labelLocal);
      }
      else {
        this.labelLocal = '';
      }
    }
    if (changes['extraValidationMessage']) {
      if (this.extraValidationSub) {
        this.extraValidationSub.unsubscribe();
      }
      if (this.extraValidationMessage) {
        this.extraValidationSub = this.strings.T(this.extraValidationMessage).subscribe(extraValidationMessageLocal => this.extraValidationMessageLocal = extraValidationMessageLocal);
      }
    }
  }

  protected setFormValue(val: any) {
    if (this.form) {
      const field = this.form.get(this.id);
      if (field && field instanceof FormControl) {
        if (field.value !== val) {
          field.setValue(val);
        }
      }
    }
  }

  get currentFormControl() {
    return this.form.controls[this.id];
  }

  protected abstract getPatternValidator(config: iDataFieldConfig | undefined): ValidatorFn | undefined;

}
