export interface iEditStatus {
  isValid: boolean;
  isDirty: boolean;
  currentComponent: ComponentPage;
  errorMessage: string | undefined;
}

export enum ComponentPage {
  None = 0,
  Case = 1,
  Envelope = 2,
  Party = 3,
  Filing = 4,
  AssociatedParties = 5,
  OptionalServices = 6,
  Payment = 7,
  CaseCrossReference = 8,
}

export class ComponentPageHelper {
  constructor() { }

  static componentName(component: ComponentPage) {
    switch (component) {
      case ComponentPage.Case: return 'Case Information';
      case ComponentPage.Envelope: return 'Envelope Information';
      case ComponentPage.Party: return 'Party Information';
      case ComponentPage.Filing: return 'Filing Information';
      case ComponentPage.AssociatedParties: return 'Associated Parties';
      case ComponentPage.OptionalServices: return 'Optional Services';
      case ComponentPage.Payment: return 'Fee Information';
      case ComponentPage.CaseCrossReference: return 'Case Cross Referencen';
    }
  }
}
