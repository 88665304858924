import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ElementRef, ViewChild } from '@angular/core';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { FilingService } from 'src/app/common/service/filing.service';
import { DfcService } from 'src/app/common/service/dfc.service';
import { iCase } from 'src/app/common/model/envelope/case';
import { iParty } from 'src/app/common/model/envelope/party';
import { SaveCaseInput, CaseInformation, CrossReference } from 'src/app/common/request/save-case-input';
import { iValidationCaseData } from 'src/app/common/response/validation-case-data';
import { CaseCrossReference } from 'src/app/common/model/envelope/case-cross-reference';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/common/constants';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { iCaseCategoryCode } from 'src/app/common/model/config/case-category-code';
import { iCaseTypeCode } from 'src/app/common/model/config/case-type-code';
import { iOrgChart } from 'src/app/common/model/config/org-chart';
import { getSelectedValueOrDefault } from 'src/app/common/component/data-fields/dropdown-field/dropdown-logic';
import { FormGroup } from '@angular/forms';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { iCaseCrossReferenceCode } from 'src/app/common/model/config/case-cross-reference-code';
import { HtmlPropertyHelper } from 'src/app/common/helper/html-property-helper';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { ConnectionService } from '../../../common/service/connection.service';
import { iMessage } from 'src/app/common/response/message';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { BaseRequest } from 'src/app/common/request/base-request';
import { NodeInput } from 'src/app/common/request/node-input';
import { CaseCategoryInput } from 'src/app/common/request/case-category-input';
import { CaseTypeInput } from 'src/app/common/request/case-type-input';
import { Router } from '@angular/router';
import { QueueService } from 'src/app/common/service/queue.service';
import { EditCrossReferenceComponent } from './edit-cross-reference/edit-cross-reference.component';
import * as lodash from 'lodash';

@Component({
  selector: 'app-case-information',
  templateUrl: './case-information.component.html',
  styleUrls: ['./case-information.component.css']
})
export class CaseInformationComponent implements OnInit, OnDestroy {
  public form: FormGroup = new FormGroup({});
  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();
  @Input() caseData: iCase;

  @ViewChild('editCaseCrossReference', { static: false }) editCaseCrossReference: EditCrossReferenceComponent | undefined;

  envelopeId: number;
  workItemId: number;
  isValid: boolean = true;

  location: iOrgChart | undefined;
  locations: iOrgChart[] | undefined;
  locationConfig: iDataFieldConfig | undefined;

  caseCategory: iCaseCategoryCode | undefined;
  caseCategories: iCaseCategoryCode[] | undefined;
  caseCategoryConfig: iDataFieldConfig | undefined;

  caseType: iCaseTypeCode | undefined;
  caseTypes: iCaseTypeCode[] | undefined;
  caseTypeConfig: iDataFieldConfig | undefined;

  caseSubTypes: iConfigCode[] | undefined;
  caseSubTypeConfig: iDataFieldConfig | undefined;
  caseSubTypesLoaded: boolean;

  lowerCourtCodes: iConfigCode[] | undefined;
  caseInformationLowerCourtConfig: iDataFieldConfig | undefined;
  
  isGetConfigCodesCalled: boolean = false;
  showEditablePage: boolean = false;
  
  //selectedProcedureRemedies: iConfigCode[] = [];
  //procedureRemedies: iConfigCode[] | undefined;
  procedureRemedyConfig: iDataFieldConfig | undefined;
  damageAmountConfig: iDataFieldConfig | undefined;

  parties: iParty[];

  caseInformationShortTitleConfig: iDataFieldConfig | undefined;
  lowerCourtCaseNumberConfig: iDataFieldConfig | undefined;
  otherCaseNumberConfig: iDataFieldConfig | undefined;
  lowerCourtCaseStyleConfig: iDataFieldConfig | undefined;
  lowerCourtNameConfig: iDataFieldConfig | undefined;
  lowerCourtCaseJudgeConfig: iDataFieldConfig | undefined;
  lowerCourtCaseStyle: iDataFieldConfig | undefined;
  willFiledDateConfig: iDataFieldConfig | undefined;
  visibleConfig: iDataFieldConfig | undefined;

  selectedCrossReferenceId: number;
  caseCrossReferenceCodes: iCaseCrossReferenceCode[];
  caseCrossReference: CaseCrossReference;
  showCaseCrossReference: boolean = false;
  isCaseCrossReferenceValid: boolean = true;
  isCaseCrossReferenceDirty: boolean;
  isCaseCrossReferenceVisible: boolean;
  caseCrossReferencesInitial: CaseCrossReference[] = [];
  isProcessCaseCrossReferenceDone: boolean;
  selectedCcrIndex: number;

  //Multiple ...Changed() calls are occuring. Get only once based on previous call.
  previousLocationId: number = 0;
  previousCaseCategoryId: number = 0;
  previousCaseTypeId: number = 0;

  stopDirtyCheck: boolean = false;

  isSaving: boolean;
  completedSave: boolean;
  saveNextComponent: string;
  saveCrossReferences: CrossReference[] = [];
  validationCaseData: iValidationCaseData | undefined
  filingsWithErrors: number[] = [];

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  constructor(private caseService: CaseService, private envelopeService: EnvelopeService, private filingService: FilingService, private htmlPropertyHelper: HtmlPropertyHelper,
    public element: ElementRef, private toastr: CustomToastrService, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper, private dfcService: DfcService,
    private router: Router, private queueService: QueueService) { }

  ngOnInit() {
    this.getApiMessage();

    this.envelopeId = this.queueService.envelopeId;
    this.workItemId = this.queueService.workItemId;

    this.subscriptions.push(this.caseService.locations.subscribe(_locations => {
      if (_locations) {
        this.locations = _locations;
        this.prepLocation();
        this.setEditablePage();
      }
      else {
        const input = new BaseRequest(null, null, null, 'getlocationcodes');
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(input));
      }
    }));

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      if (_case && !this.isGetConfigCodesCalled) {
        if (_case.isCreated) {
          this.showEditablePage = true; //stops spinner waiting on configs
        }
        else {
          this.getConfigCodes();
        }
      }
    }));

    this.subscriptions.push(this.dfcService.isDfcReady.subscribe(isReady => {
      if (isReady) {
        this.initializeDataFieldConfigs();
      }
    }));

    this.subscriptions.push(this.envelopeService.doSave.subscribe(save => {
      if (!save) {
        this.isSaving = false;
        return;
      }
      if (save.currentComponent == ComponentPage.Case) {
        this.saveNextComponent = save.nextComponent;
        this.isSaving = true;

        this.cleanupCaseBeforeSave();

        //this.caseData.caseCrossReferences.forEach(ccr => {
        //  var caseCrossReference = new CrossReference(ccr.codeId, ccr.description);
        //  this.saveCrossReferences.push(caseCrossReference);
        //})

        var caseInformation = new CaseInformation(
          this.caseData.caseDataId,                 //caseDataId
          this.caseData.location.nodeId,            //locationId
          this.caseData.caseCategory.codeId,        //caseCategoryCodeId
          this.caseData.caseType.codeId,            //caseTypeCodeId;
          this.caseData.caseSubType.codeId,         //caseSubTypeCodeId
          this.caseData.caseNumber,                 //caseNumber
          this.caseData.description,                //description
          this.caseData.lowerCourt.codeId,          //lowerCourtCodeId
          this.caseData.otherCaseNumber,            //otherCaseNumber
          this.caseData.otherCaseDescription,       //otherCaseDescription
          this.caseData.lowerCourtCaseNumber,       //lowerCourtCaseNumber
          this.caseData.lowerCourtCaseStyle,        //lowerCourtCaseStyle
          this.caseData.lowerCourtName,             //lowerCourtName
          this.caseData.lowerCourtCaseJudge,        //lowerCourtCaseJudge
          undefined,                            //civilCaseProcedureCodes            todo
          undefined, //this.caseData.civilCaseAmountRange.codeId,  //civilCaseAmountRange
          //this.saveCrossReferences,             //caseCrossReferences
          this.caseData.willFiledDate,              //willFiledDate
          undefined,                            //judicialOfficerCodeId              
          this.caseData.judicialOfficerName,        //judicialOfficerName
          this.envelopeId,                      //envelopeId
          this.workItemId
        );

        var saveCaseInput = new SaveCaseInput(null, null, null, 'savecase', caseInformation);
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(saveCaseInput));
      }
    }));
  }

  getConfigCodes() {
    this.isGetConfigCodesCalled = true;

    const inputCaseCategory = new NodeInput(null, null, null, 'getcasecategorycodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseCategory));

    const inputCaseType = new CaseCategoryInput(null, null, null, 'getcasetypecodes', this.caseData.location.nodeId, this.caseData.caseCategory.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseType));

    const inputCaseSubType = new CaseTypeInput(null, null, null, 'getcasesubtypecodes', this.caseData.location.nodeId, this.caseData.caseType.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseSubType));

    const inputLowerCourt = new NodeInput(null, null, null, 'getlowercourtcodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputLowerCourt));

    const inputCaseCrossRefernce = new CaseTypeInput(null, null, null, 'getcasecrossreferencecodes', this.caseData.location.nodeId, this.caseData.caseType.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseCrossRefernce));
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getcase': {
            if (message.isSuccess === true) {
              this.caseData = message.taskresult as iCase;
              this.caseService.caseToReview.next(this.caseData);
              this.caseCrossReferencesInitial = lodash.cloneDeep(this.caseData.caseCrossReferences);
              if (this.caseData.isCreated) {
                this.showEditablePage = true; //stops spinner waiting on configs
              }
              else {
                this.getConfigCodes();
              }
              this.detectChanges();

              //Enable left nav menu
              this.callCaseApis()
              this.prepLocation();
            }
            else {
              this.toastr.pop('error', 'Error', 'An error occurred getting case: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getlocationcodes': {
            if (message.isSuccess === true) {
              this.locations = message.taskresult.items as iOrgChart[];
              this.prepLocation();
              this.setEditablePage();
              this.caseService.locations.next(this.locations);
            }
            else {
              this.sendErrorMessage(message, "Locations");
            }
            break;
          }
          case 'getcasecategorycodes': {
            if (message.isSuccess === true) {
              this.caseCategories = message.taskresult.items as iCaseCategoryCode[];
              this.setEditablePage();

              //if location changed and caseCategory is still valid, initiate new getcasetypecodes
              if (this.caseData && this.caseData.caseCategory && this.caseData.caseCategory.codeId == this.previousCaseCategoryId) {
                var found = this.caseCategories.filter(c => {
                  c.codeId == this.caseData.caseCategory.codeId;
                });
                if (found) {
                  const inputCaseType = new CaseCategoryInput(null, null, null, 'getcasetypecodes', this.caseData.location.nodeId, this.caseData.caseCategory.codeId);
                  this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseType));
                }
              }
            }
            else {
              this.sendErrorMessage(message, "Case Categories");
            }
            break;
          }
          case 'getcasetypecodes': {
            if (message.isSuccess === true) {
              this.caseTypes = message.taskresult.items as iCaseTypeCode[];
              this.setEditablePage();
            }
            else {
              this.sendErrorMessage(message, "Case Types");
            }
            break;
          }
          case 'getcasesubtypecodes': {
            if (message.isSuccess === true) {
              this.caseSubTypes = [...message.taskresult.items];
              this.caseSubTypesLoaded = true;
              this.setEditablePage();
            }
            else {
              this.sendErrorMessage(message, "Case Sub Types");
            }
            break;
          }
          case 'getlowercourtcodes': {
            if (message.isSuccess === true) {
              this.lowerCourtCodes = [...message.taskresult.items];
            }
            else {
              this.sendErrorMessage(message, "Lower Court Codes");
            }
            break;
          }
          case 'getcasecrossreferencecodes': {
            if (message.isSuccess === true) {
              this.caseCrossReferenceCodes = message.taskresult.items as iCaseCrossReferenceCode[];
              this.caseCrossReferenceCodes.forEach(ccr => ccr.isDefault = false);
              this.processCaseCrossReference();
            }
            else {
              this.sendErrorMessage(message, "Case Cross Reference Codes");
            }
            break;
          }
          case 'savecase': {
            if (message.isSuccess === true) {
              if (!message.taskresult.isLockValid) {
                this.toastr.pop('error', 'Error', 'You no longer own the lock on this envelope.', Constants.ToasterTimeoutLong);
                this.router.navigate(["/queue"]);
                return;
              }
              this.validationCaseData = message.taskresult as iValidationCaseData;
              var validationMessages = '';
              if (!this.validationCaseData.isCaseCategoryValid || !this.validationCaseData.isCaseTypeValid) {
                if (!this.validationCaseData.isCaseCategoryValid) {
                  validationMessages += 'Case Category is invalid.</br>'
                }
                if (!this.validationCaseData.isCaseTypeValid) {
                  validationMessages += 'Case Type is invalid.</br>'
                }
                this.toastr.pop('error', 'Error', validationMessages, Constants.ToasterTimeoutNone);
              }
              else {
                if (this.validationCaseData.isRequiredPartyMissing) {
                  this.saveNextComponent = 'party';
                }
                if (this.validationCaseData.IsPrffMissing) {
                  validationMessages += 'Party Responsible For Fees is missing.</br>'
                }
                if (this.validationCaseData.filings) {
                  var validationMessagesFiling = '';
                  this.validationCaseData.filings.forEach(f => {
                    this.filingsWithErrors.push(f.filingId);
                    if (f.isFilingCodeInvalid) {
                      var filingIdentifier = 'Filing ' + f.description ? f.description : ''
                      validationMessagesFiling += filingIdentifier + ' invalid for Filing Code "' + f.filingCodeDescription + '" invalid.</br>';
                    }
                    f.filingComponents.forEach(fc => {
                      if (fc.IsFilingComponentCodeInvalid) {
                        validationMessagesFiling += 'Filing Component ' + fc.FilingComponentDescription + ' for ' + filingIdentifier + ' invalid for Filing Code ' + f.filingCodeDescription + '.</br>';
                        if (fc.IsAdditionalService && fc.IsDeleted) {
                          validationMessages += 'Optional Service ' + fc.FilingComponentDescription + ' was deleted.</br>';
                        }
                      }
                      fc.documents.forEach(d => {
                        if (d.isDocumentTypeInvalid) {
                          validationMessagesFiling += 'Security Type for document ' + d.originalFileName + ' is invalid.</br>';
                        }
                      });
                    });
                  });
                  this.filingService.filingsWithErrors.next(this.filingsWithErrors);
                }
                else {
                  this.filingService.filingsWithErrors.next([]);
                }
                if (validationMessages.length) {
                  this.toastr.pop('error', 'Error', validationMessages, Constants.ToasterTimeoutNone);
                }
                if (validationMessagesFiling.length) {
                  this.toastr.pop('error', 'Filing Error', validationMessagesFiling, Constants.ToasterTimeoutNone);
                }
                this.caseService.caseToReview.next(this.caseData);
                this.envelopeService.doSave.next(undefined);
                this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.Case, errorMessage: '' });
                this.toastr.pop('success', 'Saved', 'Case Information changes have been saved');
                this.envelopeService.showComponent.next({ component: this.saveNextComponent, isSuccess: true, message: undefined })
                this.stopDirtyCheck = this.completedSave = true;
              }
            }
            else {
              this.toastr.pop('error', 'Error', 'An error occurred saving the case: ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutNone);
           }
            break;
          }
       }
      });
  }

  callCaseApis() {
    //this.envelopeService.editStatus.next({ isValid: !this.form.invalid && this.isCaseCrossReferenceValid, isDirty: this.form.dirty || this.isCaseCrossReferenceDirty, currentComponent, currentComponent: ComponentPage.Case, errorMessage: '' });

    const inputCaseCategory = new NodeInput(null, null, null, 'getcasecategorycodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseCategory));

    const inputCaseType = new CaseCategoryInput(null, null, null, 'getcasetypecodes', this.caseData.location.nodeId, this.caseData.caseCategory.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseType));

    const inputCaseSubType = new CaseTypeInput(null, null, null, 'getcasesubtypecodes', this.caseData.location.nodeId, this.caseData.caseType.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseSubType));

    const inputLowerCourt = new NodeInput(null, null, null, 'getlowercourtcodes', this.caseData.location.nodeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputLowerCourt));
  }

  sendErrorMessage(message: iMessage, title: string) {
    this.toastr.pop('error', 'Error', 'An error occurred getting ' + title + ': ' + message.error.code + ': ' + message.error.cause);
  }

  cleanupCaseBeforeSave() {
    //clear case properties that are not longer valid
    if (this.caseType && this.caseType.requireWillFiledDate === undefined) {
      this.caseData.willFiledDate = undefined;
    }

    if (this.caseCategory && this.caseCategory.ecfCaseType !== 'AppellateCase') {
      this.caseData.lowerCourtCaseNumber = this.caseData.lowerCourtName = this.caseData.lowerCourtCaseJudge = this.caseData.lowerCourtCaseStyle = undefined;
      if (this.caseData.lowerCourt) {
        this.caseData.lowerCourt.codeId = undefined;
      }
    }
  }

  detectChanges() {
    this.subscriptions.push(this.form.statusChanges.subscribe(status => {
      if (this.stopDirtyCheck) {
        this.stopDirtyCheck = false;
        return;
      }
      if (status == "INVALID") {
        this.isValid = false;
        this.envelopeService.editStatus.next({ isValid: false && this.isCaseCrossReferenceValid, isDirty: this.form.dirty || this.isCaseCrossReferenceDirty, currentComponent: ComponentPage.Case, errorMessage: '' });
      }
      else {
        if (!this.isCaseCrossReferenceValid) {
          this.isValid = false;
        }
        else {
          this.isValid = true;
        }
        this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.form.dirty || this.isCaseCrossReferenceDirty && !this.completedSave, currentComponent: ComponentPage.Case, errorMessage: '' });
      }
    }));
  }

  //page is read-only until all the configs are loaded.This prevents a false "cannot proceed until the error is fixed" toast if user selects a different page too soon.
  setEditablePage() {
    if (this.locations && this.caseCategories && this.caseTypes && this.caseSubTypesLoaded) {
      setTimeout(() => {
        this.showEditablePage = true;
      }, 200); //timer allows all values to be correctly selected
    }
    this.isSaving = false;
  }

  prepLocation() {
    if (this.caseData && this.locations) {
      const found = getSelectedValueOrDefault(this.locations, this.location, this.caseData.location.nodeId, (l) => l.nodeId, true);
      this.location = found;

      if (this.caseTypes && this.addCrossReference && (!this.caseData.caseCrossReferences || !this.caseData.caseCrossReferences.length)) {
        this.isCaseCrossReferenceValid = this.isValid = false;
      }
    }
  }
   
  locationChanged() {
    if (this.location && this.previousLocationId != this.location.nodeId) {
      this.previousLocationId = this.location.nodeId;
      if (this.location.nodeId) {
        this.caseData.location = this.location;
      }

      const inputCaseCategory = new NodeInput(null, null, null, 'getcasecategorycodes', this.location.nodeId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseCategory));

      const inputLowerCourt = new NodeInput(null, null, null, 'getlowercourtcodes', this.location.nodeId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputLowerCourt));
    }
  }

  caseCategoryChanged(caseCategory: iCaseCategoryCode) {
    if (this.location && caseCategory &&
      (this.previousLocationId != this.location.nodeId || this.previousCaseCategoryId != caseCategory.codeId)) {
      this.previousCaseCategoryId = caseCategory.codeId;
      this.caseCategory = caseCategory;

      const inputCaseType = new CaseCategoryInput(null, null, null, 'getcasetypecodes', this.caseData.location.nodeId, caseCategory.codeId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseType));
    }
  }

  caseTypeChanged(caseType: iCaseTypeCode) {
    if (caseType) {
      if (caseType.codeId === this.previousCaseTypeId) {
        return;
      }
      this.previousCaseTypeId = caseType.codeId;
      this.caseType = caseType;
      this.showCaseCrossReference = caseType.allowCaseCrossReference ? true : false;
      if (this.previousCaseTypeId != caseType.codeId) {
        this.previousCaseTypeId = caseType.codeId
        if (this.caseType.allowCaseCrossReference) {
          const inputCaseCrossRefernce = new CaseTypeInput(null, null, null, 'getcasecrossreferencecodes', this.caseData.location.nodeId, this.caseData.caseType.codeId);
          this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseCrossRefernce));
        }
        else {
          this.caseData.caseCrossReferences = new Array();
        }

        const inputCaseSubType = new CaseTypeInput(null, null, null, 'getcasesubtypecodes', this.caseData.location.nodeId, caseType.codeId);
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputCaseSubType));

        var isWillFiledDateVisible = !!caseType.requireWillFiledDate;
        var isWillFiledDateRequired = caseType.requireWillFiledDate;
        this.willFiledDateConfig = new DataFieldConfigDefaults(isWillFiledDateVisible, isWillFiledDateVisible, isWillFiledDateRequired);
      }
    }
  }

  processCaseCrossReference() {
    if (this.caseData && this.caseCrossReferenceCodes && !this.isProcessCaseCrossReferenceDone) {
      this.isCaseCrossReferenceValid = this.isProcessCaseCrossReferenceDone = true;
      if (this.caseData.caseCrossReferences && this.caseData.caseCrossReferences.length) {
        this.caseData.caseCrossReferences.forEach(ccr => {
          var idx = this.caseCrossReferenceCodes.findIndex(c => c.codeId == ccr.codeId);
          if (idx > -1) {
            ccr.isRequired = this.caseCrossReferenceCodes[idx].isRequired;
            ccr.isCreated = true;
            ccr.validationRegex = this.caseCrossReferenceCodes[idx].validationRegex;
            ccr.validationFailureCustomMessage = this.caseCrossReferenceCodes[idx].validationFailureCustomMessage;
            if (ccr.isRequired && !ccr.caseCrossReferenceNumber) {
              this.isCaseCrossReferenceValid = false;
            }
          }
        });
        //There may multiple ccr's with the same required type. Disable only the first one.
        var requiredCodes = this.caseCrossReferenceCodes.filter(c => c.isRequired);
        if (requiredCodes && requiredCodes.length) {
          requiredCodes.forEach(required => {
            var idx = this.caseData.caseCrossReferences.findIndex(ccr => ccr.codeId == required.codeId);
            if (idx > -1) {
              this.caseData.caseCrossReferences[idx].isDisabled = true;
            }
          });
        }
      }
      else {
        this.caseData.caseCrossReferences = new Array();
        this.caseCrossReferenceCodes.forEach(c => {
          if (c.isRequired) {
            this.caseData.caseCrossReferences.push(new CaseCrossReference(c.codeId, undefined, c.description, true, false, false, true, c.validationRegex, c.validationFailureCustomMessage));
            this.isCaseCrossReferenceValid = false;
          }
        });
      }
    }
  }

  editCrossReference(caseCrossReference: CaseCrossReference, index: number) {
    this.editCaseCrossReference.caseCrossReference = caseCrossReference;
    this.selectedCcrIndex = index;
    this.isCaseCrossReferenceVisible = true;
    this.editCaseCrossReference.editCaseCrossReference(caseCrossReference, index);
  }

  addCrossReference() {
    this.caseCrossReference = new CaseCrossReference(undefined, undefined);
    this.caseData.caseCrossReferences = this.caseData.caseCrossReferences.concat();
    this.caseData.caseCrossReferences.push(this.caseCrossReference);
    this.selectedCcrIndex = this.caseData.caseCrossReferences.length -1;
    this.isCaseCrossReferenceValid = false;
    this.isCaseCrossReferenceVisible = true;
    this.envelopeService.editStatus.next({ isValid: false, isDirty: true, currentComponent: ComponentPage.Case, errorMessage: '' });
    this.editCaseCrossReference.editCaseCrossReference(this.caseCrossReference, this.caseData.caseCrossReferences.length -1);
  }

  //Returning from caseCrossReference
  onShowCaseInformationEvent(): void {
    this.caseData.caseCrossReferences = this.caseData.caseCrossReferences.concat();
    this.isCaseCrossReferenceVisible = false;

    var found = this.caseData.caseCrossReferences.filter(ccr => !ccr.codeId || !ccr.caseCrossReferenceNumber);
    if (found && found.length) {
      this.isCaseCrossReferenceValid = false;
    }
    else {
      this.isCaseCrossReferenceValid = true;
    }
    this.isCaseCrossReferenceDirty = !(JSON.stringify(this.caseCrossReferencesInitial) === JSON.stringify(this.caseData.caseCrossReferences));

    this.envelopeService.editStatus.next({ isValid: !this.form.invalid && this.isCaseCrossReferenceValid, isDirty: this.form.dirty || this.isCaseCrossReferenceDirty, currentComponent: ComponentPage.Case, errorMessage: '' });
  }

  initializeDataFieldConfigs() {
    this.locationConfig = this.dfcService.getConfig('CaseInformationLocation', true, false, true);
    this.caseCategoryConfig = this.dfcService.getConfig('CaseInformationCaseCategory', true, false, true);
    this.caseTypeConfig = this.dfcService.getConfig('CaseInformationCaseType', true, false, true);
    this.caseSubTypeConfig = this.dfcService.getConfig('CaseInformationCaseSubType', true, false, false);
    this.caseInformationShortTitleConfig = this.dfcService.getConfig('caseInformationShortTitleConfig', false, false, false);
    this.caseInformationLowerCourtConfig = this.dfcService.getConfig('CaseInformationLowerCourt', false, false, false);
    this.lowerCourtCaseNumberConfig = this.dfcService.getConfig('CaseInformationLowerCourtCaseNumber', true, false, true);
    this.otherCaseNumberConfig = this.dfcService.getConfig('CaseInformationOtherCaseNumber', true, false, false);
    this.lowerCourtCaseStyleConfig = this.dfcService.getConfig('CaseInformationLowerCourtCaseStyle', true, false, false);
    this.lowerCourtNameConfig = this.dfcService.getConfig('CaseInformationLowerCourtName', true, false, false);
    this.lowerCourtCaseJudgeConfig = this.dfcService.getConfig('CaseInformationLowerCourtCaseJudge', true, false, false);

    if (this.caseType) {
      var isWillFiledDateVisible = !!this.caseType.requireWillFiledDate;
      var isWillFiledDateRequired = this.caseType.requireWillFiledDate;
      this.willFiledDateConfig = new DataFieldConfigDefaults(isWillFiledDateVisible, isWillFiledDateVisible, isWillFiledDateRequired);
    }
    this.visibleConfig = new DataFieldConfigDefaults(true, false, false);

    //Business logic determines which of two different data field configs are referenced when Case Category is selected
    this.damageAmountConfig = new DataFieldConfigDefaults(false, false, false);
    this.procedureRemedyConfig = new DataFieldConfigDefaults(false, false, false);

  }

  getDateFormat() {
    return Constants.DATE_FMT;
  }

  next() {
    var component = 'envelope';
    this.envButtonEvent.emit({ component, key: undefined });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
