export class BasePagingFilter {
  constructor(
    public perPage: number = 20,
    public pageNumber: number = 1
  ) { }

  maxPage(totalRows: number): number {
    if (totalRows < 2) return 1;

    return Math.trunc((totalRows - 1) / this.perPage) + 1;
  }
}
