import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatDialogModule } from "@angular/material/dialog";

import { Subscription } from 'rxjs/internal/Subscription';
import { merge, Observable } from 'rxjs';
import { ConnectionService } from '../../common/service/connection.service';
import { QueueItem } from '../../common/model/queue/queue-item';
import { Router } from '@angular/router';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { QueueRequest } from '../../common/model/queue/queue-request';
import { iMessage } from '../../common/response/message';
import { QueueService } from '../../common/service/queue.service';



let EnvelopeData: QueueItem[] = [
];





@Component({
  selector: 'app-review-queue',
  templateUrl: './review-queue.component.html',
  styleUrls: ['./review-queue.component.css']
})




export class ReviewQueueComponent implements AfterViewInit, OnDestroy  {


  
  queueList: Array<QueueItem>;

  page: number = 0;
  pageSize: number = 25;

  size: number = 1000;
  total: number = 10;
  
  displayedColumns = [
    { def: 'envelope', label: 'Envelope', hide: false },
    { def: 'submitDate', label: 'Submit Date', hide: false },
    { def: 'filingCode', label: 'Filing Code', hide: false },
    { def: 'filingDescription', label: 'Filing Description', hide: false },
    { def: 'caseNumber', label: 'Case #', hide: false },
    { def: 'caseType', label: 'Case Type', hide: false },
    { def: 'judge', label: 'Judge', hide: false },
    { def: 'filingAttorney', label: 'Filing Attorney', hide: false },
    { def: 'queue', label: 'Queue', hide: false },
    { def: 'reviewer', label: 'Reviewer', hide: false },
    { def: 'showWorkItem', label: 'Lock', hide: false }
  ]
  

  dataSource = new MatTableDataSource(EnvelopeData);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;



  
  //item: any;

  contentMenuTriggeredFor: MatMenu;
  signalrSub: Subscription;
  constructor(private connectionService: ConnectionService, private router: Router, private queueService: QueueService) {}
  ngOnInit() {
    this.dataSource.data = [];
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getworkitems': {
            this.queueList = [];
            console.log(data);
            this.queueList = JSON.parse(data).taskresult.items;
            this.size = JSON.parse(data).taskresult.totalItems;
            console.log(this.size);
            this.dataSource.data = [];
            this.dataSource.data.push(...this.queueList);
            this.dataSource._updateChangeSubscription();
            this.queueService.queueSearchComplete.next(true);
          }
        };
      });    
  }

  ngAfterViewInit() {
    
    //this.dataSource.sort = this.sort;

    //const jsonLocations = "{\"tenant\": \"ofs-demo\", \"action\": \"getworkitems\",\"tenant\": \"ofs-demo\",\"userId\": \"00000000-0000-0000-0000-000000000000\", \"pageIndex\": 0, \"pageSize\": 10, \"queueIds\": [], \"nodeIds\": [], \"includeDeferred\": false  }";
    //this.connectionService.sendApiMessage(jsonLocations);
    this.getQueue();
  
  }

  


  contextMenuPosition = { x: '0px', y: '0px' };
  
  getDisplayedColumns(): string[] {
    return this.displayedColumns.filter(cd => !cd.hide).map(cd => cd.def);
  }



  openEnvelope(envelopeId, workItemId) {

    this.queueService.envelopeId = envelopeId;
    this.queueService.workItemId = workItemId;

    

    this.router.navigate(["/viewer"]);
  }

  getQueue() {

    this.queueService.getQueue();

    /*
    let qr = new QueueRequest();
    qr.tenant = "ofs-demo";
    qr.action = "getworkitems";
    qr.userId = "00000000-0000-0000-0000-000000000000";
    qr.pageIndex = page;
    qr.pageSize = pageSize;
    qr.queueIds = [];
    qr.nodeIds = [];
    qr.includeDeferred = false;

    //const jsonLocations = "{\"tenant\": \"ofs-demo\", \"action\": \"getworkitems\",\"tenant\": \"ofs-demo\",\"userId\": \"00000000-0000-0000-0000-000000000000\", \"pageIndex\": " + page + ", \"pageSize\": " + pageSize + ", \"queueIds\": [], \"nodeIds\": [], \"includeDeferred\": false  }";


    const jsonQueueRequest = JSON.stringify(qr);

    this.connectionService.sendApiMessage(jsonQueueRequest);
    */
  }


  pageChanged(event) {

    this.queueService.page = event.pageIndex;
    this.queueService.pageSize = event.pageSize;

    this.queueService.getQueue();


    //const jsonLocations = "{\"tenant\": \"ofs-demo\", \"action\": \"getworkitems\",\"tenant\": \"ofs-demo\",\"userId\": \"00000000-0000-0000-0000-000000000000\", \"pageIndex\": " + event.pageIndex + ", \"pageSize\": " + event.pageSize +", \"queueIds\": [], \"nodeIds\": [], \"includeDeferred\": false  }";
    //this.connectionService.sendApiMessage(jsonLocations);

    console.log(event);
  }
  
  ngOnDestroy() {
    this.queueService.queueSearchComplete.next(false);
  }
}
