import { Input, Output, Component, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { textPattern } from 'src/app/common/component/data-fields/text-field/text-regex-validator';

@Component({
  selector: 'app-cross-reference-input',
  templateUrl: './cross-reference-input.component.html',
  styleUrls: ['./cross-reference-input.component.css']
})

export class CrossReferenceInputComponent {
  @Input() form: FormGroup = new FormGroup({});
  @Input() name: string = "";
  @Input() pattern: string = "";
  @Input() errorMessage: string = "";

  // Two-way binding for value
  @Input() value: string | undefined;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  isEditable: boolean = true;
  id: string = "crossReferenceId";
  isRequired: boolean = false;
  requiredErrorMsg: string = "Reference Id Required";

  constructor() { }

  ngOnInit() {
    this.form.addControl(this.id, new FormControl({ value: this.value, disabled: true }));
    this.form.get(this.id)!.valueChanges.subscribe(val => { this.value = val; this.valueChange.emit(this.value); });
    const field = this.form.get(this.id);
    if (field) {
      field.enable();
      setTimeout(() => {
        field.setValidators(this.getValidators());
        field.updateValueAndValidity();
      });
    }
    //todo
    //this.strings.T('Reference Id Required').subscribe(translated => {
    //  this.requiredErrorMsg = translated;
    //});
  }

  protected getValidators(): ValidatorFn[] {
    var result: ValidatorFn[] = [];
    if (this.isEditable) {
      result.push(Validators.required);
      if (this.pattern) {
        const validator = textPattern(this.pattern);
        if (validator) {
          result.push(validator);
        }
      }
      else {
        this.pattern = "";
      }
    }
    return result;
  }

  ngOnDestroy() {
    this.form.removeControl(this.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.setFormValue(changes['value'].currentValue);
    }
    const field = this.form.get(this.id);
    if (field) {
      field.setValidators(this.getValidators());
    }
  }

  get currentFormControl() {
    return this.form.controls[this.id];
  }

  private setFormValue(val: any) {
    if (this.form) {
      const field = this.form.get(this.id);
      if (field && field instanceof FormControl) {
        if (field.value != val) {
          field.setValue(val);
        }
      }
    }
  }
}
