import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isEqualTo' })

export class IsEqualToPipe implements PipeTransform {
  transform(items: Array<any>, filter: { [key: string]: any }): Array<any> {
    return items.filter(item => {
      let noMatch = Object.keys(filter)
        .find(key => item[key] !== filter[key]);

      return !noMatch;
    });
  }
}
