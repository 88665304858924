import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { iCaseCrossReferenceCode } from 'src/app/common/model/config/case-cross-reference-code';
import { iCaseCrossReference, CaseCrossReference } from 'src/app/common/model/envelope/case-cross-reference';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { iCase } from 'src/app/common/model/envelope/case';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import * as lodash from 'lodash';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { CaseTypeInput } from 'src/app/common/request/case-type-input';
import { ConnectionService } from 'src/app//common/service/connection.service';
import { iMessage } from 'src/app/common/response/message';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { getSelectedValueOrDefault } from 'src/app/common/component/data-fields/dropdown-field/dropdown-logic';

@Component({
  selector: 'app-edit-cross-reference',
  templateUrl: './edit-cross-reference.component.html',
  styleUrls: ['./edit-cross-reference.component.css']
})
export class EditCrossReferenceComponent implements OnInit, OnDestroy {

  constructor(private caseService: CaseService, private envelopeService: EnvelopeService, private toaster: CustomToastrService, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper) { }
  form: FormGroup = new FormGroup({});

  @Input() caseData: iCase;
  @Input() caseCrossReferenceCodes: iCaseCrossReferenceCode[];
  @Input() caseCrossReference: iCaseCrossReference;
  @Input() isCaseCrossReferenceVisible: boolean;
  @Input() isCaseCrossReferenceValid: boolean;
  @Input() caseCrossReferencesInitial: iCaseCrossReference[] = [];

  @Output() showCaseInformationEvent = new EventEmitter();

  caseCrossReferenceConfig: iDataFieldConfig = new DataFieldConfigDefaults(true, false, true);
  caseCrossReferenceCode: iCaseCrossReferenceCode;

  selectedCcrIndex: number;
  showEditablePage: boolean;

  subscriptions: Subscription[] = [];

  ngOnInit() {
    this.detectChanges();
 }

  editCaseCrossReference(caseCrossReference: CaseCrossReference, index: number) {
    this.caseCrossReference = caseCrossReference;
    this.caseCrossReferenceCode = undefined;
    const found = getSelectedValueOrDefault(this.caseCrossReferenceCodes, this.caseCrossReferenceCode, caseCrossReference.codeId, (ccr) => ccr.codeId, false);
    this.caseCrossReferenceCode = found;
  }

  delete() {
    if (this.caseCrossReference) {
      if (!this.caseCrossReference.isCreated) {
        if (!this.caseCrossReference.isRequired) {
          this.caseData.caseCrossReferences = this.caseData.caseCrossReferences.slice(this.selectedCcrIndex, 1)
        }
      }
      else {
        this.caseCrossReference.isDeleted = true;
      }
      this.close();
    }
  }

  crossReferenceTypeChanged(code: iCaseCrossReferenceCode) {
    if (code) {
      this.caseCrossReference.codeId = code.codeId;
      this.caseCrossReference.description = code.description;
      this.caseCrossReference.validationRegex = code.validationRegex;
      this.caseCrossReference.validationFailureCustomMessage = code.validationFailureCustomMessage;
    }
  }

  detectChanges() {
    this.subscriptions.push(this.form.statusChanges.subscribe(status => {
      if (status == "INVALID") {
        this.isCaseCrossReferenceValid = false;
      }
      else {
        this.isCaseCrossReferenceValid = true;
      }
    }));
  }

  close() {
    this.caseCrossReference = undefined;
    this.showCaseInformationEvent.emit();
  }

  cancel() {
    this.caseData.caseCrossReferences[this.selectedCcrIndex] = this.caseCrossReferencesInitial[this.selectedCcrIndex];
    this.caseData.caseCrossReferences = this.caseData.caseCrossReferences.concat();
    this.close();
  }

  continue() {
    if (this.selectedCcrIndex == -1) {
      this.caseData.caseCrossReferences.push(this.caseCrossReference);
    }
    this.close();
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
 }
}
