import { Injectable } from '@angular/core';
import { BaseRequest } from 'src/app/common/request/base-request';

@Injectable()
export class ApiRequestHelper {

  buildMessage<T>(T: BaseRequest) {
    T.tenant = 'ofs-demo';
    T.messageId = "1";
    T.connectionId = 'test';
    return JSON.stringify(T);
  }
} 
