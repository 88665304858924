import { Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { PartyService } from 'src/app/common/service/party.service';
import { iParty } from 'src/app/common/model/envelope/party';
import { AttorneyService } from 'src/app/common/service/attorney.service';
import { iAttorney } from 'src/app/common/model/data/attorney';
import { iPartyTypeCode } from 'src/app/common/model/config/party-type-code';
import { iConfigCode } from 'src/app/common/model/config/config-code';

@Component({
  selector: 'app-party-information',
  templateUrl: './party-information.component.html',
  styleUrls: ['./party-information.component.css']
})
export class PartyInformationComponent implements OnInit, OnDestroy {
  @Input() parties: iParty[];
  @Input() partyId: number;

  //retrieved in envelope-view.component
  @Input() countries: iConfigCode[] | undefined;

  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();
  @Output() envPartyButtonEvent: EventEmitter<{
    component: string, key: number, partyTypes: iPartyTypeCode[], suffixes: iConfigCode[], countries: iConfigCode[], attorneys: iAttorney[],
    dlTypes: iConfigCode[], genders: iConfigCode[], languages: iConfigCode[], isNewParty: boolean
  }> =
    new EventEmitter<{
      component: string, key: number, partyTypes: iPartyTypeCode[], suffixes: iConfigCode[], countries: iConfigCode[], attorneys: iAttorney[],
      dlTypes: iConfigCode[], genders: iConfigCode[], languages: iConfigCode[], isNewParty: boolean
    }>();

  isPartiesLoaded: boolean;
  totalPartyCount: number = 0;
  isPartyCountGreaterThanThreshold: boolean;

  partyTypes: iPartyTypeCode[] | undefined;
  suffixes: iConfigCode[] | undefined;
  attorneys: iAttorney[] | undefined;
  dlTypes: iConfigCode[];
  genders: iConfigCode[];
  languages: iConfigCode[];
 
  isPartyTypesComplete: boolean = false;
  isSuffixesComplete: boolean = false;
  isAttorneysComplete: boolean = false;
  isCountriesComplete: boolean = false;
  isDriversLicenseStatesComplete: boolean = false;
  isDriversLicenseTypesComplete: boolean = false;
  isGendersComplete: boolean = false;
  isLanguagesComplete: boolean = false;
  isEditPartyClicked: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, private partyService: PartyService, private attorneyService: AttorneyService) { }

  ngOnInit() {
    this.partyService.selectedParty.next(undefined);

    this.subscriptions.push(this.partyService.parties.subscribe(parties => {
      if (parties) {
        this.isPartiesLoaded = true;
      }
    }));
    this.subscriptions.push(this.partyService.totalParties.subscribe(count => {
      this.totalPartyCount = count;
      this.isPartyCountGreaterThanThreshold = count > 10000 ? true : false;
    }));
  }
 
  onPartyButtonEvent($event: { component: string, key: number, isNewParty: boolean }): void {
    var component = "editParty";
    var key = $event.key;
    //Event handled in envelope-view.component onEnvPartyButtonEvent()
    this.envPartyButtonEvent.emit({
      component, key, partyTypes: this.partyTypes, suffixes: this.suffixes, countries: this.countries, attorneys: this.attorneys, 
      dlTypes: this.dlTypes, genders: this.genders, languages: this.languages, isNewParty: $event.isNewParty
    });
  }

  previous() {
    var component = 'envelope';
    this.envButtonEvent.emit({ component, key: 0 });
  }

  next() {
    var component = 'filing';
    this.envButtonEvent.emit({ component, key: 0 });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
