import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { iPartyAttorney } from 'src/app/common/model/envelope/party-attorney';
import { iAttorney } from 'src/app/common/model/data/attorney';
import { AttorneyFilter } from 'src/app/common/service/filter/attorney-filter';
import { FilterArray } from 'src/app/common/helper/filter-array';
import { PagedDataResults } from 'src/app/common/model/response/paged-model';
import { ConnectionService } from './connection.service';

@Injectable({
  providedIn: 'root'
})
export class AttorneyService {

  public selectedPartyAttorneys: BehaviorSubject<iPartyAttorney[]> = new BehaviorSubject<iPartyAttorney[]>([]);
  public currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public maxPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public numberOfResults: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public attorneyFilter: BehaviorSubject<AttorneyFilter> = new BehaviorSubject<AttorneyFilter>(new AttorneyFilter());
  public filterResult: BehaviorSubject<PagedDataResults<iAttorney>> = new BehaviorSubject<PagedDataResults<iAttorney>>(new PagedDataResults<iAttorney>());

  public filter: FilterArray;
  public propertiesToFilterOn: string[] = ['attorneyName', 'barNumber'];

  signalrSub: Subscription;

  constructor(private filterArray: FilterArray, private connectionService: ConnectionService) {
    this.filter = filterArray;
  }

  getFilteredAttorneys(attorneys: iAttorney[], filter: AttorneyFilter, pageNumber: number = 1) {
    var result = this.filter.filterArray(attorneys, "attorneyId", this.propertiesToFilterOn, filter.searchFilter);
    var pagedData = new PagedDataResults<iAttorney>(result, result.length, '');

    let maxPage = filter.maxPage(attorneys.length);
    this.maxPage.next(maxPage);
    this.numberOfResults.next(attorneys.length);
    this.currentPage.next(Math.min(filter.pageNumber, maxPage));
    if (attorneys.length > filter.perPage) {
      this.getSelectedPage(pagedData, filter, pageNumber);
    }
    this.filterResult.next(pagedData);
  }

  getSelectedPage(pagedData: PagedDataResults<iAttorney>, filter: AttorneyFilter, pageNumber: number) {
    var sliceStart = pageNumber * filter.perPage - 10;
    var sliceEnd = sliceStart + filter.perPage < pagedData.items.length ? sliceStart + filter.perPage : pagedData.items.length;
    pagedData.items = pagedData.items.slice(sliceStart, sliceEnd)
  }
}
