import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { EditStateHandler } from 'src/app/common/helper/edit-state-handler';
import { iEditStatus } from 'src/app/common/model/observable/edit-status';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

@Component({
  selector: 'app-envelope-sidebar',
  templateUrl: './envelope-sidebar.component.html',
  styleUrls: ['./envelope-sidebar.component.scss']
})
export class EnvelopeSidebarComponent implements OnInit {
  @Input() isCaseActive: boolean = true;
  @Input() isEnvelopeActive: boolean = false;
  @Input() isPartyActive: boolean = false;
  @Input() isFileActive: boolean = false;
  @Input() isServeActive: boolean = false;
  @Input() isFeesActive: boolean = false;
  @Input() isNotesActive: boolean = false;

  docVisible: boolean = true;
  caseVisible: boolean = true;
  envVisible: boolean = true;
  partyVisible: boolean = true;
  chargesVisible: boolean = false;
  fileVisible: boolean = true;
  serveVisible: boolean = true;
  feesVisible: boolean = true;
  notesVisible: boolean = true;

  isInvalid: boolean = false;
  isReady: boolean = false;
  editStatus: iEditStatus = { isValid: true, isDirty: false, currentComponent: 0, errorMessage: '' };

  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();

  private subscriptions: Subscription[] = [];

  constructor(private envelopeService: EnvelopeService, private editStateHandler: EditStateHandler, private toaster: CustomToastrService) { }

  ngOnInit() {
    this.subscriptions.push(this.envelopeService.editStatus.subscribe(editStatus => {
      this.editStatus = editStatus;
    }));
    setTimeout(() => {
      this.isReady = true; //delay for fontawesome fonts
    }, 500);

    this.subscriptions.push(this.envelopeService.showComponent.subscribe(show => {
      if (!show) {
        return;
      }

      this.showComponent(show.component);
    }));
  }

  onClickBtn(component: string) {
    if (!this.editStateHandler.canContinueIncludingSave(this.editStatus, undefined, undefined, component)) {
      return;
    }
    this.showComponent(component);
  }

  showComponent(component: string) {
    var key = 0;
    this.envButtonEvent.emit({ component, key });

    this.initializeToNotActive();
    switch (component) {
      case "case":
        this.isCaseActive = true;
        break;
      case "envelope":
        this.isEnvelopeActive = true;
        break;
      case "party":
        this.isPartyActive = true;
        break;
      case "filing":
        this.isFileActive = true;
        break;
      case "serve":
        this.isServeActive = true;
        break;
      case "fees":
        this.isFeesActive = true;
        break;
      default:
        break;
    }
  }

  initializeToNotActive() {
    this.isCaseActive = false;
    this.isEnvelopeActive = false;
    this.isPartyActive = false;
    this.isFileActive = false;
    this.isServeActive = false;
    this.isFeesActive = false;
  }
}
