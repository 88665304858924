import { iCode } from 'src/app/common/model/envelope/code';

export class FilingAssociatedParty implements iFilingAssociatedParty {
  constructor(
    public casePartyId: number = -1,
    public partyName: string,
    public partyType: iCode,
    public isCreated: boolean = false,
    public isDeleted: boolean = false, 
 ) { }
}

export interface iFilingAssociatedParty {
  casePartyId: number;
  partyName: string;
  partyType: iCode;

  //used for save
  isCreated: boolean;
  isDeleted: boolean; 
}

