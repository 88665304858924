import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/common/constants';
import { Subscription } from 'rxjs';
import { ConnectionService } from './connection.service';




@Injectable({
  providedIn: 'root'
})

//Note:
// - Don't translate the title. It's done here.
// - Combine all messages on the same toast.Otherwise, not all readers will read multiple. Use a comma to force a pause between the messages.
// - The toast title doesn't have an aria.  Make sure there's always a message.
export class CustomToastrService {

  signalrSub: Subscription;

  constructor(
    private toastr: ToastrService
  ) {}

  standardExpire: Partial<IndividualConfig> = {
    enableHtml: true,
    timeOut: 6000,        //needs to be long enough for aria to be read
    extendedTimeOut: 6000 //extendedTimeOut is the hover timeout
  };

  expire15: Partial<IndividualConfig> = {
    enableHtml: true,
    timeOut: 15000,
    extendedTimeOut: 6000
  };

  expire30: Partial<IndividualConfig> = {
    enableHtml: true,
    timeOut: 30000, //used for exception message. needs to be long enough for user to screen print/write down/listen to aria
    extendedTimeOut: 6000
  };

  noExpire: Partial<IndividualConfig> = {
    enableHtml: true,
    closeButton: true,
    timeOut: 0,
    extendedTimeOut: 6000
  };

  messageCustomConfig: Partial<IndividualConfig> = this.standardExpire;
  toastType: string = 'toast-info';
  message?: string;
  title?: string;
  timeout?: string;

  getToastType(type: string) {
    switch (type) {
      case ('success'):
        return 'toast-success';
      case ('info'):
        return 'toast-info';
      case ('error'):
        return 'toast-error';
      case ('warning'):
        return 'toast-warning';
    }
    return 'toast-info';
  }

  pop(type: string | Toast, title?: string, message?: string, timeout?: string) {
    this.parseParameters(type, title, message, timeout);
    // Html is enabled on each config option. The message is wrapped with a <span>. Otherwise, html such as <br> will be sanitized away. (</br> is sanitized to <br>)
    this.message = '<span>' + this.message + '</span>';
    this.toastr.show(
      this.message,
      this.title,
      this.messageCustomConfig,
      this.getToastType(this.toastType)
    );
  }

  parseParameters(type: string | Toast, title?: string, message?: string, timeout?: string) {
    if (typeof type == 'string') {
      this.toastType = type;
      this.message = message
      this.title = title;
      this.timeout = timeout;
    }
    else {
      this.toastType = type.type;
      this.message = type.message;
      this.title = type.title;
      this.timeout = type.timeout;
    }

    switch (this.timeout) {
      case Constants.ToasterTimeoutLong: {
        this.messageCustomConfig = this.expire15; //15 second timeout
        break;
      } 
      case Constants.ToasterTimeoutLongest: {
        this.messageCustomConfig = this.expire30; //30 second timeout
        break;
      } 
      case Constants.ToasterTimeoutNone: {
        this.messageCustomConfig = this.noExpire; //no timeout
        break;
      } 
    }
  }
}

export interface Toast {
  type: string;
  message: string;
  title?: string;
  timeout?: string
}
