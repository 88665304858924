import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { QueueService } from '../../../common/service/queue.service';

import { Subscription } from 'rxjs/internal/Subscription';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';











@Component({
  selector: 'app-review-queue-filter-date-range',
  templateUrl: './review-queue-filter-date-range.component.html',
  styleUrls: ['./review-queue-filter-date-range.component.css'],
 
 
})
export class ReviewQueueFilterDateRangeComponent implements OnInit {


  @ViewChild(DaterangepickerDirective, { static: true }) daterangepicker: DaterangepickerDirective;
  //selected: { start: moment.Moment, end: moment.Moment };

  constructor(private queueService: QueueService) { }

  queueSub: Subscription;


  options: any = {
    autoApply: true,
    alwaysShowCalendars: false,
    showCancel: false,
    showClearButton: false,
    linkedCalendars: true,
    singleDatePicker: false,
    showWeekNumbers: false,
    showISOWeekNumbers: false,
    customRangeDirection: false,
    lockStartDate: false,
    closeOnAutoApply: true
  };

  ngOnInit(): void {
    this.queueSub = this.queueService.queueSource$.subscribe(
      (data) => {
        console.log(data);
        this.clear();
      }
    );
  }

  choosedDate(event: any) {
    console.log(event.startDate);
    if (event.startDate !== null && event.endDate !== null) {
      console.log(event.startDate.format("YYYY-MM-DD"));
      console.log(event.endDate.format("YYYY-MM-DD"));
      this.queueService.filterStartDate = event.startDate.format("YYYY-MM-DD");
      this.queueService.filterEndDate = event.endDate.format("YYYY-MM-DD");
    }

  }
  clear() {
    //this.daterangepicker.clear();
    /*this.daterangepicker.picker.clear();
    this.daterangepicker.clear();
    this.daterangepicker.picker.startDate = null;
    this.daterangepicker.picker.endDate = null;
    */

    console.log(this.daterangepicker.picker);
  //  this.daterangepicker.picker.startDate = moment().startOf('day');
    //this.daterangepicker.picker.endDate = moment().endOf('day');
    //this.daterangepicker.picker.choosedDate.emit({ chosenLabel: '', startDate: moment().startOf('day'), endDate: moment().endOf('day') });
    //this.daterangepicker.picker.datesUpdated.emit({ startDate: moment().startOf('day'), endDate: moment().endOf('day') });
    //this.daterangepicker.picker.hide();

    this.daterangepicker.picker.setStartDate(moment().fromNow());
    this.daterangepicker.picker.setEndDate(moment().fromNow());
    this.daterangepicker.picker.updateMonthsInView();
//    this.daterangepicker.picker.updateElement();


    console.log(this.daterangepicker.picker);
    this.queueService.filterStartDate = null;
    this.queueService.filterEndDate = null;
  }

}

