import { Component, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { FilingService } from 'src/app/common/service/filing.service';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { PartyService } from 'src/app/common/service/party.service';

@Component({
  selector: 'app-temp-envelope-list',
  templateUrl: './temp-envelope-list.component.html',
  styleUrls: ['./temp-envelope-list.component.css']
})
export class TempEnvelopeListComponent implements OnDestroy {
  public form: FormGroup = new FormGroup({});
  @ViewChild('envelopeModal') returnModal;

  envelopeId: string = "";
  workitemId: string = "";

  modalReference: any;
  private subscriptions: Subscription[] = [];

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, private filingService: FilingService, private webviewerService: WebviewerService, private partyService: PartyService,
    private modalService: NgbModal, private config: NgbModalConfig, private hotkeysService: HotkeysService) {
    config.backdrop = false;
    config.keyboard = false;
    config.windowClass = 'custom-modal';
    hotkeysService.add(new Hotkey('ctrl+shift+e', (event: KeyboardEvent): boolean => {
      console.log('Typed hotkey');
      this.modalReference = this.modalService.open(this.returnModal);
      this.modalReference.result.then(exit => {
        this.exit();
      });
      return false; // Prevent bubbling
    }));}

  selectEnvelope(envelopeId: number, workitemId: number) {
    setTimeout(() => {
      this.envelopeService.envelopeMetadata.next({ envelopeId: envelopeId, workitemId: workitemId });
    }, 100);
  }

  getEnvelope() {
    if (this.envelopeId) {
      if (!this.workitemId) {
        this.workitemId = '0';
      }
      this.envelopeService.envelopeMetadata.next({ envelopeId: parseInt(this.envelopeId), workitemId: parseInt(this.workitemId) });
    }
  }
  exit() {
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
