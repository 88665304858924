import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { iFilingDetail } from 'src/app/common/model/envelope/filing-detail';
import { PagedModel, PagedDataResults } from 'src/app/common/model/response/paged-model';
import { FilingFilter } from 'src/app/common/service/filter/filing-filter';
import { iFilingCode } from 'src/app/common/model/config/filing-code';

@Injectable({
  providedIn: 'root'
})
export class FilingService {
  //The filing selected for editing
  public selectedFilingId: BehaviorSubject<number> = new BehaviorSubject<number | undefined>(undefined);
  public filings: BehaviorSubject<PagedModel<iFiling>> = new BehaviorSubject<PagedModel<iFiling>>(new PagedModel<iFiling>());
  public selectedFilingDetail: BehaviorSubject<iFilingDetail> = new BehaviorSubject<iFilingDetail | undefined>(undefined);
  public filingDetails: BehaviorSubject<PagedModel<iFilingDetail>> = new BehaviorSubject<PagedModel<iFilingDetail>>(new PagedModel<iFilingDetail>());
  public filingsWithErrors: BehaviorSubject<number[]> = new BehaviorSubject<number[] | undefined>(undefined);
  public filingCodes: BehaviorSubject<iFilingCode[]> = new BehaviorSubject<iFilingCode[] | undefined>(undefined);

  public currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public maxPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public numberOfResults: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public filingFilter: BehaviorSubject<FilingFilter> = new BehaviorSubject<FilingFilter>(new FilingFilter());
  public filterResult: BehaviorSubject<PagedDataResults<iFiling>> = new BehaviorSubject<PagedDataResults<iFiling>>(new PagedDataResults<iFiling>());

  constructor() {}

  getFilteredFilings(filings: iFiling[], filter: FilingFilter, pageNumber: number = 1) {
    var pagedData = new PagedDataResults<iFiling>(filings, filings.length)
    let maxPage = filter.maxPage(filings.length);
    this.maxPage.next(maxPage);
    this.numberOfResults.next(filings.length);
    this.currentPage.next(Math.min(filter.pageNumber, maxPage));
    if (filings.length > filter.perPage) {
      this.getSelectedPage(pagedData, filter, pageNumber);
    }
    this.filterResult.next(pagedData);
  }

  getSelectedPage(pagedData: PagedDataResults<iFiling>, filter: FilingFilter, pageNumber: number) {
    var sliceStart = pageNumber * filter.perPage - 10;
    var sliceEnd = sliceStart + filter.perPage < pagedData.items.length ? sliceStart + filter.perPage : pagedData.items.length;
    pagedData.items = pagedData.items.slice(sliceStart, sliceEnd)
  }
}
