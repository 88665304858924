import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FeesService } from 'src/app/common/service/fees.service';
import { CaseService } from 'src/app/common/service/case.service';
import { iOrgChart } from 'src/app/common/model/config/org-chart';
import { iPartyBasic } from 'src/app/common/model/envelope/party-basic';
import { IFeeAndPaymentInformation } from 'src/app/common/model/envelope/fee-and-payment-information';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { BaseRequest } from 'src/app/common/request/base-request';
import { SaveFeeInput } from 'src/app/common/request/save-fee-input';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { getSelectedValueOrDefault } from 'src/app/common/component/data-fields/dropdown-field/dropdown-logic';
import { FormGroup } from '@angular/forms';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { HtmlPropertyHelper } from 'src/app/common/helper/html-property-helper';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iMessage } from 'src/app/common/response/message';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { Constants } from 'src/app/common/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fees-information',
  templateUrl: './fees-information.component.html',
  styleUrls: ['./fees-information.component.css']
})
export class FeesInformationComponent implements OnInit, OnDestroy {
  public form: FormGroup = new FormGroup({});
  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();

  envelopeId: number;
  workitemId: number;
  feesAndPayment: IFeeAndPaymentInformation;

  party: iPartyBasic;  //On save, update prff with party.casePartyId
  parties: iPartyBasic[];
  prffConfig: iDataFieldConfig | undefined;
  showPrff: boolean = true;
  locations: iOrgChart[] | undefined;
  location: iOrgChart;

  stopDirtyCheck: boolean = false;
  isSaving: boolean = false;
  completedSave: boolean = false;
  saveNextComponent: string;

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  constructor(private envelopeService: EnvelopeService, private feesService: FeesService, private htmlPropertyHelper: HtmlPropertyHelper, private caseService: CaseService,
    public element: ElementRef, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper, private toaster: CustomToastrService, private router: Router
  ) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.caseService.locations.subscribe(_locations => {
      if (_locations) {
        this.locations = _locations;
        this.isPrffVisible();
      }
    }));

    this.subscriptions.push(this.envelopeService.envelopeMetadata.subscribe(meta => {
      if (!meta) {
        return;
      }
      this.envelopeId = meta.envelopeId;
      this.workitemId = meta.workitemId;

      this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
        if (!_case) {
          return;
        }
        this.location = _case.location;
        this.subscriptions.push(this.caseService.locations.subscribe(_locations => {
          if (_locations) {
            this.locations = _locations;
            var idx = this.locations.findIndex(l => l.nodeId = _case.location.nodeId);
            if (idx > -1) {
              this.location = this.locations[idx];
              this.isPrffVisible();
            }
          }
        }));
      }));

      this.subscriptions.push(this.feesService.fees.subscribe(fees => {
        if (!fees) {
          return;
        }
        this.feesAndPayment = fees;
        if (this.feesAndPayment.parties) {
          this.feesAndPayment.parties.forEach(p => {
            p.partyName += ' - ' + p.partyType.description
          });
          this.parties = this.feesAndPayment.parties;
          this.updatePrffFromModel();
          this.isPrffVisible();
        }
      }));
      this.prffConfig = new DataFieldConfigDefaults(true, false, false);
      this.detectChanges();
    }));
 

    this.subscriptions.push(this.envelopeService.doSave.subscribe(save => {
      if (!save) {
        return;
      }
      if (save.currentComponent == ComponentPage.Payment) {
        this.saveNextComponent = save.nextComponent;
        this.isSaving = true;
        this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.Payment, errorMessage: '' });
        this.isSaving = true;

        var saveFeeInput = new SaveFeeInput(null, null, null, 'savefees', this.envelopeId, this.workitemId, this.party.casePartyId);
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(saveFeeInput));
      }
    }));
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'savefees': {
            if (message.isSuccess === true) {
              if (!message.taskresult.isLockValid) {
                this.toaster.pop('error', 'Error', 'You no longer own the lock on this envelope.', Constants.ToasterTimeoutLong);
                this.router.navigate(["/queue"]);
                return;
              }
              this.toaster.pop('success', 'Saved', 'Fees and Payment Information changes have been saved');
              this.envelopeService.doSave.next(undefined);
              this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.Payment, errorMessage: '' });
              this.envelopeService.showComponent.next({ component: this.saveNextComponent, isSuccess: true, message: undefined })
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred saving Fees and Payment Information: ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutLong);
              this.envelopeService.doSave.next(undefined);
            }
            break;
          }
        }
     });
  }

  isPrffVisible() {
    if (this.feesAndPayment && this.location) {
      if (this.feesAndPayment && this.feesAndPayment.fees && this.feesAndPayment.fees.envelopeFees && this.feesAndPayment.fees.envelopeFees.length) {
        if (this.feesAndPayment.isWaiver) {
          if (!this.location.isTransferWaivedFeesToCMS) {
            this.showPrff = false;
          }
          else {
            if (this.location.isTransferWaivedFeesToCMS) {
              this.showPrff = true;
            }
          }
        }
        else {
          var totalFees = this.feesAndPayment.fees.envelopeFees[this.feesAndPayment.fees.envelopeFees.length - 1];
          if (totalFees && totalFees.amount === 0 && this.location.allowZeroFeeSubmissionWithoutResponsibleParty) {
            this.showPrff = false;
          }
        }
      }
    }
  }

  updatePrffFromModel() {
    if (this.feesAndPayment && this.parties) {
      const found = getSelectedValueOrDefault(this.parties, this.party, this.feesAndPayment.payeeId, (p) => p.casePartyId, true);
      this.party = found;
    }
  }

  detectChanges() {
    this.subscriptions.push(this.form.statusChanges.subscribe(status => {
      if (this.stopDirtyCheck) {
        this.stopDirtyCheck = false;
        return;
      }
      if (status == "INVALID") {
        this.envelopeService.editStatus.next({ isValid: false, isDirty: this.form.dirty, currentComponent: ComponentPage.Payment, errorMessage: '' });
      }
      else {
        this.envelopeService.editStatus.next({ isValid: true, isDirty: this.form.dirty && !this.completedSave, currentComponent: ComponentPage.Payment, errorMessage: '' });
      }
    }));
  }

  previous() {
    var component = 'serve';
    this.envButtonEvent.emit({ component, key: this.envelopeId });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
