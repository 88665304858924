export class BaseRequest implements iBaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string
  ) { }
}

export interface iBaseRequest {
  tenant: string;
  messageId: string;
  connectionId: string;
  action: string;
}


