import { Component, OnInit } from '@angular/core';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { EnvelopeService } from 'src/app/common/service/envelope.service';

@Component({
  selector: 'app-edit-tabs',
  templateUrl: './edit-tabs.component.html',
  styleUrls: ['./edit-tabs.component.css']
})
export class EditTabsComponent implements OnInit {
  actions: boolean = true;
  annotations: boolean = false;
  stamps: boolean = false;
  isFailedLock: boolean = false;
  
  constructor(private webviewerService: WebviewerService, private envelopeService: EnvelopeService) { }

  ngOnInit() {
    this.envelopeService.isFailedLock.subscribe(isFailedLock => this.isFailedLock = isFailedLock);
  }

  navOpen(value: string): void {

    if (value === "action") {
      this.actions = true;
      this.annotations = false;
      this.stamps = false;
    }
    else if (value === "annotations") {
      this.actions = false;
      this.annotations = true;
      this.stamps = false;

    }
    else if (value === "stamps") {
      this.actions = false;
      this.annotations = false;
      this.stamps = true;
    }

    console.log(value);
  }
}
