import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timer, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Collections from 'typescript-collections';



@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ConnectionService {


  messageReceived = new EventEmitter<string>();
  connectionEstablished = new EventEmitter<boolean>();

  private connectionIsEstablished = false;
  private _hubConnection: HubConnection;


  private signalrSource = new Subject<string | null>();
  signalrSource$ = this.signalrSource.asObservable();



  public connectionID: string;
  public connected: boolean = false;

  messageListTimer = timer(5000);
  messageList = new Collections.Dictionary<string, Date>();
  

  constructor(private http: HttpClient) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
    console.log('startConnection');
    
  }


  private startTimer(): void {

    this.messageListTimer
      .subscribe(x => {
        this.messageList.keys().forEach(id => {
          //console.log("message id: " + id + " " + this.messageList.getValue(id));

          if (new Date() > this.messageList.getValue(id)) {
            console.error("Date Error");
            this.messageList.remove(id);
          }

        });
      }, undefined, () => {
        this.startTimer();
      });
  }


  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async sendApiMessage(body: string) {

    //console.log(">>>>>>>>>"+this._hubConnection);
    const _this = this;
    let data;


    if (!this.connected) {
      for (var i = 0; i < 300; i++) {
        await this.delay(3000);
        if (this.connected) break;
      }
    }


    this._hubConnection.invoke('getConnectionId')
      .then(function (connectionId) {
        //console.log("~~~~~~~~~~~~" + connectionId);
        _this.connectionID = connectionId;
        _this.connected = true;
      });


    

    const obj = JSON.parse(body);
    obj.connectionId = this.connectionID;
    obj.messageId = uuid();
    const stringifyBody = JSON.stringify(obj, null, '\t');
    //console.log(stringifyBody);


    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 1);
    this.messageList.setValue(obj.messageId, currentDate);

    const headers = new HttpHeaders()
      .set('Authorization', "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImpvaG4gZG9lIiwibmJmIjoxNjEwNzMxMjMzLCJleHAiOjE2Mjg3Mjc2MzMsImlzcyI6ImVmbTpyZXZpZXc6YXBpIiwiYXVkIjoiZWZtOnJldmlldzphcGkifQ.XsYwMu4f1nnvWPEoKO8FykuKDDvQM97xzQDMvqsQDbk")
      .set('Content-Type', 'application/JSON');


    this.http
      .post<string>("https://ofs-demo.fileandservdev.tylertech.cloud/invoke", stringifyBody, { headers: headers })
      .subscribe(message => {
        //console.log(message);
        //data = message;
      }, error => {
          console.log(error);
          //data = error;
      }
      );
    //return data;
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl('https://sr-cluster.fileandservdev.tylertech.cloud/Hubs/ReviewAppHub')
      .build();
  }


  
  private startConnection(): void {
    const _this = this;
    this._hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log('Hub connection started');
        this.connectionEstablished.emit(true);
        this._hubConnection.invoke('getConnectionId')
          .then(function (connectionId) {
            _this.connectionID = connectionId;
            _this.connected = true;
          });
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        setTimeout(function () { this.startConnection(); }, 5000);
      });
  }

  

  public streamData(resourceID: string) {
    let data = "";
    const _this = this;
    this._hubConnection.stream("StreamData", resourceID).subscribe({
      next: function (item) {
        data += item
        //console.log("data");
      },
      error: function (err) {
        console.log(err);
      },
      complete: function () {
        console.log("complete length " + data.length);
        const obj = JSON.parse(data);
        _this.messageList.remove(obj.messageId);
        _this.signalrSource.next(data);
      }
    });
  }




  private registerOnServerEvents(): void {
    console.log("registerOnServerEvents");
    this._hubConnection.on('ReceiveMessageLarge', (data: string) => {
      console.log(">>>>>>>>>>>>>>" + data.length);
      this.streamData(data);
    });
    this._hubConnection.on('ReceiveMessage', (data: string) => {
      //console.log("<<<<<<<<<<<<<<<<<<" + data.length);
      const obj = JSON.parse(data);
      this.signalrSource.next(data);
      this.messageList.remove(obj.messageId);
    });
  }

  




}
