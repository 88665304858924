import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { FilingService } from 'src/app/common/service/filing.service';
import { DfcService } from 'src/app/common/service/dfc.service';
import { iCase } from 'src/app/common/model/envelope/case';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { iFilingDetail } from 'src/app/common/model/envelope/filing-detail';
import { iFilingCode } from 'src/app/common/model/config/filing-code';
import { FormGroup } from '@angular/forms';
import * as lodash from 'lodash';
import { EditFileComponent } from '../edit-file/edit-file.component';
import { EditAssociatedPartiesComponent } from '../edit-associated-parties/edit-associated-parties.component';
import { EditFileServiceComponent } from '../edit-file-service/edit-file-service.component';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { FilingInput } from 'src/app/common/request/filing-input';
import { SaveFileInput } from 'src/app/common/request/save-file-input';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iMessage } from 'src/app/common/response/message';
import { Constants } from 'src/app/common/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-file-tabs',
  templateUrl: './edit-file-tabs.component.html',
  styleUrls: ['./edit-file-tabs.component.scss']
})
export class EditFileTabsComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});

  envelopeId: number;
  workitemId: number;
  case: iCase;
  filings: iFiling[];
  fileTrees: FileTree[] = [];
  filingCodes: iFilingCode[] = [];

  filing: iFilingDetail;
  filingBeforeEdits: iFilingDetail;

  isEditFileTabActive: boolean = true;
  isAssocPartyTabActive: boolean = false;
  isServicesTabActive: boolean = false;
  isAssocPartyTabVisible: boolean = false;
  isSaving: boolean = false;
  isNextSelected: boolean = false;

  isInvalid: boolean = false;
  filingsWithErrors: number[] = [];

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  @ViewChild('editFile', { static: false }) editFile: EditFileComponent | undefined;
  @ViewChild('editAssocParties', { static: false }) editAssocParties: EditAssociatedPartiesComponent | undefined;
  @ViewChild('editFileService', { static: false }) editFileService: EditFileServiceComponent | undefined;

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @Output() tabEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, private filingService: FilingService, private webviewerService: WebviewerService, private dfcService: DfcService,
    private toaster: CustomToastrService, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper, private router: Router) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      this.case = _case;
      if (!this.case) {
        return;
      }

      this.subscriptions.push(this.filingService.filings.subscribe(result => {
        if (!result || !result.items || !result.items.length) {
          return;
        }
        this.filings = result.items;
      }));

      this.subscriptions.push(this.webviewerService.documentFileTree.subscribe(result => {
        if (!result) {
          return;
        }
        this.fileTrees = result;

        this.subscriptions.push(this.filingService.selectedFilingId.subscribe(filingId => {
          if (!filingId) {
            return;
          }
          this.getFilingDetail(filingId);

          var index = this.fileTrees.findIndex(tree => tree.filingId == filingId);
          if (index > -1) {
            var tree = this.fileTrees[index].children[0];
            tree.isViewed = true;
            this.webviewerService.selectedDocument.next(tree);
          }
        }));
      }));
    }));

    this.subscriptions.push(this.envelopeService.editStatus.subscribe(editStatus => {
      if (!editStatus) {
        return;
      }
      if (editStatus.currentComponent === ComponentPage.Filing ||
        editStatus.currentComponent === ComponentPage.AssociatedParties ||
        editStatus.currentComponent === ComponentPage.OptionalServices) {
        this.isInvalid = !editStatus.isValid;
      }
    }));

    this.subscriptions.push(this.dfcService.isDfcReady.subscribe(isReady => {
      if (isReady) {
        var associatedPartiesConfig = this.dfcService.getConfig('FilingEventCaseParties', true, false, false);
        this.isAssocPartyTabVisible = associatedPartiesConfig.isVisible;
      }
    }));

    this.subscriptions.push(this.envelopeService.envelopeMetadata.subscribe(meta => {
      if (!meta) {
        return;
      }
      this.envelopeId = meta.envelopeId
      this.workitemId = meta.workitemId
    }));

    this.subscriptions.push(this.filingService.filingsWithErrors.subscribe(filingIds => {
      this.filingsWithErrors = filingIds;
    }));
  }

  getFilingDetail(filingId: number) {
    var inputFiling = new FilingInput(null, null, null, 'getfilingdetail', filingId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputFiling));
  }


  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getfilingdetail': {
            if (message.isSuccess === true) {
              this.filing = message.taskresult as iFilingDetail;

              this.filing.documents.forEach(d => {
                d.filingComponentCode.description.replace('Document', '').replace('Attachments', 'Attachment');
                d.originalFilingComponentCodeId = d.filingComponentCode.codeId;
              });

              this.filingBeforeEdits = lodash.cloneDeep(this.filing);
              this.filingService.selectedFilingDetail.next((this.filing));
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting filing detail: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'savefiling': {
            if (message.isSuccess === true) {
              if (!message.taskresult.isLockValid) {
                this.toaster.pop('error', 'Error', 'You no longer own the lock on this envelope.', Constants.ToasterTimeoutLong);
                this.router.navigate(["/queue"]);
                return;
              }
              this.toaster.pop('success', 'Saved', 'Filing changes have been saved');
              this.isSaving = false;
              if (this.isNextSelected) {
                this.showNextFiling()
              }
              else {
                this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
                this.envButtonEvent.emit({ component: 'filing', key: undefined });
              }
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred saving the filing: ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutLong);
              this.envelopeService.doSave.next(undefined);
            }
            break;
          }
        }
      });
  }

  onTabEvent(value: string): void {
    switch (value) {
      case "edit":
        this.isEditFileTabActive = true;
        this.isAssocPartyTabActive = false;
        this.isServicesTabActive = false;
        break;
      case "assocParty":
        this.isEditFileTabActive = false;
        this.isAssocPartyTabActive = true;
        this.isServicesTabActive = false;
        break;
      case "service":
        this.isEditFileTabActive = false;
        this.isAssocPartyTabActive = false;
        this.isServicesTabActive = true;
        break;
      default:
        this.isEditFileTabActive = true;
        this.isAssocPartyTabActive = false;
        this.isServicesTabActive = false;
        break;
    }
  }

  isFormDirty() {
    if (!this.editFile || !this.editAssocParties || !this.editFileService) {
      return false;
    }
    return this.editFile.form.dirty || this.editAssocParties.form.dirty || this.editFileService.form.dirty;
  }

  isFormInvalid() {
    if (!this.editFile || !this.editAssocParties || !this.editFileService) {
      return false;
    }
    return this.editFile.form.invalid || !this.editAssocParties.isValid || this.editFileService.form.invalid;
  }

  close() {
    var index = this.filings.findIndex(f => f.filingId == this.filingBeforeEdits.filingId);
    this.editFile.stopDirtyCheck = true;
    this.editAssocParties.stopDirtyCheck = true;
    this.editFileService.stopDirtyCheck = true;
    this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });

    var component = "filing";
    var key = this.filing.filingId;
    this.envButtonEvent.emit({ component, key });
  }

  next() {
    if (this.isFormDirty()) {
      this.isNextSelected = true;
      this.save()
    }

    this.showNextFiling();
  }

  showNextFiling() {
    this.isNextSelected = false;
    if (this.filings.length === 1) {
      //Return to the Filing Information page
      var component = "filing";
      var key = this.filing.filingId;
      this.envButtonEvent.emit({ component, key });
    }

    setTimeout(() => {
      this.form.reset();

      var idx = this.filings.findIndex(f => f.filingId == this.filing.filingId);
      if (idx < this.filings.length - 1) {
        idx++;
      }
      else {
        idx = 0;
      }
      this.getFilingDetail(this.filings[idx].filingId);
    }, 1)
  }

  resetForm() {
    this.filing = undefined;
    this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
  }

  save() {
    this.isSaving = true;

    this.editFile.stopDirtyCheck = true;
    this.editAssocParties.stopDirtyCheck = true;
    this.editFileService.stopDirtyCheck = true;

    this.filing = this.editFile.getUpdates();
    if (this.isAssocPartyTabVisible) {
      this.filing.associatedParties = this.editAssocParties.getUpdates();
    }
    this.filing.optionalServices = this.editFileService.getUpdates();

    //remove the associated parties that aren't updated
    var filingToSave = lodash.cloneDeep(this.filing);
    filingToSave.associatedParties = this.filing.associatedParties.filter(p => p.isCreated || p.isDeleted);

    filingToSave.envelopeId = this.envelopeId;
    filingToSave.workItemId = this.workitemId;
    var saveFilingInput = new SaveFileInput(null, null, null, 'savefiling', filingToSave);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(saveFilingInput));

    //if (this.filingsWithErrors && this.filingsWithErrors.length) {
    //  var idx = this.filingsWithErrors.findIndex(id => id == this.filing.filingId);
    //  if (idx > -1) {
    //    this.filingsWithErrors.splice(idx, 1);
    //    this.filingService.filingsWithErrors.next(this.filingsWithErrors);
    //  }
    //}
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
