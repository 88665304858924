import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

function padYear(value: any) {
  if (isNumber(value)) {
    if (value >= 1000) {
      return value;
    }
    else if (value >= 100) {
      return `1${value}`.slice(-4);
    }
    else if (value >= 20) {
      return `19${value}`.slice(-4);
    }
    else if (value >= 10) {
      return `20${value}`.slice(-4);
    }
    else {
      return `200${value}`.slice(-4);
    }

  } else {
    return "";
  }
}

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

function defaultDay(): number {
  return 1;
}

function defaultMonth(): number {
  return 1;
}

@Injectable()
export class NgbDateENUSParserFormatter extends NgbDateParserFormatter {
  /**
   * Parses the given value to an NgbDateStruct. Implementations should try their best to provide a result, even
   * partial. They must return null if the value can't be parsed.
   * @param value the value to parse
   */
  static parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/').filter(s => s.length > 0);

      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(padYear(dateParts[0])), month: defaultMonth(), day: defaultDay() };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: toInteger(padYear(dateParts[1])), month: toInteger(dateParts[0]), day: defaultDay() };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { year: toInteger(padYear(dateParts[2])), month: toInteger(dateParts[0]), day: toInteger(dateParts[1]) };
      }
    }
    return <any>null;
  }

  parse(value: string): NgbDateStruct { return NgbDateENUSParserFormatter.parse(value); }

  format(date: NgbDateStruct): string {
    let stringDate: string = "";
    if (date) {
      stringDate += isNumber(date.month) ? padNumber(date.month) + "/" : "";
      stringDate += isNumber(date.day) ? padNumber(date.day) + "/" : "";
      stringDate += isNumber(date.year) ? padYear(date.year) : "";
    }
    return stringDate;
  }
}
