import { BaseRequest } from 'src/app/common/request/base-request';

export class SaveEnvelopeInput implements BaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public envelopeInformation: EnvelopeInformation
  ) { }
}

export class EnvelopeInformation {
  constructor(
    public envelopeId: number,
    public workItemId: number,
    public dateToDocket: string,
    public filerTypeCodeId: number
  ) { }
}


