import { ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function textPattern(pattern: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Empty string will be checked by IsRequired.
    if (!control.value) { return null; }

    const value: string = control.value.toString();
    
    return value.match(pattern) ? null : { 'pattern': { requiredPattern:pattern, actualValue:value } };
  };
}
