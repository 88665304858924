export class Code implements iCode {
  constructor(
    public description: string,
    public codeId?: number) { }
} 


export interface iCode {
  description: string;
  codeId?: number;
}
