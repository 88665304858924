import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { iParty } from 'src/app/common/model/envelope/party';
import { iPartyDetail } from 'src/app/common/model/envelope/party-detail';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Strings } from 'src/app/common/localization/strings';
import { MarkForTranslation } from 'src/app/common/localization/mark-for-translation';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { FormGroup } from '@angular/forms';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { PartyService } from 'src/app/common/service/party.service';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iMessage } from 'src/app/common/response/message';
import { PartyDetailInput } from 'src/app/common/request/party-detail-input';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

@Component({
  selector: 'app-verify-party',
  templateUrl: './verify-party.component.html',
  styleUrls: ['./verify-party.component.css']
})

export class VerifyPartyComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});

  title: string = MarkForTranslation.T("Verify Party");
  envelopeId: number;
  partiesToVerify: iParty[];
  selectedParty: iPartyDetail;
  regionCodes: iConfigCode[] | undefined;

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;


  constructor(public activeModal: NgbActiveModal, private envelopeService: EnvelopeService, private strings: Strings, private partyService: PartyService, 
    private toaster: CustomToastrService,private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.envelopeService.envelopeMetadata.subscribe(meta => {
      if (!meta) {
        return;
      }
      this.envelopeId = meta.envelopeId;

      this.subscriptions.push(this.partyService.parties.subscribe(parties => {
      if (!parties) {
        return;
      }
      this.partiesToVerify = parties.newParties.items.filter(p => p.isVerifyRequired);
      }));
    }));
  }

  selectParty(party: iParty) {
    this.getPartyDetail(party);
  }

  getPartyDetail(party: iParty) {
    var inputPartyDetail = new PartyDetailInput(null, null, null, 'getpartydetail', this.envelopeId, party.casePartyId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputPartyDetail));
  }
  
  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        if (message.action === "getpartydetail") {
          if (message.isSuccess === true) {
            this.selectedParty = message.taskresult as iPartyDetail;
          }
          else {
            this.toaster.pop('error', 'Error', 'An error occurred getting party detauk: ' + message.error.code + ': ' + message.error.cause);
          }
        }
      });
  }

  search() {
  }

  editFilerInfo() {
  }

  useFilerInfo() {
  }

  useCmsInfo() {
  }

  back() {
    if (!this.selectedParty) {
      return;
    }
    var index = this.partiesToVerify.findIndex(p => p.casePartyId == this.selectedParty.casePartyId);
    if (index > -1) {
      if (index == 0) {
        index = this.partiesToVerify.length - 1;
      }
      else {
        index--;
      }
      this.getPartyDetail(this.partiesToVerify[index]);
    }
  }

  next() {
    if (!this.selectedParty) {
      return;
    }
    var index = this.partiesToVerify.findIndex(p => p.casePartyId == this.selectedParty.casePartyId);
    if (index > -1) {
      if (index < this.partiesToVerify.length - 1) {
        index++;
      }
      else {
        index = 0;
      }
      this.getPartyDetail(this.partiesToVerify[index]);
    }
  }

  save() {
  }

  exit() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
