import { Injectable } from '@angular/core';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { FilingStateHelper } from 'src/app/common/enum/filing-state';
import { iEditStatus } from 'src/app/common/model/observable/edit-status';

@Injectable()
export class EditStateHandler {

  constructor(private toaster: CustomToastrService, private envelopeService: EnvelopeService, private webviewerService: WebviewerService,
    private stateHelper: FilingStateHelper) { }

  canContinueIncludingSave(editStatus: iEditStatus, selectedFiling: iFiling, isDocumentDisplayed: boolean, nextComponent: string) {
    if (selectedFiling) {
      //A single filing action is allowed. Send an error if a 2nd action is attempted
      if (this.stateHelper.isActionCompleted(selectedFiling.filingState)) {
        this.toaster.pop('error', 'Error', 'Filing action previously completed. "' + selectedFiling.filingCode.description + '" has been ' + this.stateHelper.friendlyName(selectedFiling.filingState));
        return false;
      }

      if (!isDocumentDisplayed) {
        this.toaster.pop('error', 'Error', 'Please wait for document to finish loading');
        return false;
      }
    }

    if (editStatus) {
      if (!editStatus.isValid || editStatus.isDirty) {
        var currentComponent = ''
        if (editStatus.currentComponent) {
          currentComponent = ComponentPage[editStatus.currentComponent]
        }
        if (!editStatus.isValid) {
          //todo this.strings.translateOnce('Cannot proceed until the error is fixed - {0}', [currentComponent]));
          this.toaster.pop('error', 'Error', currentComponent + ': Cannot proceed until the error is fixed.');
        }
        else if (editStatus.isDirty) {
          if (editStatus.currentComponent == ComponentPage.Case) {
            this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
            this.envelopeService.doSave.next({ currentComponent: ComponentPage.Case, nextComponent: nextComponent });
            return false;
          }
          else if (editStatus.currentComponent == ComponentPage.Envelope) {
            this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
            this.envelopeService.doSave.next({ currentComponent: ComponentPage.Envelope, nextComponent: nextComponent });
            return false;
          }
          else if (editStatus.currentComponent == ComponentPage.Payment) {
            this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
            this.envelopeService.doSave.next({ currentComponent: ComponentPage.Payment, nextComponent: nextComponent });
            return false;
          }
          else {
            this.toaster.pop('error', 'Error', currentComponent + ': Cannot proceed until until the section is saved or cancelled');
          }
        }
        return false;
      }
    }
    return true;
  }
}
