export class Constants {
  static readonly DATE_FMT = 'MM/dd/yyyy';
  static readonly TIME_FMT = 'h:mm a';
  static readonly DATE_TIME_FMT = Constants.DATE_FMT + ' ' + Constants.TIME_FMT;
  static readonly EmptyGuid = '00000000-0000-0000-0000-000000000000';

  static readonly AnyLocation = 'AnyLocation';

  //countries
  static readonly USA = 15;
  static readonly Mexico = -1481;
  static readonly Canada = 16;
  static readonly Australia = 7809;

  //zipCode regex
  static readonly regexUSA = '^\\d{5}(-\\d{4})?$';
  static readonly regexMex = '^\\d{5}$';
  static readonly regexCan = '^([A-Z]\\d[A-Z] \\d[A-Z]\\d)$';

  //DFC Defaults
  static readonly dateDefaultGhostText = 'MM/DD/YYYY';

  //responseCodes
  static readonly Success = 0;
  static readonly Fail = 1;
  static readonly UserNotFound = 10;
  static readonly NotFound = 11;
  static readonly LocationNotFound = 12;
  static readonly ServiceContactNotFound = 40;

  //Http status codes
  static readonly Unauthorized = 401;
  static readonly Forbidden = 403;
  static readonly ExpectationFailed = 417; //Indicates an efm timeout
  static readonly InternalServerError = 500;

  //CMS Error Codes
  static readonly PaymentAccountNotFound = "41";
  static readonly AttorneyNotFound = "42";
  static readonly AllowSubsequentFilingIntoNonIndexedCase = "136";

  //serviceType
  static readonly ServiceType_eServe = '-580';
  static readonly ServiceType_eMail = '-581';
  static readonly ServiceType_Mail = '-582';

  //Service Only
  static readonly ServiceOnlyFilingCode = -365;
  static readonly ServiceOnlyFilingComponentCode = -364;

  //Timing Constants
  static readonly ToasterTimeoutLong = '15000';     //longer timeout messages
  static readonly ToasterTimeoutLongest = '30000';  //longest timeout messages such as "the envelope number is" (after submitting) and exceptions
  static readonly ToasterTimeoutNone = '0';         //no timeout
  static readonly RedactionClientPollingInterval = 4000;

  //Party Threshhold
  static readonly PartyThreshold = 15;

  //messages
  static readonly FutureFeatureMessage: string = 'This feature is coming soon.';
}
