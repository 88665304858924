import { Pipe, PipeTransform } from '@angular/core';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { Strings } from 'src/app/common/localization/strings';
import { Constants } from 'src/app/common/constants';


@Pipe({
  name: 'codeDescription',
  pure: true
})
export class CodeDescriptionPipe implements PipeTransform {

  constructor(private strings: Strings) { }
  transform(value: number, codeList: iConfigCode[]): any {
    if (!value) {
      return undefined;
    }
    if (!codeList) {
      return undefined;
    }

    const found: iConfigCode | undefined = codeList.find(c => c.codeId === value);
    var description = '';
    if (found) {
      description = this.strings.translateOnce(found.description);
    }
    else {
      description = '';
    }
    return description;
  }
}
