import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { textPattern } from './text-regex-validator';
import { DataFieldBase } from '../data-field-base';
import { ViewChild, ElementRef } from '@angular/core';
import { Strings } from '../../../localization/strings';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})

export class TextFieldComponent extends DataFieldBase implements OnInit, OnChanges, OnDestroy {
  constructor(strings: Strings) { super(strings) }

  @ViewChild('input')
  private input!: ElementRef;

  @Input()
  public forceInputType: string | undefined;

  @Input()
  public precision = -1;
  public step: number | undefined;

  @Input()
  public forceRegex: string | undefined = undefined;

  @Input()
  public hideLabel: boolean = false;

  @Input()
  public showShortError: boolean = false;

  public focus() {
    this.input.nativeElement.focus();
  }

  ngOnInit() {
    super.ngOnInit();
    
    if (this.precision >= 0 && (!this.forceInputType || this.forceInputType === 'number')) {
      this.forceInputType = 'number';
      this.format();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes['precision']) {
      this.step = this.precision >= 0 ? Math.pow(10, -this.precision) : undefined;
    }
  }

  format() {    
    if (this.precision >= 0) {
      const float = parseFloat(this.value || '');

      this.setFormValue(isNaN(float) ? undefined : float.toFixed(this.precision));
    }
  }

  protected getPatternValidator(): ValidatorFn | undefined {
    if (this.forceRegex) {
      return textPattern(this.forceRegex);
    }
    if (this.config && this.config.regularExpression) {
      return textPattern(this.config.regularExpression);
    }
    return undefined;
  }
}
