import { Component, OnInit, Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs/index';
import { IResultsInfo } from './results-info';
import { Strings } from '../../localization/strings';

@Component({
  selector: 'app-search-results-summary',
  templateUrl: './search-results-summary.component.html',
  styleUrls: ['./search-results-summary.component.scss']
})
export class SearchResultsSummaryComponent implements OnInit {

  @Input() resultsInfo: IResultsInfo | undefined = undefined;
  @Input() numberOfResults: number = 0;
  @Input() isNewParty: boolean;
  @Input() isExistingParty: boolean;

  currentPage: number = 1;
  currentPageNew: number = 1;
  currentPageExisting: number = 1;
  maxPage: number = 1;

  currentPageLabelTemplate: BehaviorSubject<string> = this.strings.T("Page {0} of {1}");

  private subscriptions: Subscription[] = [];

  constructor(private strings: Strings) { }

  ngOnInit() {
    if (this.resultsInfo) {
      this.subscriptions.push(this.resultsInfo.maxPage.subscribe(val => this.maxPage = val));
      if (!this.isNewParty && !this.isExistingParty) {
        this.subscriptions.push(this.resultsInfo.numberOfResults.subscribe(val => this.numberOfResults = val));
        this.subscriptions.push(this.resultsInfo.currentPage.subscribe(val => this.currentPage = val));
      }
      else if (this.isNewParty) {
        this.subscriptions.push(this.resultsInfo.currentPage.subscribe(val => this.currentPageNew = val));
      }
      else if (this.isExistingParty) {
        this.subscriptions.push(this.resultsInfo.currentPage.subscribe(val => this.currentPageExisting = val));
      }
    }
  }

  currentPageLabel(localizedLabel: string, currentPage: number, maxPage: number) {
    return this.strings.format(localizedLabel, [currentPage.toString(), maxPage.toString()]);
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
