import { BaseRequest } from 'src/app/common/request/base-request';

export class SaveFeeInput implements BaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public envelopeId: number,
    public workitemId: number,
    public payeeId: number,
  ) { }
}


