import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CaseService } from 'src/app/common/service/case.service';
import { QueueService } from 'src/app/common/service/queue.service';
import { PartyService } from 'src/app/common/service/party.service';
import { iParty } from 'src/app/common/model/envelope/party';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartyFilter } from 'src/app/common/service/filter/party-filter';
import { iAttorney } from 'src/app/common/model/data/attorney';
import { iPartyTypeCode } from 'src/app/common/model/config/party-type-code';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { HtmlPropertyHelper } from 'src/app/common/helper/html-property-helper';
import { iCase } from 'src/app/common/model/envelope/case';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-party-information-list',
  templateUrl: './party-information-list.component.html',
  styleUrls: ['./party-information-list.component.css']
})
export class PartyInformationListComponent implements OnInit, OnDestroy {
  @Input() isNewParty: boolean;
  @Input() partyId: number;
  @Input() countries: iConfigCode[] | undefined;

  @Output() partyButtonEvent: EventEmitter<{ component: string, key: number, isNewParty: boolean }> = new EventEmitter<{ component: string, key: number, isNewParty: boolean }>();

  case: iCase;
  isFilterLoaded: boolean = false;
  numberOfResultsNew: number;
  numberOfResultsExisting: number;
  parties: iParty[];
  partiesAll: iParty[];
  partyIdLabel: string = '';

  partyTypes: iPartyTypeCode[] | undefined;
  suffixes: iConfigCode[] | undefined;
  attorneys: iAttorney[] | undefined;
  regions: iConfigCode[] | undefined;
  dlTypes: iConfigCode[] | undefined;
  genders: iConfigCode[] | undefined;
  languages: iConfigCode[] | undefined;

  partyFilter: PartyFilter = new PartyFilter();
  searchField: FormControl;

  get currentPageNew(): number {
    return this.partyService.currentPageNew.value;
  }
  set currentPageNew(pageNumber: number) {
    this.getSelectedPage(pageNumber);
  }
  get currentPageExisting(): number {
    return this.partyService.currentPageExisting.value;
  }
  set currentPageExisting(pageNumber: number) {
    this.getSelectedPage(pageNumber);
  }

  subscriptions: Subscription[] = [];

  constructor(private queueService: QueueService, private partyService: PartyService, private modalService: NgbModal, private htmlPropertyHelper: HtmlPropertyHelper, public element: ElementRef,
    private caseService: CaseService, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper, private toaster: CustomToastrService) { }

  ngOnInit() {
    if (this.isNewParty) {
      this.subscriptions.push(this.partyService.parties.subscribe(parties => {
        if (!parties) {
          this.parties = [];
          return;
        }
        if (parties.newParties && parties.newParties.totalItems) {
          parties.newParties.items.forEach(party => {
            if (!party.partyName) {
              party.isError = true;
            }
          });
        }
        this.parties = parties.newParties.items;
        this.numberOfResultsNew = parties.newParties.totalItems;
        this.htmlPropertyHelper.setPagerIds(this.element, 'New');
        this.partyIdLabel = 'New';
        this.initalizePager();
        setTimeout(() => {
          //all parties display instead of paged parties when there's multiple parties 
          this.isFilterLoaded = true;
        }, 100)
      }));
    }
    else {
      this.subscriptions.push(this.partyService.parties.subscribe(parties => {
        if (!parties) {
          this.parties = [];
          return;
        }
        if (parties.newParties && parties.newParties.totalItems) {
          parties.newParties.items.forEach(party => {
            if (!party.partyName) {
              party.isError = true;
            }
          });
        }
        this.parties = parties.existingParties.items;
        this.numberOfResultsExisting = parties.existingParties.totalItems;
        this.htmlPropertyHelper.setPagerIds(this.element, 'Existing');
        this.partyIdLabel = 'Existing';
        this.initalizePager();
      }));
    }
  }

  initalizePager() {
    if (this.parties && this.parties.length) {
      this.partiesAll = this.parties.concat();

      var startPage = 1;
      if (this.partyId && this.partyId > 0) {
        startPage = this.getPageWithEditedParty();
        this.partyFilter.pageNumber = startPage;
      }
      this.subscribeToFilter(startPage);
      this.subscribeToFilterResults();

      this.searchField = new FormControl('');
      this.searchField.valueChanges
        .pipe(
          debounceTime(350),
          distinctUntilChanged(),
          tap((text) => {
            this.filter();
          })
        )
        .subscribe(res => console.log(res));

      this.isFilterLoaded = true;
    }
  }

  subscribeToFilter(startPage: number) {
    this.subscriptions.push(this.partyService.partyFilter.subscribe(filter => {
      if (this.isNewParty) {
        this.partyFilter.uniqueFilterName = 'newParties';
      }
      this.partyService.getFilteredParties(this.partiesAll, this.partyFilter, startPage, this.isNewParty);
    }));
  }

  subscribeToFilterResults() {
    this.subscriptions.push(this.partyService.filterResult.subscribe(result => {
      if ((result.uniqueFilterName === 'newParties' && this.isNewParty) ||
        (result.uniqueFilterName !== 'newParties' && !this.isNewParty)) {
        if (result.items) {
          this.parties = result.items.concat();
        }
        else {
          this.parties = undefined;
        }
      }
    }));
  }

  //Returns all parties if filter is empty
  filter(pageNumber: number = 1) {
    this.partyService.getFilteredParties(this.partiesAll, this.partyFilter, pageNumber, this.isNewParty);
  }

  getSelectedPage(pageNumber: number) {
    this.filter(pageNumber);
  }

  getPageWithEditedParty() {
    if (!this.parties) {
      return;
    }
    var startPage = 1;
    var idx = this.parties.findIndex(cp => cp.casePartyId == this.partyId);

    if (idx < 0) {
      return startPage;
    }

    var tryAgain = true;
    if (idx > -1) {
      while (tryAgain) {
        if (idx + 1 < (startPage * this.partyFilter.perPage)) {
          tryAgain = false;
          return startPage;
        }
        else {
          startPage++;
        }
        if (startPage * 10 > (this.parties.length + 10)) {
          //can't find it. go to first page
          return 1;
        }
      }
    }
  }

  selectEditParty(party: iParty) {
    this.partyService.selectedParty.next(party.casePartyId);
    var component = "editParty";
    var key = party.casePartyId;
    //Event handled in party-information.component onPartyButtonEvent()
    this.partyButtonEvent.emit({ component, key, isNewParty: this.isNewParty });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
