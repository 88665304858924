import { BasePagingFilter } from './base-paging-filter';

export class AttorneyFilter extends BasePagingFilter {
  constructor(
    public searchFilter: string = '',
    perPage: number = 10,
    pageNumber: number = 1,
  ) {
    super(perPage, pageNumber);
  }
}
