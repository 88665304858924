import { BaseRequest } from 'src/app/common/request/base-request';
import { iPartyDetail } from 'src/app/common/model/envelope/party-detail';

export class SavePartyInput implements BaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public partyDetail: iPartyDetail
  ) { }
}



