import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { iParties } from 'src/app/common/model/envelope/parties';
import { FilterArray } from 'src/app/common/helper/filter-array';
import { PartyFilter } from 'src/app/common/service/filter/party-filter';
import { iParty } from 'src/app/common/model/envelope/party';
import { iPartyDetail } from 'src/app/common/model/envelope/party-detail';
import { PagedDataResults } from 'src/app/common/model/response/paged-model';


@Injectable({
  providedIn: 'root'
})
export class PartyService {
  public selectedParty: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  public selectedPartyDetail: BehaviorSubject<iPartyDetail | undefined> = new BehaviorSubject<iPartyDetail | undefined>(undefined);
  public parties: BehaviorSubject<iParties> = new BehaviorSubject<iParties | undefined>(undefined);

  public currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public currentPageNew: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public currentPageExisting: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public maxPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public numberOfResults: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public numberOfResultsNew: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public numberOfResultsExisting: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalParties: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public partyFilter: BehaviorSubject<PartyFilter> = new BehaviorSubject<PartyFilter>(new PartyFilter());
  public filterResult: BehaviorSubject<PagedDataResults<iParty>> = new BehaviorSubject<PagedDataResults<iParty>>(new PagedDataResults<iParty>());
  public filterResultNew: BehaviorSubject<PagedDataResults<iParty>> = new BehaviorSubject<PagedDataResults<iParty>>(new PagedDataResults<iParty>());
  public filterResultExisting: BehaviorSubject<PagedDataResults<iParty>> = new BehaviorSubject<PagedDataResults<iParty>>(new PagedDataResults<iParty>());

  public filter: FilterArray;
  public propertiesToFilterOn: string[] = ["partyName"]

  constructor(private filterArray: FilterArray) {
    this.filter = filterArray;
  }

  getFilteredParties(parties: iParty[], filter: PartyFilter, pageNumber: number = 1, isNewParty: boolean = false) {
    var result = this.filter.filterArray(parties, "casePartyId", this.propertiesToFilterOn, filter.nameFilter);
    var pagedData = new PagedDataResults<iParty>(result, result.length, '')
    let maxPage = filter.maxPage(parties.length);
    this.maxPage.next(maxPage);
    this.numberOfResults.next(parties.length);
    if (isNewParty) {
      this.currentPageNew.next(Math.min(filter.pageNumber, maxPage));
    }
    else {
      this.currentPageExisting.next(Math.min(filter.pageNumber, maxPage));
    }
    this.currentPage.next(Math.min(filter.pageNumber, maxPage));
    if (parties.length > filter.perPage) {
      this.getSelectedPage(pagedData, filter, pageNumber);
    }
    pagedData.uniqueFilterName = filter.uniqueFilterName;
    this.filterResult.next(pagedData);
  }

  getSelectedPage(pagedData: PagedDataResults<iParty>, filter: PartyFilter, pageNumber: number) {
    var sliceStart = pageNumber * filter.perPage - 10;
    var sliceEnd = sliceStart + filter.perPage < pagedData.items.length ? sliceStart + filter.perPage : pagedData.items.length;
    pagedData.items = pagedData.items.slice(sliceStart, sliceEnd)
  }

  //following filters used by filing.associatedParties and serviceContacts
  public casePartyFilter: BehaviorSubject<PartyFilter> = new BehaviorSubject<PartyFilter>(new PartyFilter());
  public filterCasePartyResult: BehaviorSubject<PagedDataResults<iParty>> = new BehaviorSubject<PagedDataResults<iParty>>(new PagedDataResults<iParty>());

  getFilteredCaseParties(parties: iParty[], filter: PartyFilter, pageNumber: number = 1) {
    var result = this.filter.filterArray(parties, "casePartyId", this.propertiesToFilterOn, filter.nameFilter);
    var pagedData = new PagedDataResults<iParty>(result, result.length, '')
    let maxPage = filter.maxPage(parties.length);
    this.maxPage.next(maxPage);
    this.currentPage.next(Math.min(filter.pageNumber, maxPage));
    if (parties.length > filter.perPage) {
      this.getCasePartySelectedPage(pagedData, filter, pageNumber);
    }
    this.filterCasePartyResult.next(pagedData);
  }

  getCasePartySelectedPage(pagedData: PagedDataResults<iParty>, filter: PartyFilter, pageNumber: number) {
    var sliceStart = pageNumber * filter.perPage - 10;
    var sliceEnd = sliceStart + filter.perPage < pagedData.items.length ? sliceStart + filter.perPage : pagedData.items.length;
    pagedData.items = pagedData.items.slice(sliceStart, sliceEnd)
  }
}

