import { Injectable, NgZone  } from '@angular/core';
import { Stamp } from 'src/app/common/model/data/stamp';
import { Subject } from 'rxjs';
import { AbsoluteUrlService } from 'src/app/common/service/absolute-url.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FileTree } from 'src/app/common/model/envelope/file-tree';

@Injectable({
  providedIn: 'root'
})
export class WebviewerService {
  public documentFileTree: BehaviorSubject<FileTree[]> = new BehaviorSubject<FileTree[] | undefined>(undefined);
  public selectedDocument: BehaviorSubject<FileTree> = new BehaviorSubject<FileTree | undefined>(undefined);
  //selectedDocumentFilingId: Actions apply to the filing of the currently selected document
  public selectedDocumentFilingId: BehaviorSubject<number> = new BehaviorSubject<number | undefined>(undefined);
  public webviewerReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean | undefined>(undefined);

  private stampSource = new Subject<Stamp | null>();
  stampSource$ = this.stampSource.asObservable();

  private annotationSource = new Subject<string>();
  annotationSource$ = this.annotationSource.asObservable();

  private viewerStatusSource = new Subject<string>();
  viewerStatusSource$ = this.viewerStatusSource.asObservable();


  constructor(private httpClient: HttpClient, private urlService: AbsoluteUrlService) {
  }

  sendViewerStatus(status: string): void {
    this.viewerStatusSource.next(status);
  }
   
  sendStamp(stamp: Stamp | null): void {
    this.stampSource.next(stamp);
  }

  sendAnnotation(annotation: string ): void {
    this.annotationSource.next(annotation);
  }
}
