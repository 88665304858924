import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { FormGroup } from '@angular/forms';
import { DfcService } from 'src/app/common/service/dfc.service';
import { Subscription } from 'rxjs';
import { Address } from 'src/app/common/model/envelope/address';
import { ConfigCode } from 'src/app/common/model/config/config-code';
import { getSelectedValueOrDefault } from 'src/app/common/component/data-fields/dropdown-field/dropdown-logic';
import { Constants } from 'src/app/common/constants';
import { PartyService } from 'src/app/common/service/party.service';
import { CaseService } from 'src/app/common/service/case.service';
import { MarkForTranslation } from '../../localization/mark-for-translation';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iMessage } from 'src/app/common/response/message';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { RegionInput } from 'src/app/common/request/region-input';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})

export class AddressComponent implements OnInit, OnDestroy {
  public form: FormGroup = new FormGroup({});
  @Input() parentForm!: FormGroup
  @Input() name: string | undefined;
  @Input() address!: Address;
  @Input() isReadOnly: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() showEditablePage: boolean = false;
  @Input() skipGetAddressViewModel: boolean = false;
  @Input() countries: ConfigCode[] | undefined;
  
  get anyFieldsPopulated(): boolean {
    return !!(
      this.address.streetAddressLine1 ||
      this.address.streetAddressLine3 ||
      this.address.city ||
      this.address.region.codeId ||
      this.address.zipCode
    );
  }

  locationId: number;
  region: ConfigCode | undefined;
  regions: ConfigCode[] | undefined;
  regionConfig: iDataFieldConfig | undefined;
  regionDescription: string | undefined;
  isGetRegionsComplete: boolean;

  country: ConfigCode | undefined;
  countryConfig: iDataFieldConfig | undefined;

  addressLine1Config: iDataFieldConfig | undefined;
  addressLine2Config: iDataFieldConfig | undefined;
  cityConfig: iDataFieldConfig | undefined;
  zipCodeConfig: iDataFieldConfig | undefined;

  stateLabel: string = 'State';
  zipCodeLabel: string | undefined;
  zipCodeRegex: string | undefined = Constants.regexUSA;

  isFirstTime: boolean = true;
  public isDirty: boolean;
  public isValid: boolean;
  stopDirtyCheck: boolean;

  forceAddressRequired: boolean = false;
  signalrSub: Subscription;

  subscriptions: Subscription[] = [];

  constructor(private dfcService: DfcService, private addressService: PartyService, private envelopeService: EnvelopeService, private caseService: CaseService,
    private toaster: CustomToastrService, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      if (!_case) {
        return;
      }
      this.locationId = _case.location.nodeId;
    }));

    if (this.parentForm) {
      this.parentForm.addControl('address', this.form);
    }

    if (this.address && !this.address.country.codeId) {
      this.address.country.codeId = Constants.USA;
    }

    this.subscriptions.push(this.dfcService.isDfcReady.subscribe(isReady => {
      if (isReady) {
        this.regionConfig = this.dfcService.getConfig('State', true);
        this.countryConfig = this.dfcService.getConfig('Country', true);
        this.addressLine1Config = this.dfcService.getConfig('AddressLine1', true);
        this.addressLine2Config = this.dfcService.getConfig('AddressLine2', true);
        this.zipCodeConfig = this.dfcService.getConfig('City', true);
        this.cityConfig = this.dfcService.getConfig('ZipCode', true);
      }
    }));

    this.updateCountryFromModel(); //countries was populated by envelope-view.component
    this.updateRegionAndZipLabels();
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case "getregioncodes": {
            if (message.isSuccess === true) {
              this.regions = message.taskresult.items as ConfigCode[];
              this.isGetRegionsComplete = true;
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting region codes: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
        }
      });
  }

	//Set labels based on country
	//  United States: State/Zip Code
	//  Mexico:        State/Postal Code   if no states, region textbox = State 
	//  Canada:        Province/Postal Code
	//  Default:       State/Postal Code
	//  If there are no states:
	//                 Region/Postal Code
  updateRegionAndZipLabels() {
    // Only mark the strings for translation here.  The DataField component assumes that it needs to
    // localize whatever text is provided for 'Label' and double-translating seems like it could introduce errors.
    switch (this.country.codeId) {
      case Constants.USA: {
        this.zipCodeLabel = MarkForTranslation.T('Zip Code');
        this.stateLabel = MarkForTranslation.T('State');
        this.zipCodeRegex = Constants.regexUSA;
        break;
      }
      case Constants.Canada: {
        this.zipCodeLabel = MarkForTranslation.T('Postal Code');
        this.stateLabel = MarkForTranslation.T('Province');
        this.zipCodeRegex = Constants.regexCan;
        break;
      }
      default: {
        this.zipCodeLabel = MarkForTranslation.T('Postal Code');
        this.stateLabel = MarkForTranslation.T('State');
        this.zipCodeRegex = Constants.regexMex;
        break;
      }
    }
  }

  updateCountryFromModel() {
    if (this.countries) {
      const found = getSelectedValueOrDefault(this.countries, this.country, this.address.country.codeId, (c) => c.codeId);
      this.country = found;
    }
  }

  countryChanged() {
    if (this.country) {
      if (this.address.country.codeId != this.country.codeId) {
        this.address.country.codeId = this.country.codeId;
        this.address.streetAddressLine3 = undefined;
        this.region = this.address.region.codeId = undefined;
      }
      this.getRegions();
      this.updateRegionAndZipLabels();
    }
    else {
      this.address.country.codeId = undefined;
    }
    //if (!this.isFirstTime) {
    //  /*
    //   * If address has been changed externally, the ngOnChange handler will
    //   * be responsible for loading the regions because it needs to do some
    //   * additional processing. It also needs this.address.region to remain
    //   * as it is.
    //   */
    //  //this.address.region;
    //  this.getRegions();
    //}
    //else {
    //  this.isFirstTime = false;
    //}
  }

  getRegions() {
    if (this.country) {
      const inputRegions = new RegionInput(null, null, null, 'getregioncodes', this.locationId, this.country.codeId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputRegions));
    }
    else {
      this.regions = undefined;
    }
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
