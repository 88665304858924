import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { DfcService } from 'src/app/common/service/dfc.service';
import { iEnvelope } from 'src/app/common/model/envelope/envelope';
import { Constants } from 'src/app/common/constants';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { FormGroup } from '@angular/forms';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { SaveEnvelopeInput, EnvelopeInformation } from 'src/app/common/request/save-envelope-input';
import { iMessage } from 'src/app/common/response/message';
import { Code } from 'src/app/common/model/envelope/code';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { NodeInput } from 'src/app/common/request/node-input';
import { Router } from '@angular/router';

@Component({
  selector: 'app-envelope-information',
  templateUrl: './envelope-information.component.html',
  styleUrls: ['./envelope-information.component.css']
})
export class EnvelopeInformationComponent implements OnInit, OnDestroy {
  public form: FormGroup = new FormGroup({});
  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();

  envelopeId: number;
  workItemId: number;
  envelope: iEnvelope;
  locationId: number;
  docketDateConfig: iDataFieldConfig | undefined;
  isSaving: boolean;
  completedSave: boolean;
  saveNextComponent: string;

  filerTypes: iConfigCode[] | undefined;
  filerTypeConfig: iDataFieldConfig | undefined;

  removeCalendarPlacement: string = '';

  stopDirtyCheck: boolean = false;
  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, public element: ElementRef, private dfcService: DfcService,
    private toaster: CustomToastrService, private router: Router, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.envelopeService.envelopeMetadata.subscribe(meta => {
      if (!meta) {
        return;
      }
      this.envelopeId = meta.envelopeId;
      this.workItemId = meta.workitemId;
    }));

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      if (!_case) {
        return;
      }
      this.locationId = _case.location.nodeId;

      const inputNode = new NodeInput(null, null, null, 'getfilertypecodes', this.locationId);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputNode));
    }));

    this.subscriptions.push(this.envelopeService.envelopeToReview.subscribe(envelope => {
      if (!envelope) {
        return;
      }
      this.envelope = envelope;
      //temp - waiting on api update
      if (!this.envelope.filerType) {
        this.envelope.filerType = new Code(undefined, undefined);
      }
      this.detectChanges();
    }))

    this.subscriptions.push(this.envelopeService.doSave.subscribe(save => {
      if (!save) {
        this.isSaving = false;
        return;
      }
      if (save.currentComponent == ComponentPage.Envelope) {
        this.saveNextComponent = save.nextComponent;
        this.isSaving = true;

        var envelopeInformation = new EnvelopeInformation(
          this.envelopeId,                     
          this.workItemId,
          this.envelope.dateToDocket,
          this.envelope.filerType.codeId
        );

        var saveEnvelopeInput = new SaveEnvelopeInput(null, null, null, 'saveenvelope', envelopeInformation);
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(saveEnvelopeInput));
      }
    }));

    this.subscriptions.push(this.dfcService.isDfcReady.subscribe(isReady => {
      if (isReady) {
        this.filerTypeConfig = this.dfcService.getConfig('FilingFilerType', false, false, false);
        this.docketDateConfig = new DataFieldConfigDefaults(true, false, true); //todo - replace isReadOnly with RightsFlags.AllowEditDocketDate
      }
    }));
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getfilertypecodes': {
            if (message.isSuccess === true) {
              this.filerTypes = message.taskresult.items as iConfigCode[];
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting filer types: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'saveenvelope': {
            if (message.isSuccess === true) {
              if (!message.taskresult.isLockValid) {
                this.toaster.pop('error', 'Error', 'You no longer own the lock on this envelope.', Constants.ToasterTimeoutLong);
                this.router.navigate(["/queue"]);
                return;
              }
              this.toaster.pop('success', 'Saved', 'Envelope Information changes have been saved');
              this.envelopeService.envelopeToReview.next(this.envelope);
              this.envelopeService.doSave.next(undefined);
              this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.Envelope, errorMessage: '' });
              this.envelopeService.showComponent.next({ component: this.saveNextComponent, isSuccess: true, message: undefined })
              }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred saving the envelope: ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutLong);
              this.envelopeService.doSave.next(undefined);
            }
            break;
          }
        }
      });
  }

  detectChanges() {
    this.subscriptions.push(this.form.statusChanges.subscribe(status => {
      if (this.stopDirtyCheck) {
        this.stopDirtyCheck = false;
        return;
      }
      if (status == "INVALID") {
        this.envelopeService.editStatus.next({ isValid: false, isDirty: this.form.dirty, currentComponent: ComponentPage.Envelope, errorMessage: '' });
      }
      else {
        this.envelopeService.editStatus.next({ isValid: true, isDirty: this.form.dirty && !this.completedSave, currentComponent: ComponentPage.Envelope, errorMessage: '' });
      }
    }));
  }

  changeEmail() {
  }

  getDateFormat() {
    return Constants.DATE_FMT;
  }

  previous() {
    var component = 'case';
    this.envButtonEvent.emit({ component, key: this.envelope.envelopeId });
  }

  next() {
    var component = 'party';
    this.envButtonEvent.emit({ component, key: this.envelope.envelopeId });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
