import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charges-information',
  templateUrl: './charges-information.component.html',
  styleUrls: ['./charges-information.component.css']
})
export class ChargesInformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
