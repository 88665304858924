import { Injectable } from '@angular/core';
import { QueueRequest } from '../model/queue/queue-request';
import { ConnectionService } from './connection.service';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  workItemId: number;
  envelopeId: number;

  filterEnvelopeId: number = null;
  filterCaseNumber: string = null;
  filterStartDate: string = null;
  filterEndDate: string = null;
  queueSearchComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean | undefined>(undefined);


  page: number = 0;
  pageSize: number = 25;

  private queueSource = new Subject<string | null>();
  queueSource$ = this.queueSource.asObservable();


  constructor(private connectionService: ConnectionService) { }


  getQueue(isGetNextEnvelope: boolean = false) {

    let qr = new QueueRequest();
    qr.tenant = "ofs-demo";
    qr.action = "getworkitems";
    qr.userId = "00000000-0000-0000-0000-000000000000";
    qr.pageIndex = this.page;
    qr.pageSize = this.pageSize;
    qr.queueIds = [];
    qr.nodeIds = [];
    qr.includeDeferred = false;
    qr.isGetNextEnvelope = isGetNextEnvelope;
    if (this.filterEnvelopeId !== null) {
      qr.envelopeId = Number(this.filterEnvelopeId);
      qr.pageIndex = this.page = 0;
    }
    if (this.filterCaseNumber !== null) {
      qr.caseNumber = this.filterCaseNumber;
      qr.pageIndex = this.page = 0;
    }

    if (this.filterStartDate !== null && this.filterEndDate !== null) {
      qr.fromDate = this.filterStartDate;
      qr.toDate = this.filterEndDate;
      qr.pageIndex = this.page = 0;
    }

    


    const jsonQueueRequest = JSON.stringify(qr);
    console.log(jsonQueueRequest);
    this.connectionService.sendApiMessage(jsonQueueRequest);
  }

  clearFilter() {
    this.queueSource.next("clear");
  }

}
