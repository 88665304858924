import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DropdownFieldComponent } from './dropdown-field.component';
import { iConfigCode } from '../../../model/config/config-code';
import { getSelectedValueOrDefault } from './dropdown-logic';

@Component({
  selector: 'app-code-dropdown',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss']
})
export class CodeDropdownComponent extends DropdownFieldComponent<iConfigCode> implements OnInit, OnChanges, OnDestroy {

  @Input()
  public code: number | undefined;
  @Input()
  public isDisabled: boolean = false;
  @Output()
  public codeChange = new EventEmitter<number>();

  @Input()
  public autoSelectSingle = false;

  ngOnInit() {
    if (!this.values && !this.value && this.code) {
      //Set up a dummy value to satisfy required validation and avoid an ExpressionChangedAfterItHasBeenCheckedError
      // from the ng-valid value changing from false to true after the values array is loaded.
      this.value = ({ codeId: this.code } as iConfigCode);
    }

    super.ngOnInit();

    this.subscriptions.push(this.valueChange.subscribe((val: iConfigCode | undefined) => {
      if (val) {
        this.code = val.codeId;
      } else {
        //This is done because the code might initially load as null, and in that case
        // setting it to undefined here will cause an ExpressionChangedAfterItHasBeenCheckedError
        this.code = this.code ? undefined : this.code;
      }

      this.codeChange.emit(this.code);
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes['code'] && !this.code) {
      this.valueChange.emit(this.value = undefined);
      this.setFormValue(this.value);
    }
    else if ((changes['code'] || changes['values']) && this.values) {
      this.valueChange.emit(this.value = getSelectedValueOrDefault(
        this.values, this.value, this.code, c => c.codeId,
      ));
      this.setFormValue(this.value);
    }
  }
}
