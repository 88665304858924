import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { iAttorney } from 'src/app/common/model/data/attorney';
import { AttorneyService } from 'src/app/common/service/attorney.service';
import { PartyAttorney } from 'src/app/common/model/envelope/party-attorney';
import { iPartyAttorney } from 'src/app/common/model/envelope/party-attorney';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { PartyService } from 'src/app/common/service/party.service';
import { iPartyDetail } from 'src/app/common/model/envelope/party-detail';
import { AttorneyFilter } from 'src/app/common/service/filter/attorney-filter';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { HtmlPropertyHelper } from 'src/app/common/helper/html-property-helper';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import * as lodash from 'lodash';

@Component({
  selector: 'app-add-attorney',
  templateUrl: './add-attorney.component.html',
  styleUrls: ['./add-attorney.component.css']
})
export class AddAttorneyComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  @Input() attorneys: iAttorney[];
  @Input() partyDetail: iPartyDetail;
  @Input() isPartyDirty: boolean;

  public isVisible: boolean;
  public isAttorneysDirty: boolean = false;

  searchField: FormControl;
  attorneysPage: iAttorney[];
  attorneysInitial: iAttorney[];

  attorneyFilter: AttorneyFilter = new AttorneyFilter();
  numberOfResults: number = 0;
  get currentPage(): number {
    return this.attorneyService.currentPage.value;
  }
  set currentPage(val: number) {
    this.filter(val);
  }

  private subscriptions: Subscription[] = [];
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @Output() editPartyEvent = new EventEmitter();
  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();

  constructor(private partyService: PartyService, private attorneyService: AttorneyService, private toaster: CustomToastrService, private htmlPropertyHelper: HtmlPropertyHelper, public element: ElementRef,
    private envelopeService: EnvelopeService) { }

  ngOnInit() {
  }

  initAttorneys() {
    this.partyDetail.attorneys.forEach(pa => {
      var idx = this.attorneys.findIndex(a => !pa.isDeleted && pa.attorneyId == a.attorneyId);
      if (idx > -1) {
        this.attorneys[idx].isSelected = true;
        this.attorneys[idx].isProSe = pa.isProSe;
        this.attorneys[idx].isLead = pa.isLead;
        this.attorneys[idx].isCreated = pa.isCreated;
      }
    });
    this.attorneysInitial = lodash.cloneDeep(this.attorneys);

    this.subscribeToFilter();
    this.subscribeToFilterResults();

    this.searchField = new FormControl('');
    this.searchField.valueChanges
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        tap((text) => {
          this.filter();
        })
      )
      .subscribe(res => console.log(res))

  }

  updateSelectedAttorney(attorney: PartyAttorney) {
    if (!attorney.isSelected) {
      if (attorney.attorneyId > -1) {
        attorney.isDeleted = true;
      }
    }
    else {
      attorney.isDeleted = false;
    }
    this.isAttorneysDirty = this.hasAttorneysChanged();
    if (!this.isPartyDirty) {
      this.envelopeService.editStatus.next({ isValid: true, isDirty: this.isAttorneysDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
    }
  }

  hasAttorneysChanged() {
    //Don't flag as dirty if reviewer changes attorneys back to orginal selection
    var isAttorneysDirty = false;
    this.attorneys.forEach(a => {
      var idx = this.partyDetail.attorneys.findIndex(cpa => cpa.attorneyId == a.attorneyId);
      if (idx > -1) {
        if (this.partyDetail.attorneys[idx].isSelected != a.isSelected ||
          this.partyDetail.attorneys[idx].isDeleted != a.isSelected) {
          isAttorneysDirty = true;
        }
      }
      else {
        isAttorneysDirty = true;
      }
    });
    return isAttorneysDirty;
  }

  subscribeToFilter() {
    this.subscriptions.push(this.attorneyService.attorneyFilter.subscribe(filter => {
      this.attorneyService.getFilteredAttorneys(this.attorneys, this.attorneyFilter);
    }));
  }

  subscribeToFilterResults() {
    this.subscriptions.push(this.attorneyService.filterResult.subscribe(result => {
      this.numberOfResults = result.totalItems;
      if (result.items) {
        this.attorneysPage = result.items.concat();
      }
      else {
        this.attorneysPage = undefined;
      }
      this.htmlPropertyHelper.setPagerIds(this.element, '');
      this.htmlPropertyHelper.setScrollbarId(this.element);
    }));
  }

  filter(pageNumber: number = 1) {
    this.attorneyService.getFilteredAttorneys(this.attorneys, this.attorneyFilter, pageNumber);
  }

  continue() {
    var setFirstAttorneyAsLead = false;
    if (this.partyDetail.attorneys && !this.partyDetail.attorneys.length) {
      setFirstAttorneyAsLead = true;
    }
    this.attorneys.forEach(fa => {
      var idx = this.partyDetail.attorneys.findIndex(cpa => cpa.attorneyId == fa.attorneyId);
      if (idx > -1) {
        this.partyDetail.attorneys[idx].isSelected = fa.isSelected;
        this.partyDetail.attorneys[idx].isDeleted = fa.isSelected ? false : true;
      }
      else {
        if (fa.isSelected) {
          var partyAttorney = new PartyAttorney(
            -1,                    //casePartyAttorneyId
            this.partyDetail.casePartyId,
            fa.attorneyId,            //attorneyId
            null,                     //firmId   Note: SavePartyDetail will need to determine FirmId from AttorneyId
            fa.attorneyName,
            fa.barNumber,
            false,                    //isProSe
            setFirstAttorneyAsLead,   //isLead
            false,                    //isCreated
            false,                    //isDeleted
            true                      //isSelected
          );
          setFirstAttorneyAsLead = false;
          this.partyDetail.attorneys.push(partyAttorney);
        }
      }
    })
    this.isVisible = false;
    this.editPartyEvent.emit();
  }

  cancel() {
    this.attorneys = lodash.cloneDeep(this.attorneysInitial);
    this.isAttorneysDirty = false;
    this.subscribeToFilter();
    if (!this.isPartyDirty) {
      this.envelopeService.editStatus.next({ isValid: true, isDirty: this.isAttorneysDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
    }
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
