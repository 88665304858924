import { Component, OnInit } from '@angular/core';
import { TextFieldComponent } from '../text-field/text-field.component';
import { ValidatorFn } from '@angular/forms';
import { emailPattern } from './email-regex-validator';

@Component({
  selector: 'app-email-field',
  templateUrl: '../text-field/text-field.component.html',
  styleUrls: ['../text-field/text-field.component.scss']
})
export class EmailFieldComponent extends TextFieldComponent implements OnInit {

  protected getPatternValidator(): ValidatorFn | undefined {
    return emailPattern();
  }

}
