import { Injectable } from '@angular/core';
import { iEnvelope } from 'src/app/common/model/envelope/envelope';
import { iEnvelopeMetaData } from 'src/app/common/model/envelope/envelope-metadata';
import { iEditStatus } from 'src/app/common/model/observable/edit-status';
import { iSaveComponent } from 'src/app/common/model/observable/save-component';
import { iShowComponentDetails } from 'src/app/common/model/observable/show-component-details';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvelopeService {
  public envelopeMetadata: BehaviorSubject<iEnvelopeMetaData | undefined> = new BehaviorSubject<iEnvelopeMetaData | undefined>(undefined);
  public envelopeToReview: BehaviorSubject<iEnvelope | undefined> = new BehaviorSubject<iEnvelope | undefined>(undefined);
  public isFailedLock: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  public isCriminalCase: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  public showOverlay: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);

  //Each envelope-view component has a detectChanges() method which emits an editStatus containing the isValid and isDirty state.
  //When a EnvelopeSidebarComponent or ActionsComponent button is clicked, the isValid/isDirty states are validated to determine if the button click can be honored.
  //If not:
  //  1. If not Case, Envelope, or Payment: an error toast instructs the user to fix their changes(!isValid) or to save their changes(isDirty).
  //  2. If Case, Envelope, or Payment:
  //     a. If !isValid, an error toast instructs the user to fix their changes.
  public editStatus: BehaviorSubject<iEditStatus | undefined> = new BehaviorSubject<iEditStatus | undefined>(undefined);
  //     b. If isDirty, a doSave message is sent to Case/Envelope/Payment. The appropriate component will save their updates
  public doSave: BehaviorSubject<iSaveComponent | undefined> = new BehaviorSubject<iSaveComponent | undefined>(undefined);
  //     c. After the http post is complete, the showComponent message emitted. EnvelopeSidebarComponent/ActionsComponent will send a save or error toast.
  //        If there's no error, the user selected component will display.
  public showComponent: BehaviorSubject<iShowComponentDetails | undefined> = new BehaviorSubject<iShowComponentDetails | undefined>(undefined);

  constructor() { }
}
