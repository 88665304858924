import { Component, OnInit, OnChanges, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { DataFieldBase } from '../data-field-base';
import { ValidatorFn } from '@angular/forms';
import { Strings } from '../../../localization/strings';

@Component({
  selector: 'app-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.scss']
})
export class CheckboxFieldComponent extends DataFieldBase implements OnInit, OnChanges, OnDestroy {

  @Input()
  public hideLabel: boolean = false;

  constructor(strings: Strings) { super(strings) }

  @ViewChild('input')
  private input!: ElementRef;

  public focus() {
    this.input.nativeElement.focus();
  }

  protected getPatternValidator(): ValidatorFn | undefined {
    return undefined;
  }
}
