export class PartyAttorney implements iPartyAttorney {
  constructor(
    public casePartyAttorneyId: number = -1,
    public casePartyId: number = -1,
    public attorneyId: number = -1,
    public firmId: number = -1,
    public attorneyName: string | undefined = undefined,
    public barNumber: string | undefined = undefined,
    public isProSe: boolean = false,
    public isLead: boolean = false,
    public isCreated: boolean = false,
    //viewModel
    public isDeleted: boolean = false,
    public isSelected: boolean = false,
  ) { }
}
export interface iPartyAttorney {
  casePartyAttorneyId: number;
  casePartyId: number;
  attorneyId: number;
  firmId: number;
  attorneyName: string | undefined;
  barNumber: string | undefined;  
  isProSe: boolean;
  isLead: boolean;
  isCreated: boolean;
  //viewModel
  isDeleted: boolean;
  isSelected: boolean;
}
