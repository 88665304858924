import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';


@Component({
  selector: 'app-stamp-button',
  templateUrl: './stamp-button.component.html',
  styleUrls: ['./stamp-button.component.css'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height: '0',
        overflow: 'hidden',
        
      })),
      state('final', style({
        height: '*',
        overflow: 'hidden',
        
      })),
      transition('initial=>final', animate('500ms')),
      transition('final=>initial', animate('500ms'))
    ]),
    trigger('buttonCollapse', [
      state('initial', style({
        'background-color': '#fff',
      })),
      state('final', style({
        'background-color': '#ccc',
      })),
      transition('initial=>final', animate('500ms')),
      transition('final=>initial', animate('500ms'))
    ]),
  ]
})
export class StampButtonComponent implements OnInit {

  //@ViewChild(NguiPopupComponent) popup: NguiPopupComponent;

  @Input() btnText: string;
  @Input() btnImage: string;
  @Output() stampTabEvent: EventEmitter<string> = new EventEmitter<string>();


  
  
  isCollapsed: boolean = true;


  constructor()
  {
  }

  ngOnInit() {
  }

  openPopup() {
    
   }





  onClickBtn() {
    
    this.stampTabEvent.emit("stamps");
    
  }

 
}
