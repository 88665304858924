import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-annotation-buttons',
  templateUrl: './annotation-buttons.component.html',
  styleUrls: ['./annotation-buttons.component.scss']
})
export class AnnotationButtonsComponent implements OnInit, OnDestroy {
  @Input() btnText: string;
  @Input() btnCommand: string;
  @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

  isActive: boolean;

  subscription: Subscription | undefined;

  constructor(private webviewerService: WebviewerService) { }

  ngOnInit() {
  }

  onClickBtn() {
    this.clickEvent.emit(this.btnCommand)
    this.isActive = true;
    if (this.btnCommand != 'Rotate') {
      this.webviewerService.sendAnnotation(this.btnCommand);
    }
  }

  setCssStyle() {
    switch (this.btnCommand) {
      case "AnnotationCreateFreeText":
      case "Rotate":
        if (this.isActive) {
          return 'tyler-icons tyler-icon svg-active';
        }
        else {
          return 'tyler-icons tyler-icon';
        }
      case "AnnotationCreateTextHighlight":
        if (this.isActive) {
          return 'fas fa-highlighter menu-icon-active';
        }
        else {
          return 'fas fa-highlighter menu-icon-inactive';
        }
      default:
        return '';
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
