import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { iPartyDetail } from 'src/app/common/model/envelope/party-detail';
import { iPartyAttorney } from 'src/app/common/model/envelope/party-attorney';
import { PartyService } from 'src/app/common/service/party.service';
import { FormGroup } from '@angular/forms';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import * as lodash from 'lodash';

@Component({
  selector: 'app-existing-attorneys',
  templateUrl: './existing-attorneys.component.html',
  styleUrls: ['./existing-attorneys.component.css']
})
export class ExistingAttorneysComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  @Input() partyDetail: iPartyDetail;
  @Input() isPartyDirty: boolean;

  public isVisible: boolean = false;
  public isLeadAttorneyMissing: boolean;
  public isAttorneysDirty: boolean = false;

  attorneysInitial: iPartyAttorney[];
  disableLeadAttorney: boolean;
  leadAttorneyInitial: number;
  subscriptions: Subscription[] = [];

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @Output() addAttorneyEvent = new EventEmitter();
  @Output() editPartyEvent = new EventEmitter();

  constructor(private partyService: PartyService, private envelopeService: EnvelopeService, private toaster: CustomToastrService) { }

  ngOnInit() {
  }

  loadPage() {
    this.validateLeadExists();

    this.attorneysInitial = lodash.cloneDeep(this.partyDetail.attorneys);
    var idx = this.partyDetail.attorneys.findIndex(a => a.isLead);
    if (idx > -1) {
      this.leadAttorneyInitial = this.partyDetail.attorneys[idx].casePartyAttorneyId;
      if (this.partyDetail.isCreated && this.partyDetail.attorneys[idx].casePartyAttorneyId != -1) {
        this.disableLeadAttorney = true;
      }
    }
  }

  updateSelectedAttorney(attorney: iPartyAttorney) {
    if (!attorney.isSelected) {
      attorney.isLead = false;
      if (attorney.attorneyId > -1) {
        attorney.isDeleted = true;
      }
    }
    else {
      attorney.isDeleted = false;
    }

    this.isAttorneysDirty = this.hasAttorneysChanged();
    this.validateLeadExists();
    if (!this.isPartyDirty) {
      this.envelopeService.editStatus.next({ isValid: true, isDirty: this.isAttorneysDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
    }
  }

  updateLead(attorney: iPartyAttorney) {
    //this.partyDetail.attorneys.forEach(a => a.isLead = false);
    var found = this.partyDetail.attorneys.filter(a => a.attorneyId == attorney.attorneyId);
    if (found) {
      found[0].isLead = true;
      found[0].isSelected = true;
      found[0].isDeleted = false;
      this.isLeadAttorneyMissing = false;
    }
    this.partyDetail.attorneys.forEach(a => {
      if (a.isLead && a.casePartyAttorneyId != attorney.casePartyAttorneyId) {
        a.isLead = false;
      }
    });
    this.isAttorneysDirty = this.hasAttorneysChanged();
    if (!this.isPartyDirty) {
      this.envelopeService.editStatus.next({ isValid: true, isDirty: this.isAttorneysDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
    }
  }

  hasAttorneysChanged() {
    //Don't flag as dirty if reviewer changes attorneys back to orginal selection
    var isAttorneysDirty = false;
    var leadAttorney = -1;
    this.partyDetail.attorneys.forEach(a => {
      if (a.isLead) {
        leadAttorney = a.casePartyAttorneyId;
      }
      if (a.casePartyAttorneyId == -1) {
        if (!a.isDeleted) {
          isAttorneysDirty = true;
        }
      }
      else {
        if (!a.isSelected) {
          isAttorneysDirty = true;
        }
      }
    });

    if (this.leadAttorneyInitial != leadAttorney) {
      isAttorneysDirty = true;
    }

    return isAttorneysDirty;
  }

  validateLeadExists() {
    var selectedAttorneys = this.partyDetail.attorneys.filter(a => a.isSelected);
    if (this.partyDetail.attorneys.length === 0 || !selectedAttorneys.length) {
      this.isLeadAttorneyMissing = false;
      return;
    }
    var found = this.partyDetail.attorneys.filter(a => a.isLead);
    if (!found || found.length == 0) {
      this.isLeadAttorneyMissing = true;
    }
    else {
      this.isLeadAttorneyMissing = false;
    }
  }

  addAttorney() {
    this.isVisible = false;
    this.addAttorneyEvent.emit();
  }

  continue() {
    this.isVisible = false;
    this.editPartyEvent.emit();
  }

  cancel() {
    this.partyDetail.attorneys = lodash.cloneDeep(this.attorneysInitial);
    this.isAttorneysDirty = false;
    if (!this.isPartyDirty) {
      this.envelopeService.editStatus.next({ isValid: true, isDirty: this.isAttorneysDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
    }
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
