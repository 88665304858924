import { Constants } from 'src/app/common/constants';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, _args?: any): any {
    var utcDateString: string = "" + value;

    // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
    if (utcDateString.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) {
      utcDateString += 'Z';
    }
    return super.transform(utcDateString, Constants.DATE_TIME_FMT);
  }
}
