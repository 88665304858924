import { Component } from '@angular/core';
import { ConnectionService } from './common/service/connection.service';
import { EnvelopeService } from './common/service/envelope.service';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'OFS Review App';
  showOverlay: boolean;
  envelopeId: number;

  signalrSub: Subscription;

  constructor(
    private connectionService: ConnectionService, private envelopeService: EnvelopeService, private router: Router
    )
  {

    this.envelopeService.showOverlay.subscribe(s => this.showOverlay = s)
    this.envelopeService.envelopeMetadata.subscribe(meta => {
      this.envelopeId = undefined;
      if (meta && this.router.url === '/viewer') {
        this.envelopeId = meta.envelopeId;
      }
    }); 

  }

}
