import { Component, OnInit } from '@angular/core';
import { TextFieldComponent } from '../text-field/text-field.component';
import { ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";
import { emailPatternRegex } from '../email-field/email-regex-validator';

@Component({
  selector: 'app-multi-email-field',
  templateUrl: '../text-field/text-field.component.html',
  styleUrls: ['../text-field/text-field.component.scss']
})
export class MultiEmailFieldComponent extends TextFieldComponent implements OnInit {

  protected getPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === undefined || !control.value) {
        return null;
      }
      var pattern = emailPatternRegex;
      let emailList: string[];
      if (control.value.indexOf(';') > -1) {
        emailList = control.value.split(';');
      }
      else if (control.value.indexOf(',') > -1) {
        emailList = control.value.split(',');
      }
      else {
        emailList = control.value.split(' ');
      }
      for (const email of emailList) {
        if (!email.trim()) { return null; }
        if (!email.trim().match(pattern)) {
          {
            return { 'email': true };
          }
        }
      };

      return null;
    };
  }
}
