import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { ActionBase } from 'src/app/webviewer/actions/action-base.component';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { QueueService } from 'src/app/common/service/queue.service';

@Component({
  selector: 'app-forward-action',
  templateUrl: './forward-action.component.html',
  styleUrls: ['./forward-action.component.css']
})
export class ForwardActionComponent extends ActionBase {
  public form: FormGroup = new FormGroup({});
  @ViewChild('forwardModal') forwardModal;

  constructor(private modalService: NgbModal, private config: NgbModalConfig, public queueService: QueueService, public webviewerService: WebviewerService, public toaster: CustomToastrService) {
    super(webviewerService, toaster)
    config.backdrop = false;
    config.keyboard = false;
    config.windowClass = 'custom-modal';
  }

  showModal() {
    this.modalReference = this.modalService.open(this.forwardModal);
    this.modalReference.result.then(save => {
      this.save();
    }, (exit) => {
      this.exit();
    });
  }

  save() {
    if (!this.isModalOpen()) {
      return;
    }

    this.toaster.pop('success', 'Saved', 'Envelope ' + this.queueService.envelopeId + ' is being Forwarded.');
    this.modalReference.close();
    this.modalReference = undefined;
    this.doActionsCompleteProcessing(this.filing);
  }
}
