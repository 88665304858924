export class PagedModel<T> implements iPagedModel<T> {
  constructor(
    public totalItems: number = 0,
    public items: T[] = [],
  ) { }
}

export interface iItems<T> {
  items: T;
}

export interface iPagedModel<T> extends iItems<T[]> {
  totalItems: number;
}

export interface IPagedDataResults<T> extends iPagedModel<T[]> {
}

export class PagedDataResults<T> implements iPagedModel<T> {
  constructor(
    public items: T[] = [],
    public totalItems: number = 0,
    public uniqueFilterName: string = ''
  ) { }
}
