import { Component, OnInit, Input, HostBinding } from '@angular/core';



@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
  
})
export class ActionButtonsComponent implements OnInit {

  constructor() { }

  state = "close";

  @Input() btnText: string;
  @Input() btnImage: string;
  @Input() btnId: string;
  @Input() activeAction: string;
  @Input() btnIcon: string;

  ngOnInit() {
  }
}
