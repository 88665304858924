import { iCode } from 'src/app/common/model/envelope/code';

export class FilingOptionalService implements iFilingOptionalService {
  constructor(
    public optionalServiceId: number = -1,
    public filingComponentCode: iCode,
    public multiplierCount: number,
    public multiplierValue: number,
    public amount: number,
    public isUpdated: boolean = false,
    public isDeleted: boolean = false,
 ) { }
}

export interface iFilingOptionalService {
  optionalServiceId: number;
  filingComponentCode: iCode;
  multiplierCount: number;
  multiplierValue: number;
  amount: number;

  //used for save
  isUpdated: boolean;
  isDeleted: boolean;
}

