import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { AnnotationButtonsComponent } from 'src/app/webviewer/annotation-buttons/annotation-buttons.component';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.css'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height: '0',
        overflow: 'hidden',
        opacity: '0'
      })),
      state('final', style({
        overflow: 'hidden',
        opacity: '1'
      })),
      transition('initial=>final', animate('250ms')),
      transition('final=>initial', animate('250ms'))
    ]),
  ]

})

export class AnnotationsComponent implements OnInit {
  @ViewChild('annotationLine', { static: true }) annotationLine: AnnotationButtonsComponent | undefined;
  @ViewChild('annotationText', { static: true }) annotationText: AnnotationButtonsComponent | undefined;
  @ViewChild('annotationHighlight', { static: true }) annotationHighlight: AnnotationButtonsComponent | undefined;
  @ViewChild('annotationRotate', { static: true }) annotationRotate: AnnotationButtonsComponent | undefined;

  isDrawLineActive: boolean;

  constructor(private toaster: CustomToastrService) { }

  @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

  isCollapsed = false;

  ngOnInit() {
  }

  onClickEvent(value: string): void {
    //if (!this.isAnnotationDocumentDisplayed) {
    //  this.toaster.pop('error', 'Error', 'Please wait for document to finish loading');
    //  return;
    //}

    this.annotationLine.isActive = false;
    this.annotationText.isActive = false;
    this.annotationHighlight.isActive = false;
    this.annotationRotate.isActive = false;
  }
}
