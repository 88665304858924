import { ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export var emailPatternRegex: string = "^([a-zA-Z0-9_\\-\\.\\+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{1,14})$"

export function emailPattern(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Empty string will be checked by IsRequired.
    if (!control.value) { return null; }

    const value: string = control.value.toString();

    return value.match(emailPatternRegex) ? null : { 'email': { requiredPattern: emailPatternRegex, actualValue:value } };
  };
}
