import { DropdownValue } from "./dropdown-value";

export function getSelectedValueOrDefault<T extends DropdownValue>(items: T[], currentValue: T | undefined, modelValue: number | undefined,
  keySelector: (x: T) => number, autoSelectSingleEntry: boolean = false ): T | undefined {
  if (items) {
    if (currentValue) {
      const found = items.find(i => currentValue == i || keySelector(currentValue) == keySelector(i));
      if (found) {
        return found;
      }
    }
    if (modelValue) {
      const found = items.find(i => modelValue == keySelector(i));
      if (found) {
        return found;
      }
    }
    if (items.length == 1 && autoSelectSingleEntry) {
      return items[0];
    }
    const def = items.find(i => i.isDefault);
    if (def) {
      return def;
    }
  }
  return undefined;
}
