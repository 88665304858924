import { Injectable } from "@angular/core";
import { NgbTimeAdapter, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string | undefined> {
  static readonly pattern = /(?:^(?:\d+\.)?|T)(\d\d):(\d\d):(\d\d)/;

  fromModel(value: string | undefined): NgbTimeStruct {
    if (!value) return null as any;

    const match = NgbTimeStringAdapter.pattern.exec(value);

    if (!match || match.length < 4) return null as any;

    return {
      hour: +match[1],
      minute: +match[2],
      second: +match[3]
    };
  }

  toModel(time: NgbTimeStruct): string | undefined {
    if (!time) return undefined;

    return `${this.format(time.hour)}:${this.format(time.minute)}:${this.format(time.second)}`;
  }

  private format(n: number) {
    return n < 10 ? `0${n}` : n;
  }
}
