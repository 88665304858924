import { iCode } from 'src/app/common/model/envelope/code';

export class Party implements iParty {
  constructor(
    public casePartyId: number = -1,
    public partyTypeCode: iCode,
    public partyName: string,
    public leadAttorney: string,
    public isVerifyRequired: boolean,

    // View Model
    public isSelected: boolean = true,
    public isDeleted: boolean = true,
    public isVisible: boolean = true,
    public isError: boolean = true,
  ) { }
}

export interface iParty {
  casePartyId: number;
  partyTypeCode: iCode,
  partyName: string;
  leadAttorney: string,
  isVerifyRequired: boolean,
  isSelected: boolean,
  isDeleted: boolean,
  isVisible: boolean,
  isError: boolean,
}
