import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { iCase } from 'src/app/common/model/envelope/case';
import { iParty } from 'src/app/common/model/envelope/party';
import { iPartyDetail, PartyDetail } from 'src/app/common/model/envelope/party-detail';
import { Address } from 'src/app/common/model/envelope/address';
import { PartyAttorney } from 'src/app/common/model/envelope/party-attorney';
import { iAttorney } from 'src/app/common/model/data/attorney';
import { DfcService } from 'src/app/common/service/dfc.service';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { iPartyTypeCode } from 'src/app/common/model/config/party-type-code';
import { iOrgChart } from 'src/app/common/model/config/org-chart';
import { getSelectedValueOrDefault } from 'src/app/common/component/data-fields/dropdown-field/dropdown-logic';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { FormGroup } from '@angular/forms';
import * as lodash from 'lodash';
import { PartyService } from 'src/app/common/service/party.service';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { AddressComponent } from 'src/app/common/component/address/address.component';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iMessage } from 'src/app/common/response/message';
import { PartyDetailInput } from 'src/app/common/request/party-detail-input';
import { EnvelopeInput } from 'src/app/common/request/envelope-input';
import { BaseRequest } from 'src/app/common/request/base-request';
import { Code } from 'src/app/common/model/envelope/code';
import { CaseService } from 'src/app/common/service/case.service';
import { SavePartyInput } from 'src/app/common/request/save-party-input';
import { Constants } from 'src/app/common/constants';
import { ExistingAttorneysComponent } from '../party-attorneys/existing-attorneys/existing-attorneys.component';
import { AddAttorneyComponent } from '../party-attorneys/add-attorney/add-attorney.component';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { QueueService } from 'src/app/common/service/queue.service';

@Component({
  selector: 'app-edit-party',
  templateUrl: './edit-party.component.html',
  styleUrls: ['./edit-party.component.css']
})
export class EditPartyComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  @Input() isNewParty: boolean;
  @Input() countries: iConfigCode[] | undefined;

  @ViewChild('existingAttorneysComponent', { static: false }) existingAttorneysComponent: ExistingAttorneysComponent | undefined;
  @ViewChild('addAttorneyComponent', { static: false }) addAttorneyComponent: AddAttorneyComponent | undefined;

  envelopeId: number;
  workitemId: number;
  caseData: iCase;
  partyDetail: iPartyDetail;

  partyTypes: iPartyTypeCode[] | undefined;
  suffixes: iConfigCode[] | undefined;
  attorneys: iAttorney[] | undefined;
  regions: iConfigCode[] | undefined;
  dlTypes: iConfigCode[] | undefined;
  genders: iConfigCode[] | undefined;
  languages: iConfigCode[] | undefined;
  location: iOrgChart | undefined;

  partyName: string;
  partyDetailBeforeEdits: iPartyDetail;
  parties: iParty[];
  additionalAttorneys: string | undefined;
  showEditablePage: boolean = true; //set by envelope-view

  partyType: iPartyTypeCode | undefined;
  partyTypeConfig: iDataFieldConfig | undefined;

  leadAttorney: iAttorney | undefined;
  hasLeadAttorneyBeenSelected: boolean = false;
  leadAttorneyConfig: iDataFieldConfig | undefined;
  leadAttorneyId: number | undefined;

  isAttorneysVisible: boolean;

  suffix: iConfigCode | undefined;
  suffixConfig: iDataFieldConfig | undefined;

  gender: iConfigCode | undefined;
  genderConfig: iDataFieldConfig | undefined;

  language: iConfigCode | undefined;
  languageConfig: iDataFieldConfig | undefined;

  //Drivers License
  dlTypeConfig: iDataFieldConfig | undefined;
  dlStateConfig: iDataFieldConfig | undefined;
  dlNumberConfig: iDataFieldConfig | undefined;

  businessNameConfig: iDataFieldConfig | undefined;
  nameFirstConfig: iDataFieldConfig | undefined;
  nameMiddleConfig: iDataFieldConfig | undefined;
  nameLastConfig: iDataFieldConfig | undefined;
  filerIdConfig: iDataFieldConfig | undefined;
  emailConfig: iDataFieldConfig | undefined;
  phoneConfig: iDataFieldConfig | undefined;
  ssnConfig: iDataFieldConfig | undefined;
  sidConfig: iDataFieldConfig | undefined;
  fbiConfig: iDataFieldConfig | undefined;
  dobConfig: iDataFieldConfig | undefined;
  phoneNumberConfig: iDataFieldConfig | undefined;

  removeCalendarPlacement: string = '';

  isNextSelected: boolean;
  isSaving: boolean;
  isPartyDirty: boolean;
  isAttorneysDirty: boolean = false;
  isValid: boolean = true;
  stopDirtyCheck: boolean;

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();
  @Output() envPartyPageLoadedEvent = new EventEmitter();

  @ViewChild(AddressComponent, { static: false }) editAddress?: AddressComponent;

  constructor(private envelopeService: EnvelopeService, private partyService: PartyService, private toaster: CustomToastrService, private caseService: CaseService,
    private dfcService: DfcService, public element: ElementRef, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper, private changeDetector: ChangeDetectorRef,
    private router: Router, private queueService: QueueService) { }

  ngOnInit() {
    this.getApiMessage();

    this.envelopeId = this.queueService.envelopeId;
    this.workitemId = this.queueService.workItemId;

    this.subscriptions.push(this.partyService.selectedParty.subscribe(partyId => {
      this.getPartyDetail(partyId);
    }));

    this.subscriptions.push(this.partyService.parties.subscribe(parties => {
      if (!parties) {
        return;
      }
      if (this.isNewParty) {
        this.parties = parties.newParties.items;
      }
      else {
        this.parties = parties.existingParties.items;
      }
    }));

    this.subscriptions.push(this.dfcService.isDfcReady.subscribe(isReady => {
      if (isReady) {
        this.initializeDataFieldConfigs();
      }
    }));

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      if (!_case) {
        return;
      }
      this.caseData = _case;

      this.subscriptions.push(this.caseService.locations.subscribe(_locations => {
        if (_locations) {
          var found = _locations.filter(l => l.nodeId = this.caseData.location.nodeId);
          if (found) {
            this.location = found[0];
          }
        }
      }));
    }));
  }

  getPartyDetail(partyId: number) {
    this.partyDetail = undefined;
    var inputPartyDetail = new PartyDetailInput(null, null, null, 'getpartydetail', this.envelopeId, partyId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputPartyDetail));

    const inputAttorney = new EnvelopeInput(null, null, null, 'getfirmattorneys', this.envelopeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputAttorney));
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getpartydetail': {
            if (message.isSuccess === true) {
              this.partyDetail = message.taskresult as iPartyDetail;
              if (!this.partyDetail.address || !this.partyDetail.address.addressId) {
                this.partyDetail.address = new Address(-1, undefined, undefined, undefined, undefined, undefined, new Code(undefined, undefined), undefined, new Code(undefined, 15), undefined);
              }
              else {
                var state = this.partyDetail.address.streetAddressLine3 ? this.partyDetail.address.streetAddressLine3 : this.partyDetail.address.region.description;
                this.partyDetail.address.cityStateZip = this.partyDetail.address.city + ', ' + state + ' ' + this.partyDetail.address.zipCode;
              }

              this.hasLeadAttorneyBeenSelected = false;
              this.partyService.selectedPartyDetail.next(this.partyDetail);
              this.extendPartyDetail();
              this.setSelectedAttorneys();

              this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
              if (this.form.dirty) {
                //this.toaster.pop('success', this.strings.translateOnce('Saved'), this.strings.translateOnce('Your changes have been saved'));
                this.toaster.pop('success', 'Saved', 'Party changes have been saved');
                this.stopDirtyCheck = true;
                this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
              }
              this.envPartyPageLoadedEvent.emit();
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting party detail: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getfirmattorneys': {
            if (message.isSuccess === true) {
              this.attorneys = message.taskresult.items as iAttorney[];
              this.setSelectedAttorneys();
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting firm attorneys: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'saveparty': {
            if (message.isSuccess === true) {
              if (!message.taskresult.isLockValid) {
                this.toaster.pop('error', 'Error', 'You no longer own the lock on this envelope.', Constants.ToasterTimeoutLong);
                this.router.navigate(["/queue"]);
                return;
              }
              this.toaster.pop('success', 'Saved', 'Party changes have been saved');
              this.isSaving = false;
              if (this.isNextSelected) {
                this.showNextParty()
              }
              else {
                this.stopDirtyCheck = true;
                this.editAddress.stopDirtyCheck = true;
                this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
                var component = 'party';
                var key = this.partyDetail.casePartyId;
                this.envButtonEvent.emit({ component, key });
              }
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred saving the party: ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutLong);
            }
            break;
          }
        }
      });
  }

  extendPartyDetail() {
    this.partyService.selectedPartyDetail.next(this.partyDetail);
    this.stopDirtyCheck = false;
    this.partyDetailBeforeEdits = lodash.cloneDeep(this.partyDetail);
    this.partyName = this.partyDetail.nameFirst + (!this.partyDetail.nameMiddle ? "" : " " + this.partyDetail.nameMiddle) + " " + this.partyDetail.nameLast;

    this.detectChanges();
  }

  setSelectedAttorneys() {
    if (this.partyDetail && this.attorneys) {
      if (this.partyDetail.attorneys) {
        this.partyDetail.attorneys.forEach(cpa => {
          if (!cpa.isDeleted) {
            cpa.isSelected = true
          }
        })
      };
      this.formatAdditionalAttorneys();
      this.updateLeadAttorneyFromModel();
    }
  }

  detectChanges() {
    this.form.valueChanges.subscribe(change => {
      if (this.stopDirtyCheck) {
        return;
      }
      this.isPartyDirty = this.form.dirty || this.isAttorneysDirty;
      this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isPartyDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
    });

    this.subscriptions.push(this.form.statusChanges.subscribe(status => {
      if (this.stopDirtyCheck) {
        return;
      }
      this.isPartyDirty = this.form.dirty || this.isAttorneysDirty;
      if (status == "INVALID") {
        this.isValid = false;
        this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isPartyDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
      }
      else {
        this.isValid = true;
        this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isPartyDirty, currentComponent: ComponentPage.Party, errorMessage: '' });
      }
    }));
  }

  onIsBusinessChanged(isChecked: boolean) {
    if (!isChecked) {
      this.partyDetail.isPerson = true;
      this.partyDetail.businessName = undefined;
    }
    else {
      this.partyDetail.isPerson = false;
      this.partyDetail.nameFirst = undefined;
      this.partyDetail.nameMiddle = undefined;
      this.partyDetail.nameLast = undefined;
      this.partyDetail.nameSuffix = undefined;
      this.partyDetail.suffix.description = this.partyDetail.suffix.codeId = null;
    }
  }

  onPartyNameChange() {
    if (!this.partyDetail) {
      return;
    }
    if (this.partyDetail.isPerson) {
      this.partyDetail.partyName =
        (this.partyDetail.nameFirst != undefined ? this.partyDetail.nameFirst : "") +
        (this.partyDetail.nameMiddle != undefined ? " " + this.partyDetail.nameMiddle : "") + " " +
        (this.partyDetail.nameLast != undefined ? this.partyDetail.nameLast : "");
      //todo - add when theres suffixes
      //(this.partyDetail != undefined ? " " + this.suffix.description : "");
    }
    else {
      this.partyDetail.partyName = this.partyDetail.businessName != undefined ? this.partyDetail.businessName : "";
    }
  }

  updateLeadAttorneyFromModel() {
    this.setLeadAttorneyId();
    const found = getSelectedValueOrDefault(this.attorneys, this.leadAttorney, this.leadAttorneyId, (a) => a.attorneyId);
    this.leadAttorney = found;
  }
  
  leadAttorneyChanged() {
    if (this.leadAttorney) {
      this.hasLeadAttorneyBeenSelected = true;
      if (this.partyDetail && this.partyDetail.attorneys) {
        if (this.partyDetail.attorneys.length) {
          this.partyDetail.attorneys.forEach(a => {
            a.isLead = false;
          });
          var existingAttorney = this.partyDetail.attorneys.filter(a => a.attorneyId == this.leadAttorney.attorneyId && !a.isDeleted);
          if (existingAttorney && existingAttorney.length) {
            existingAttorney[0].isLead = true;
          }
          else {
            this.addLeadAttorney();
          }
        }
        else {
          this.addLeadAttorney();
        }
      }
    }
    else {
      if (this.hasLeadAttorneyBeenSelected) {
        this.partyDetail.attorneys.forEach(a => a.isLead = false);
      }
    }
    this.formatAdditionalAttorneys();
  }

  addLeadAttorney() {
    var idx = 0;
    if (this.partyDetail.attorneys && this.partyDetail.attorneys.length) {
      var idx = this.partyDetail.attorneys.findIndex((a) => a.attorneyId == this.leadAttorney.attorneyId);
    }
    if (idx == -1) {
      var partyAttorney = new PartyAttorney(
        -1,                        //casePartyAttorneyId
        this.partyDetail.casePartyId, //casePartyId
        this.leadAttorney.attorneyId, //attorneyId
        null,                         //firmId   Note: efm-api updates this value
        this.leadAttorney.attorneyName,
        this.leadAttorney.barNumber,
        false,                       //isProSe
        true,                        //isLead
        false,                      //isCreated
        false,                      //isDeleted
        true                       //isSelected
      );
      this.partyDetail.attorneys.push(partyAttorney);
    }
  }

  setLeadAttorneyId() {
    if (this.partyDetail && this.partyDetail.attorneys && this.partyDetail.attorneys.length) {
      var leadAttorney = this.partyDetail.attorneys.filter(a => a.isLead && !a.isDeleted);
      if (leadAttorney && leadAttorney.length) {
        this.leadAttorneyId = leadAttorney[0].attorneyId;
        var idx = this.attorneys.findIndex(a => a.attorneyId == this.leadAttorneyId);
        if (idx > -1) {
          this.leadAttorney = this.attorneys[idx];
        }
      }
      else {
        this.leadAttorneyId = this.leadAttorney = undefined;
      }
    }
    else {
      this.leadAttorneyId = undefined;
    }
  }

  formatAdditionalAttorneys() {
    this.additionalAttorneys = '';
    if (this.partyDetail && this.partyDetail.attorneys) {
      this.partyDetail.attorneys.filter(cpa => !cpa.isDeleted && !cpa.isLead && cpa.isSelected).forEach(cpa => {
        if (this.additionalAttorneys.length) {
          this.additionalAttorneys += ', ';
        }
        this.additionalAttorneys += cpa.attorneyName;
      })
      if (this.additionalAttorneys.length) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  viewAttorney() {
    this.isAttorneysVisible = true;
    this.existingAttorneysComponent.loadPage();
    this.existingAttorneysComponent.isVisible = true;;
  }

  //Returning from existing-attorneys or add-attorneys
  onEditPartyEvent(cancelParty: boolean): void {
    if (cancelParty) {
      this.close();
    }
    this.isAttorneysVisible = false;
    this.updateLeadAttorneyFromModel();
    this.formatAdditionalAttorneys();
    if (!this.isAttorneysDirty) {
      if (this.existingAttorneysComponent) {
        this.isAttorneysDirty = this.existingAttorneysComponent.isAttorneysDirty;
      }
      else {
        this.isAttorneysDirty = this.addAttorneyComponent.isAttorneysDirty;
      }
      if (this.isAttorneysDirty) {
        this.changeDetector.markForCheck();
      }
    }
  }

  onAddAttorneyEvent($event: { component: string, key: number }): void {
    if (!this.isAttorneysDirty) {
      this.isAttorneysDirty = this.existingAttorneysComponent.isAttorneysDirty;
    }
    this.existingAttorneysComponent.isVisible = false;
    this.existingAttorneysComponent = undefined;
    this.addAttorneyComponent.partyDetail = this.partyDetail; //push updates from existing-attorneys component
    this.addAttorneyComponent.initAttorneys();
    this.addAttorneyComponent.isVisible = true;
  }

  initializeDataFieldConfigs() {
    this.partyTypeConfig = this.dfcService.getConfig('PartyPartyType', true, false, true);
    this.leadAttorneyConfig = this.dfcService.getConfig('PartyAttorney', true, false, false);
    this.businessNameConfig = this.dfcService.getConfig('PartyBusinessName', true, false, true);
    this.nameFirstConfig = this.dfcService.getConfig('PartyFirstName', true, false, true);
    this.nameMiddleConfig = this.dfcService.getConfig('PartyMiddleName', true, false, false);
    this.nameLastConfig = this.dfcService.getConfig('PartyLastName', true, false, true);
    this.suffixConfig = this.dfcService.getConfig('PartySuffix', true, false, false);
    this.filerIdConfig = this.dfcService.getConfig('PartyFilerID', true, false, false, '^[0-9]*$', 'Filer ID must be a number');
    this.emailConfig = this.dfcService.getConfig('PartyEmail', false);
    this.phoneConfig = this.dfcService.getConfig('PartyPhone', true, false, true);
    this.ssnConfig = this.dfcService.getConfig('PartySSN', false);
    this.dobConfig = this.dfcService.getConfig('PartyDateOfBirth', false);
    this.genderConfig = this.dfcService.getConfig('PartyGender', false);
    this.languageConfig = this.dfcService.getConfig('PartyInterpreter', false);
    this.dlTypeConfig = this.dfcService.getConfig('PartyDLType', false);
    this.dlStateConfig = this.dfcService.getConfig('PartyDLState', false);
    this.dlNumberConfig = this.dfcService.getConfig('PartyDLNumber', false);
    this.phoneNumberConfig = this.dfcService.getConfig('PartyPhone', true, false, true);
  }

  close() {
    this.stopDirtyCheck = true;
    this.envelopeService.editStatus.next({ isValid: true, isDirty: false, currentComponent: ComponentPage.None, errorMessage: '' });
    var component = 'party';
    var key = this.partyDetail.casePartyId;
    this.envButtonEvent.emit({ component, key });
  }

  next() {
    if (this.form.dirty) {
      this.isNextSelected = true;
      this.save()
    }
    else {
      this.showNextParty();
    }
  }

  showNextParty() {
    this.isNextSelected = false;
    setTimeout(() => {
      this.form.reset();

      var idx = this.parties.findIndex(cp => cp.casePartyId == this.partyDetail.casePartyId);
      if (idx < this.parties.length - 1) {
        idx++;
      }
      else {
        idx = 0;
      }

      this.getPartyDetail(this.parties[idx].casePartyId);
    }, 1)
  }

  save() {
    this.isSaving = true;
    this.partyDetail.envelopeId = this.envelopeId;
    this.partyDetail.workItemId = this.workitemId;
    var savePartyInput = new SavePartyInput(null, null, null, 'saveparty', this.partyDetail);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(savePartyInput));
  }

  get dlStates(): iConfigCode[]  {
    if (this.editAddress)
      return this.editAddress.regions;
    else return undefined;
  }

  get isAddressValid(): boolean {
    if (this.editAddress)
      return this.editAddress.form.valid;
    else return true;
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }
}
