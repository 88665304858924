import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string | undefined> {
  static readonly pattern = /^(\d{4})-(\d\d)-(\d\d)(?:T|$)/;

  fromModel(value: string | undefined): NgbDateStruct { return NgbDateStringAdapter.fromModel(value); }
  toModel(date: NgbDateStruct): string | undefined { return NgbDateStringAdapter.toModel(date); }

  static fromModel(value: string | undefined): NgbDateStruct {
    if (!value) return null as any;

    const match = NgbDateStringAdapter.pattern.exec(value);

    if (!match || match.length < 4) return null as any;

    return {
      year: +match[1],
      month: +match[2],
      day: +match[3]
    };
  }

  static toModel(date: NgbDateStruct): string | undefined {
    if (!date) return undefined;

    return `${date.year}-${this.format(date.month)}-${this.format(date.day)}`;
  }

  private static format(n: number) {
    return n < 10 ? `0${n}` : n;
  }
}
