import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { CaseService } from 'src/app/common/service/case.service';
import { FilingService } from 'src/app/common/service/filing.service';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { FilingFilter } from 'src/app/common/service/filter/filing-filter';
import { HtmlPropertyHelper } from 'src/app/common/helper/html-property-helper';

@Component({
  selector: 'app-file-information',
  templateUrl: './file-information.component.html',
  styleUrls: ['./file-information.component.css']
})
export class FileInformationComponent implements OnInit, OnDestroy {
  filingId: number;
  envelopeId: number;

  filings: iFiling[]; //paged filings
  filingInfoList: iFiling[];
  filingFilter: FilingFilter = new FilingFilter();
  isShowAll: boolean = false;

  numberOfResults: number = 0;

  get currentPage(): number {
    return this.filingService.currentPage.value;
  }
  set currentPage(val: number) {
    this.filter(val);
  }

  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();
  private subscriptions: Subscription[] = [];

  constructor(private filingService: FilingService, private caseService: CaseService, private htmlPropertyHelper: HtmlPropertyHelper, public element: ElementRef) { }

  ngOnInit() {
    this.subscriptions.push(this.filingService.filings.subscribe(result => {
      if (!result) {
        this.filings = [];
        return;
      }
      this.filings = result.items;
      this.filingInfoList = this.filings.concat();
      var startPage = 1;
      if (this.filings.length) {
        this.filingId = this.filings[0].filingId;
        startPage = this.getPageWithEditedFiling();
        this.filingFilter.pageNumber = startPage;
      }

      this.subscribeToFilter(startPage);
      this.subscribeToFilterResults();

      this.subscriptions.push(this.filingService.filingsWithErrors.subscribe(filingIds => {
        if (filingIds) {
          filingIds.forEach(id => {
            var filing = this.filings.filter(f => f.filingId == id);
            if (filing && filing.length) {
              filing[0].isError = true;
            }
          });
        }
      }));
    }));
    this.subscriptions.push(this.filingService.selectedFilingId.subscribe(id => {
      this.filingId = id;
    }));
  }

  subscribeToFilter(startPage: number) {
    this.subscriptions.push(this.filingService.filingFilter.subscribe(filter => {
      this.filingService.getFilteredFilings(this.filingInfoList, this.filingFilter, startPage);
    }));
  }

  subscribeToFilterResults() {
    this.subscriptions.push(this.filingService.filterResult.subscribe(result => {
      this.numberOfResults = result.totalItems;
      if (result.items) {
        this.filings = result.items.concat();
      }
      else {
        this.filings = undefined;
      }
      this.htmlPropertyHelper.setPagerIds(this.element, '');
      this.htmlPropertyHelper.setScrollbarId(this.element);
   }));
  }

  //Returns all filings if filter is empty
  filter(pageNumber: number = 1) {
    this.filingService.getFilteredFilings(this.filingInfoList, this.filingFilter, pageNumber);
  }

  getPageWithEditedFiling() {
    if (!this.filingInfoList) {
      return;
    }
    var startPage = 1;
    var idx = this.filingInfoList.findIndex(f => f.filingId == this.filingId);

    if (idx < 0) {
      return startPage;
    }

    var tryAgain = true;
    if (idx > -1) {
      while (tryAgain) {
        if (idx + 1 < (startPage * this.filingFilter.perPage)) {
          tryAgain = false;
          return startPage;
        }
        else {
          startPage++;
        }
        if (startPage * 10 > this.filingInfoList.length + 10) {
          //can't find it. go to first page
          return 1;
        }
      }
    }
  }

  showAll() {
    this.isShowAll = !this.isShowAll;
    if (this.isShowAll) {
      this.filings = this.filingInfoList.concat();
    }
    else {
      this.filter(1)
    }
  }

  selectFiling(filing: iFiling) {
    this.filingService.selectedFilingId.next(filing.filingId);
    var component = 'editFile';
    var key = filing.filingId;
    this.envButtonEvent.emit({ component, key });
  }

  ngAfterViewInit() {
  }

  previous() {
    var component = 'party';
    this.envButtonEvent.emit({ component, key: 0 });
  }

  next() {
    var component = 'serve';
    this.envButtonEvent.emit({ component, key: 0 });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
