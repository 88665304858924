import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-data-field-message',
  templateUrl: './data-field-message.component.html',
  styleUrls: ['./data-field-message.component.scss']
})
export class DataFieldMessageComponent implements OnInit {

  constructor() { }

  @Input() control!: FormControl;

  @Input() errorKey!: string | string[];
  get keys(): string[] { return typeof this.errorKey === 'string' ? [this.errorKey] : this.errorKey; }

  private hide = false;

  ngOnInit(): void {
  }

  get showMessage(): boolean {
    if (this.hide || !this.control.errors) return false;

    //drill down through the errors using the keys array
    //if an error doesn't exist for a key, return false
    let error = this.control.errors;
    for (let key of this.keys) {
      error = error[key];

      if (!error) return false;
    }

    //if the loop completes, the error specified by the key(s) was found, so return true
    return true;
  }
}
