import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { iCase } from 'src/app/common/model/envelope/case';
import { iEditStatus } from 'src/app/common/model/observable/edit-status';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { ActionType } from 'src/app/common/enum/action-type';
import { FilingService } from 'src/app/common/service/filing.service';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { AcceptActionComponent } from './accept-action/accept-action.component';
import { ReturnActionComponent } from './return-action/return-action.component';
import { FailActionComponent } from './fail-action/fail-action.component';
import { ForwardActionComponent } from './forward-action/forward-action.component';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { EditStateHandler } from 'src/app/common/helper/edit-state-handler';
import { ComponentPage, ComponentPageHelper } from 'src/app/common/model/observable/edit-status';
import { iFilingCode } from 'src/app/common/model/config/filing-code';
import { Constants } from 'src/app/common/constants';
import { iSaveFilingAction } from 'src/app/common/response/save-filing-action';
import { Router } from '@angular/router';
import { QueueService } from 'src/app/common/service/queue.service';
import { QueueItem } from 'src/app/common/model/queue/queue-item';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iMessage } from 'src/app/common/response/message';
import { SaveActionsInput, FilingActionList, Action } from 'src/app/common/request/save-actions-input';
import { UnlockEnvelopeInput } from 'src/app/common/request/unlock-envelope-input';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { NodeInput } from 'src/app/common/request/node-input';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height: '0',
        overflow: 'hidden',
        opacity: '0'
      })),
      state('final', style({
        overflow: 'hidden',
        opacity: '1'
      })),
      transition('initial=>final', animate('250ms')),
      transition('final=>initial', animate('250ms'))
    ]),
  ]
})
export class ActionsComponent implements OnInit, OnDestroy  {
  editStatus: iEditStatus = { isValid: true, isDirty: false, currentComponent: 0, errorMessage: '' };
  currentAction: string;
  isReturnActionVisible = false;
  isDocumentDisplayed = false;
  filingList: iFiling[];
  isActionInvalid: boolean = false;
  activeAction: string;
  isSaving: boolean;
  savingComponent: ComponentPage;

  caseData: iCase;
  filings: iFiling[];
  filing: iFiling;
  filingDescription: string;
  filingCode: iFilingCode | undefined;
  fileTrees: FileTree[] = [];
  fileTree: FileTree;
  queueList: QueueItem[] = [];

  rejectComments: string = '';
  rejectReasonCodeId: number;
  rejectReasons: iConfigCode[];

  queueCodes: iConfigCode[];

  actions: Action[] = [];
  forwardedWorkQueueCodeID?: number;
  isActionsComplete: boolean;
  isForwarded: boolean;
  filingsWithErrors: number[] = [];

  @ViewChild('acceptAction', { static: true }) acceptAction: AcceptActionComponent | undefined;
  @ViewChild('returnAction', { static: true }) returnAction: ReturnActionComponent | undefined;
  @ViewChild('failAction', { static: true }) failAction: FailActionComponent | undefined;
  @ViewChild('forwardAction', { static: true }) forwardAction: ForwardActionComponent | undefined;

  private subscriptions: Subscription[] = [];

  constructor(private toaster: CustomToastrService,
    private envelopeService: EnvelopeService,
    public caseService: CaseService,
    private filingService: FilingService,
    private webviewerService: WebviewerService,
    public connectionService: ConnectionService,
    public apiRequestHelper: ApiRequestHelper,
    public router: Router,
    private editStateHandler: EditStateHandler,
    public queueService: QueueService) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      if (_case) {
        this.caseData = _case;

        var input = new NodeInput(null, null, null, 'getrejectreasoncodes', this.caseData.location.nodeId);
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(input));

        var input = new NodeInput(null, null, null, 'getqueuecodes', this.caseData.location.nodeId);
        this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(input));
      }
    }));

    this.subscriptions.push(this.filingService.filings.subscribe(result => {
      if (!result || !result.items || !result.items.length) {
        return;
      }
      this.filings = result.items;
      this.subscriptions.push(this.webviewerService.documentFileTree.subscribe(result => {
        if (!result) {
          return;
        }
        this.fileTrees = result;
        this.subscriptions.push(this.webviewerService.selectedDocumentFilingId.subscribe(id => {
          if (!id) {
            return;
          }
          var idx = this.filings.findIndex(f => f.filingId == id);
          if (idx > -1) {
            this.filing = this.filings[idx];
            this.filing.acceptComments = !this.filing.acceptComments ? '' : this.filing.acceptComments;
            this.filingDescription = this.filing.filingCode.description +
              (this.filing.description ? ': ' + this.filing.description : '');
          }

          this.subscriptions.push(this.webviewerService.viewerStatusSource$.subscribe(status => {
            if (status == "active") {
              this.isDocumentDisplayed = true;
            }
            else if (status == "loadDocument") {
              this.isDocumentDisplayed = false;
              this.acceptAction.closeModal();
              this.returnAction.closeModal();
              this.failAction.closeModal();
              this.currentAction = undefined;
            }
          }));
          idx = this.fileTrees.findIndex(t => t.filingId == id)
          if (idx > -1) {
            this.fileTree = this.fileTrees[idx];
          }

          this.subscriptions.push(this.filingService.filingCodes.subscribe(filingCodes => {
            if (!filingCodes || !this.filing) {
              return;
            }
            var idx = filingCodes.findIndex(f => f.codeId == this.filing.filingCode.codeId);
            if (idx > -1) {
              this.filingCode = filingCodes[idx];
            }
          }));
        }));
      }));

      this.subscriptions.push(this.envelopeService.editStatus.subscribe(editStatus => {
        this.editStatus = editStatus;
      }));

      this.subscriptions.push(this.envelopeService.showComponent.subscribe(show => {
        if (!show) {
          return;
        }

        if (show.component === 'accept' || show.component === 'reject' || show.component === 'fail') {
          if (show.isSuccess) {
            this.toaster.pop('success', 'Saved', show.message);
          }
          else {
            this.toaster.pop('error', 'Error', show.message);
          }
          this.showModal(show.component);
        }
      }));

      this.subscriptions.push(this.envelopeService.doSave.subscribe(save => {
        if (save) {
          this.isSaving = true;
          this.savingComponent = save.currentComponent;
        }
        else {
          this.isSaving = false;
        }
      }));
    }));
  }

  getApiMessage() {
    this.subscriptions.push(this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getrejectreasoncodes': {
            if (message.isSuccess === true) {
              this.rejectReasons = message.taskresult.items as iConfigCode[];
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting reason codes: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getqueuecodes': {
            if (message.isSuccess === true) {
              this.queueCodes = message.taskresult.items as iConfigCode[];
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting the queues : ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutNone);
              this.envelopeService.doSave.next(undefined);
            }
            break;
          }
          case 'savefilingaction': {
            if (message.isSuccess === true) {
              if (!message.taskresult.isLockValid) {
                this.envelopeService.showOverlay.next(false);
                this.toaster.pop('error', 'Error', 'You no longer own the lock on this envelope.', Constants.ToasterTimeoutLong);
                this.router.navigate(['/queue']);
                return;
              }
              var saveResult = message.taskresult as iSaveFilingAction;
              if (!saveResult.isEnvelopeCompleted) {
                var validationMessages = '';
                var validationMessagesFiling = '';
                this.envelopeService.showOverlay.next(false);
                if (saveResult.filings) {
                  saveResult.filings.forEach(f => {
                    this.filingsWithErrors.push(f.filingId);
                    if (f.isFilingCodeInvalid) {
                      var filingIdentifier = 'Filing ' + f.description ? f.description : ''
                      validationMessagesFiling += filingIdentifier + ' invalid for Filing Code "' + f.filingCodeDescription + '" invalid.</br>';
                    }
                    f.filingComponents.forEach(fc => {
                      if (fc.IsFilingComponentCodeInvalid) {
                        validationMessagesFiling += 'Filing Component ' + fc.FilingComponentDescription + ' for ' + filingIdentifier + ' invalid for Filing Code ' + f.filingCodeDescription + '.</br>';
                        if (fc.IsAdditionalService && fc.IsDeleted) {
                          validationMessages += 'Optional Service ' + fc.FilingComponentDescription + ' was deleted.</br>';
                        }
                      }
                      fc.documents.forEach(d => {
                        if (d.isDocumentTypeInvalid) {
                          validationMessagesFiling += 'Security Type for document ' + d.originalFileName + ' is invalid.</br>';
                        }
                      });
                    });
                  });
                  this.filingService.filingsWithErrors.next(this.filingsWithErrors);
                }
                else {
                  this.filingService.filingsWithErrors.next([]);
                }
                if (validationMessages == '') {
                  this.toaster.pop('error', 'Error', 'An unknown error occurred', Constants.ToasterTimeoutNone);
                }
                else {
                  this.toaster.pop('error', 'Error', 'An error occurred processing the envelope actions: ' + validationMessagesFiling, Constants.ToasterTimeoutNone);
                }
              }
              else {
                if (this.isForwarded) {
                  this.toaster.pop('success', 'Saved', 'Envelope ' + this.queueService.envelopeId + ' Forwarded');
                }
                else {
                  this.toaster.pop('success', 'Saved', 'Envelope ' + this.queueService.envelopeId + ' Review Complete');
                }
                this.queueService.page = 0;
                this.queueService.pageSize = 1;
                this.queueService.getQueue();
              }
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred processing the envelope actions: ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutNone);
              this.envelopeService.doSave.next(undefined);
              this.envelopeService.showOverlay.next(false);
            }
            break;
          }
          case 'getworkitems': {
            this.envelopeService.showOverlay.next(false);
            if (message.isSuccess === true) {
              this.queueList = message.taskresult.items as QueueItem[];
              if (!this.queueList.length) {
                this.router.navigate(['/']);
              }
              else {
                this.queueService.envelopeId = this.queueList[0].envelopeId;
                this.queueService.workItemId = this.queueList[0].workItemId;
                this.envelopeService.envelopeMetadata.next({ envelopeId: this.queueList[0].envelopeId, workitemId: this.queueList[0].workItemId });
              }
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting the next envelope: ' + message.error.code + ': ' + message.error.cause, Constants.ToasterTimeoutNone);
              this.router.navigate(['/queue']);
            }
            break;
          }
        }
      }));
  }

  GetNextLeadDocument(filing: iFiling) {
    var filingsWithNoAction = this.filings.filter(f => !f.isActionTaken);
    if (filingsWithNoAction && filingsWithNoAction.length) {
      var idx = this.fileTrees.findIndex(t => t.filingId == filingsWithNoAction[0].filingId);
      if (idx > -1) {
        this.filingService.selectedFilingId.next(this.fileTrees[idx].filingId);
        //First child of the fileTree is the lead document
        this.fileTrees[idx].children[0].isViewed = true;
        this.webviewerService.selectedDocument.next(this.fileTrees[idx].children[0]);
      }
    }
  }

  onClickBtn(action: string) {
    //if (this.isSaving && this.savingComponent == ComponentPage.Case) {
    //  this.toaster.pop('warning', undefined, 'Please wait until the ' + ComponentPageHelper.componentName(this.savingComponent) + ' save is complete');
    //  return;
    //}
    if (action == 'endReview') {
      this.onActionsCompleteEvent(undefined, true);
      return;
    }

    if (!this.editStateHandler.canContinueIncludingSave(this.editStatus, this.filing, this.isDocumentDisplayed, action)) {
      return;
    }
    else {
      this.showModal(action);
    }
  }

  onActionsCompleteEvent(filing: iFiling, isEndReview: boolean = false) {
    if (!this.filings && isEndReview) { //filings haven't loaded yet so it is ok to return to the queue
      this.router.navigate(['/queue']);
    }
    if (!this.filings) {
      return;
    }
    if (isEndReview) {
      this.isActionsComplete = true;
    }
    else {
      filing.isActionTaken = true;

      if (filing.forwardedWorkQueueCodeID) {
        this.isForwarded = true;
      }

      var actionFilings = this.filings.filter(f => f.isActionTaken);
      if (actionFilings && actionFilings.length == this.filings.length) {
        this.isActionsComplete = true;
      }

      if (!this.isActionsComplete && !this.isForwarded) {
        this.GetNextLeadDocument(filing);
        return;
      }
    }

    this.filings.forEach(filing => {
      if (filing.actionType && filing.actionType != ActionType.EndReview && filing.actionType != ActionType.Routed) {
        var idx = this.actions.findIndex(a => a.filingId == filing.filingId);
        if (idx == -1) {
          var action = new Action(
            filing.filingId,
            filing.actionType,
            filing.actionType == ActionType.Accept ? filing.acceptComments : (filing.filingErrorCause ? filing.filingErrorCause.comment : undefined),
            filing.filingErrorCause ? filing.filingErrorCause.rejectReasonCodeId : undefined
          );
          this.actions.push(action);
        }
      }
    });

    if (isEndReview && !this.actions.length) {
      var unlockEnvelopeInput = new UnlockEnvelopeInput(null, null, null, 'unlockenvelope', this.queueService.envelopeId, this.queueService.workItemId, false);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(unlockEnvelopeInput));

      this.router.navigate(['/queue']);
      return;
    }

    //temp
    if (this.queueService.envelopeId == 3391 ||
      this.queueService.envelopeId == 3308 ||
      this.queueService.envelopeId == 3385 ||
      this.queueService.envelopeId == 3318 ||
      this.queueService.envelopeId == 3310 ||
      this.queueService.envelopeId == 3353 ||
      this.queueService.envelopeId == 3405 ||
      this.queueService.envelopeId == 3407 ||
      this.queueService.envelopeId == 3327 ||
      this.queueService.envelopeId == 2214 ||
      this.queueService.envelopeId == 3450 ||
      this.queueService.envelopeId == 3452 ||
      this.queueService.envelopeId == 3483 ||
      this.queueService.envelopeId == 3425 ||
      this.queueService.envelopeId == 3488) {
      if (!confirm("Actions are complete for this envelope. EnvelopeComplete processing is not being done because this envelope is within the app's test envelopes.")) {
        if (isEndReview) {
          this.router.navigate(['/queue']);
        }
        return;
      }
      else {
        if (isEndReview) {
          this.router.navigate(['/queue']);
          return;
        }
      }

      this.queueService.page = 0;
      this.queueService.pageSize = 1;
      this.queueService.getQueue();
      this.envelopeService.showOverlay.next(false);
      return;
    }
    var filingActionList = new FilingActionList(
      this.caseData.caseDataId,
      this.queueService.envelopeId,
      this.queueService.workItemId,
      this.caseData.location.nodeId,
      this.caseData.caseCategory.codeId,
      this.caseData.caseType.codeId,
      filing ? filing.forwardedWorkQueueCodeID : undefined,
      this.actions,
      isEndReview);

    var saveActionsInput = new SaveActionsInput(null, null, null, 'savefilingaction', filingActionList);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(saveActionsInput));
    this.envelopeService.showOverlay.next(true);
  }

  showModal(action: string) {
    if (this.acceptAction.isModalOpen() ||
      this.returnAction.isModalOpen() ||
      this.failAction.isModalOpen() ||
      this.forwardAction.isModalOpen()) {
      //prevent clicking action if one is active.
      return;
    }

    this.currentAction = action;
    switch (action) {
      case 'accept': {
        this.activeAction = 'accept';
        this.acceptAction.showModal();
        return;
      }
      case 'reject': {
        this.activeAction = 'reject';
        this.returnAction.showModal();
        return;
      }
      case 'fail': {
        this.activeAction = 'fail';
        this.failAction.showModal();
        return;
      }
      case 'forward': {
        this.activeAction = 'forward';
        this.forwardAction.showModal();
        return;
      }
    }
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
