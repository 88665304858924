export class FileTree implements iFileTree {
  constructor(
    public filingId: number = -1,
    public id: string, //SecurityToken
    public fileName: string,
    public description: string,
    public actionType: number,
    public isViewed: boolean,
    public isManuallySelected: boolean,
    public children: iFileTree[],

  ) { }
}
export interface iFileTree {
  filingId: number;
  id: string;
  fileName: string;
  description: string;
  actionType: number;
  isViewed: boolean;
  isManuallySelected: boolean;
  children: iFileTree[];
}
