import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { CaseService } from 'src/app/common/service/case.service';
import { PartyService } from 'src/app/common/service/party.service';
import { iParty, Party } from 'src/app/common/model/envelope/party';
import { iServiceContact } from 'src/app/common/model/envelope/service-contact';
import { ServiceContactService } from 'src/app/common/service/service-contact.service';
import { PartyFilter } from 'src/app/common/service/filter/party-filter';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { EnvelopePagingInput } from 'src/app/common/request/envelope-paging-input';
import { iMessage } from 'src/app/common/response/message';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';
import { PagedModel } from 'src/app/common/model/response/paged-model';
import { Code } from '../../../common/model/envelope/code';

@Component({
  selector: 'app-serve-information',
  templateUrl: './serve-information.component.html',
  styleUrls: ['./serve-information.component.css']
})
export class ServeInformationComponent implements OnInit, OnDestroy {
  serviceContacts: iServiceContact[] = [];
  caseParties: iParty[] = [];
  casePartiesAll: iParty[] = [];
  casePartyOther: iParty;
  partyFilter: PartyFilter = new PartyFilter();
  envelopeId: number;
  isShowAll: boolean = false;
  isGetServiceComplete: boolean = false;
  selectedPage: number;

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @ViewChild('serviceHeader') serviceHeader: ElementRef;
  @Output() envButtonEvent: EventEmitter<{ component: string, key: number }> = new EventEmitter<{ component: string, key: number }>();

  numberOfResults: number = 0;
  get currentPage(): number {
    return this.partyService.currentPage.value;
  }
  set currentPage(val: number) {
    var doFilter = false;
    if (this.partyService.currentPage.getValue() != val) {
      doFilter = true;
    }
    this.partyService.currentPage.next(val);
    this.selectedPage = val;
    if (doFilter) {
      this.filter(val);
    }
  }

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  constructor(private caseService: CaseService, private partyService: PartyService, private serviceContactService: ServiceContactService, private envelopeService: EnvelopeService,
    private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper, private toaster: CustomToastrService) { }


  ngOnInit() {
    this.subscriptions.push(this.envelopeService.envelopeMetadata.subscribe(meta => {
      if (!meta) {
        return;
      }
      this.envelopeId = meta.envelopeId;
    }))

    this.subscriptions.push(this.serviceContactService.serviceContacts.subscribe(sc => {
      if (!sc) {
        return;
      }
      this.serviceContacts = sc.items;
      this.numberOfResults = sc.totalItems;
      this.processServiceContacts();
      this.subscribeToFilter();
      this.subscribeToFilterResults();
    }));
  }

  processServiceContacts() {
    this.serviceContacts.forEach(sc => {
      if (sc.streetAddressLine1) {
        sc.streetAddressLine2 = sc.streetAddressLine2 ? sc.streetAddressLine2 : ' ';
        sc.streetAddressLine3 = sc.streetAddressLine3 ? sc.streetAddressLine3 : ' ';
        var formattedStreetAddress = (`${sc.streetAddressLine1} ${sc.streetAddressLine2}  ${sc.streetAddressLine3}`).trim();
        sc.formattedAddress = `${formattedStreetAddress}, ${sc.city}, `
        sc.formattedAddress += sc.region ? sc.region.description : '';
      }
    });

    const scParties = this.serviceContacts.filter(
      (sc, i, arr) => arr.findIndex(p => p.casePartyId === sc.casePartyId) === i);
    //create iParty array 
    scParties.forEach(sc => {
      this.caseParties.push(new Party(sc.casePartyId, sc.partyType, sc.partyName, undefined, false, false, true));
    });
    this.casePartiesAll = this.caseParties.concat();
    
    this.isGetServiceComplete = true;
  }

  subscribeToFilter() {
    this.subscriptions.push(this.partyService.partyFilter.subscribe(filter => {
      this.partyService.getFilteredCaseParties(this.caseParties, this.partyFilter, this.partyService.currentPage.getValue());
    }));
  }

  subscribeToFilterResults() {
    this.subscriptions.push(this.partyService.filterCasePartyResult.subscribe(result => {
      //this.numberOfResults = result.totalItems;
      if (result.items) {
        this.caseParties = result.items.concat();
      }
      else {
        this.caseParties = undefined;
      }
   }));
  }

  //Returns all parties if filter is empty
  filter(pageNumber: number = 1) {
    this.partyFilter.pageNumber = pageNumber;
    if (this.numberOfResults > 200) {
      this.getApiMessage();
      //If there's > 200 parties, party paging is done with api getServiceContacts where only 10 ServiceContacts are returned at a time
      const inputService = new EnvelopePagingInput(null, null, null, 'getservices', this.envelopeId, pageNumber > 1 ? (pageNumber-1) * 10 : pageNumber, 10);
      this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputService));
      this.isGetServiceComplete = false;
    }
    else {
      //If there's <= 200 serviceContacts, paging is from cache
      this.partyService.getFilteredCaseParties(this.casePartiesAll, this.partyFilter, pageNumber);
    }
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case "getservices": {
            if (message.isSuccess === true) {
              const pagedServiceContacts = message.taskresult as PagedModel<iServiceContact>;
              this.serviceContacts = pagedServiceContacts.items;
              this.caseParties = [];
              this.casePartiesAll = [];
              this.processServiceContacts();
              this.partyService.getFilteredCaseParties(this.caseParties, this.partyFilter, this.selectedPage);
              this.isGetServiceComplete = true;
              this.partyService.currentPage.next(this.selectedPage)
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting services: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
        }
      });
  }

  showAll() {
    this.isShowAll = !this.isShowAll;
    if (this.isShowAll) {
      this.caseParties = this.caseParties.concat();
    }
    else {
      this.filter(1)
    }
  }

  previous() {
    var component = 'filing';
    this.envButtonEvent.emit({ component, key: 0 });
  }

  next() {
    var component = 'fees';
    this.envButtonEvent.emit({ component, key: 0 });
  }

  ngOnDestroy() {
    this.partyService.currentPage.next(1);
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if (this.signalrSub) {
      this.signalrSub.unsubscribe();
    }
  }
}

