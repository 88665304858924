import { ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function dropdownPattern(pattern: string, descriptionElementName: string, descriptionFunc: ((val: object) => string) | undefined): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === undefined) {
      return null;
    }

    var value: any;
    if (descriptionFunc) {
      value = descriptionFunc(control.value);
      if (value === undefined || value === null) {
        throw new Error("Description function resulted in an undefined value!");
      }
    }
    else {
      //check for null value or exception occurs on form.reset()
      if (control.value) {
        value = control.value[descriptionElementName];
        if (value === undefined || value === null) {
          throw new Error("Element '" + descriptionElementName + "' not found on dropdown item!");
        }
      }
    }

    if (value) {
      value = value.toString();
      return value.match(pattern) ? null : { 'pattern': { requiredPattern: pattern, actualValue: value } };
    }
    else {
      return null;
    }
  };
}
