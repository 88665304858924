import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: any): SafeHtml {

    var originalText: string;
    if (typeof value === 'string') {
      originalText = value;
    }
    else {
      // When chaining these pipes together, we can get a SafeUrl interface here instead of a string.  When we call .toString on such an object, it automatically adds a message warning us to
      // use property bindings instead of interpolation.  I have not found a way to chain multiple of these pipes together without triggering this error.  Obviously, this is a hack. ¯\_(ツ)_/¯

      // It is also worth noting that we are eventually doing exactly what they ask us to do (using [property]=binding), but Angular
      // incorrectly assumes you are doing something wrong as soon as you call the .toString() function.
      originalText = value.toString();

      if (originalText.startsWith("SafeValue must use [property]=binding"))
        originalText = originalText.substring(39, originalText.length - 33);
    }
      
    return this.sanitizer.bypassSecurityTrustHtml(originalText);
  }
}
