import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DropdownValue } from './dropdown-value';
import { ValidatorFn } from '@angular/forms';
import { dropdownPattern } from './dropdown-regex-validator';
import { DataFieldBase } from '../data-field-base';
import { ViewChild, ElementRef } from '@angular/core';
import { Strings } from '../../../localization/strings';

@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss']
})
export class DropdownFieldComponent<V = DropdownValue> extends DataFieldBase<V> implements OnInit, OnChanges, OnDestroy {

  @Input()
  public descriptionElementName: string = "description";

  @Input()
  public descriptionFunc: ((val: object) => string) | undefined = undefined;

  @Input()
  values: V[] | undefined;

  @Input()
  public hideLabel: boolean = false;

  @ViewChild('dropdown') dropdown!: ElementRef;

  public focus() {
    if (this.dropdown) {
      this.dropdown.nativeElement.focus();
    }
  }

  constructor(strings: Strings) { super(strings) }

  protected getPatternValidator(): ValidatorFn | undefined {
    if (this.config && this.config.regularExpression) {
      return dropdownPattern(this.config.regularExpression, this.descriptionElementName, this.descriptionFunc);
    }
    return undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
