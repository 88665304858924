import { Component, OnInit, OnDestroy } from '@angular/core';
import { QueueService } from '../../common/service/queue.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-review-queue-filter',
  templateUrl: './review-queue-filter.component.html',
  styleUrls: ['./review-queue-filter.component.css']
})
export class ReviewQueueFilterComponent implements OnInit, OnDestroy {

  constructor(private queueService: QueueService) { }
  isQueueSearchComplete: boolean;
  subscription: Subscription | undefined;

  ngOnInit() {
    this.subscription = this.queueService.queueSearchComplete.subscribe(q => {
      this.isQueueSearchComplete = q;
    });
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }
}
