import { BaseRequest } from 'src/app/common/request/base-request';

export class FilingComponentCodeInput extends BaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public nodeId: number,
    public filingCodeId: number,
    public isAdditionalService: boolean
  ) { super(tenant, messageId, connectionId, action) }
}
