import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { DataFieldBase } from '../data-field-base';
import { dateTypeValidator } from './date-type-validator';
import { Strings } from '../../../localization/strings';
import { Constants } from '../../../constants';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateStringAdapter } from '../../../helper/ngb-date-string-adapter';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss']
})
export class DateFieldComponent extends DataFieldBase<string> implements OnInit, OnChanges, OnDestroy {

  @Input() minDate: string | undefined;
  defaultMinDateStruct: NgbDateStruct = { year: 1, month: 1, day: 1 };
  minDateStruct: NgbDateStruct = this.defaultMinDateStruct;

  @Input() maxDate: string | undefined;
  defaultMaxDateStruct: NgbDateStruct = { year: 9999, month: 12, day: 31 };
  maxDateStruct: NgbDateStruct = this.defaultMaxDateStruct;

  @Input() allowFutureDates: boolean = true;
  @Input() placement: string = 'top';

  constructor(strings: Strings) { super(strings) }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes['minDate']) {
      this.minDateStruct = this.minDate ? NgbDateStringAdapter.fromModel(this.minDate) : this.defaultMinDateStruct;
    }
    if (changes['maxDate'] || changes['allowFutureDates']) {
      let max: string | undefined;

      if (this.maxDate) {
        if (!this.allowFutureDates) {
          max = new Date(Math.min(Date.now(), Date.parse(this.maxDate))).toISOString();
        } else {
          max = this.maxDate;
        }
      }
      else if (!this.allowFutureDates) {
        max = new Date().toISOString();
      }

      this.maxDateStruct = max ? NgbDateStringAdapter.fromModel(max) : this.defaultMaxDateStruct;
    }
    if (changes['minDate'] || changes['maxDate'] || changes['allowFutureDates']) {
      this.updateFormConfig();
    }
  }

  getDateFormat() {
    return Constants.DATE_FMT;
  }

  protected getPatternValidator(): ValidatorFn | undefined {
    return undefined;
  }
}
