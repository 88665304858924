import { Component, OnInit } from '@angular/core';
import { NgbTabChangeEvent, NgbTabset } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-stamp-tabs',
  templateUrl: './stamp-tabs.component.html',
  styleUrls: ['./stamp-tabs.component.css']
})
export class StampTabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onTabChange($event: NgbTabChangeEvent) {


    console.info($event.nextId);
    console.info('onTabChangeX=2' + $event.activeId);
  }

}
