import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { iCase } from 'src/app/common/model/envelope/case';
import { iEnvelope } from 'src/app/common/model/envelope/envelope';
import { iFilingDetail } from 'src/app/common/model/envelope/filing-detail';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { FormGroup } from '@angular/forms';
import { FilingService } from 'src/app/common/service/filing.service';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { iCode } from 'src/app/common/model/envelope/code';
import { iMessage } from 'src/app/common/response/message';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { iFilingComponentCode } from 'src/app/common/model/config/filing-component-code';
import { FilingOptionalService } from 'src/app/common/model/envelope/filing-optional-service';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

@Component({
  selector: 'app-edit-file-service',
  templateUrl: './edit-file-service.component.html',
  styleUrls: ['./edit-file-service.component.css']
})
export class EditFileServiceComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  @Input() filingBeforeEdits: iFilingDetail | undefined;
  @Input() isServicesTabActive: boolean = false;

  case: iCase;
  envelope: iEnvelope;
  filing: iFilingDetail;
  showSelectedServicesOnly: boolean;

  optionalService: iFilingComponentCode | undefined;
  optionalServices: iFilingComponentCode[] | undefined;
  optionalServicesBase: iFilingComponentCode[] | undefined;

  isSelectedConfig: iDataFieldConfig | undefined;
  multiplierConfig: iDataFieldConfig | undefined;

  isDirty: boolean;
  isValid: boolean = true;
  public stopDirtyCheck: boolean;

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, private filingService: FilingService,
    private connectionService: ConnectionService, private toaster: CustomToastrService) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      this.case = _case;
      if (!this.case) {
        return;
      }

      this.subscriptions.push(this.filingService.selectedFilingDetail.subscribe(_filing => {
        if (!_filing) {
          return;
        }
        this.filing = _filing;
        this.stopDirtyCheck = false;
      }))
      this.multiplierConfig = new DataFieldConfigDefaults(true, false, true);
      this.isSelectedConfig = new DataFieldConfigDefaults(true, false, false);
    }));
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case "getfilingcomponentcodes": {
           if (message.isSuccess === true) {
              var serviceCodesCodes = message.taskresult.items as iFilingComponentCode[];
             if (serviceCodesCodes && serviceCodesCodes.length && !serviceCodesCodes[0].isAdditionalService) {
                  return;
                }
             this.optionalServicesBase = serviceCodesCodes;
             this.optionalServicesBase = this.optionalServicesBase.sort((c1, c2) => c1.displayOrder - c2.displayOrder);
             this.setSelectedServices();
             this.detectChanges();
            }
            else {
              this.toaster.pop('error', 'Error', 'Unable to get filing service codes: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
        }
      });
  }

  setSelectedServices() {
    var optionalServices = this.optionalServicesBase.concat(); //clear values from previous filing
    optionalServices.forEach(serviceCode => {
      var index = this.filing.optionalServices.forEach(filingService => {
        if (filingService.filingComponentCode.codeId == serviceCode.codeId) {
          serviceCode.isSelected = true;
          serviceCode.optionalServiceId = filingService.optionalServiceId;
          serviceCode.multiplierCount = filingService.multiplierCount;
          serviceCode.multiplierValue = filingService.multiplierValue;
          this.calculateTotal(serviceCode);
        }
      });
    });
    this.optionalServices = optionalServices.concat();
  }

  selectService(service: iFilingComponentCode) {
    if (service.isSelected) {
      if (!service.optionalServiceId) {
        var newOptionalService = this.createNewOptionalService(service);
        service.optionalServiceId = newOptionalService.optionalServiceId;
      }
    }
    else {
      service.multiplierCount = undefined;
      var index = this.filing.optionalServices.findIndex(fc => fc.optionalServiceId == service.optionalServiceId);
      if (index > -1) {
        this.filing.optionalServices[index].isDeleted = true;
      }
    }
    this.calculateTotal(service);
  }

  calculateTotal(service: iFilingComponentCode) {
    if (service.isSelected) {
      if (service.feeAmount && service.feeAmount > 0) {
        if (service.hasMultiplier) {
          if (service.multiplierCount) {
            service.feeTotal = service.feeAmount * service.multiplierCount;
          }
          else {
            service.feeTotal = undefined;
          }
        }
        else {
          service.feeTotal = service.feeAmount;
        }
      }
      else {
        service.feeTotal = service.feeAmount;
      }
    }
    else {
      service.feeTotal = undefined;
    }
  }

  showSelectedOnly() {
    this.showSelectedServicesOnly = !this.showSelectedServicesOnly;
  }

  resetForm() {
    this.showSelectedServicesOnly = false;
  }

  getUpdates() {
    //optionalServices are type iFilingComponentCode.
    //filing.optionalServices are type iFilingOptionalService
    this.optionalServices.forEach(service => {
      var index = this.filing.optionalServices.findIndex(fc => fc.filingComponentCode.codeId == service.codeId);
      if (service.isSelected) {
        if (index > -1) {
          this.filing.optionalServices[index].isDeleted = false;
          this.filing.optionalServices[index].multiplierCount = service.multiplierCount ? Number(service.multiplierCount) : undefined;
          this.filing.optionalServices[index].amount = service.amount ? Number(service.amount) : undefined;
          this.filing.optionalServices[index].multiplierValue = service.multiplierValue ? Number(service.multiplierValue) : undefined;
          if (this.filing.optionalServices[index].multiplierCount != this.filingBeforeEdits.optionalServices[index].multiplierCount ||
            this.filing.optionalServices[index].amount != this.filingBeforeEdits.optionalServices[index].amount ||
            this.filing.optionalServices[index].multiplierValue != this.filingBeforeEdits.optionalServices[index].multiplierValue) {
            //Update service
            this.filing.optionalServices[index].isUpdated = true;
          }
        }
        else {
          //Add new service
          this.filing.optionalServices.push(this.createNewOptionalService(service));
        }
      }
      else {
        if (index > -1) {
          //Delete service
          this.filing.optionalServices[index].isDeleted = true;
        }
      }
    });
    return this.filing.optionalServices;
  }

  createNewOptionalService(service: iFilingComponentCode) {
    var optionalService = new FilingOptionalService(
      -1,    //optionalServiceId
      ({ codeId: service.codeId, description: service.description }) as iCode,  //filingComponentCode
      service.multiplierCount ? Number(service.multiplierCount) : undefined,
      service.multiplierValue ? Number(service.multiplierValue) : undefined,
      service.amount ? Number(service.amount) : undefined,
      false,   //isUpdated
      false)   //isDeleted
    return optionalService;
  }

  detectChanges() {
    this.form.valueChanges.subscribe(change => {
      if (this.stopDirtyCheck) {
        return;
      }
      this.isDirty = this.form.dirty;
      this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
    });

    this.subscriptions.push(this.form.statusChanges.subscribe(status => {
      if (this.stopDirtyCheck) {
        return;
      }
      if (status == "INVALID") {
        this.isValid = false;
        this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
      }
      else {
        this.isValid = true;
        this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
      }
    }));
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.signalrSub.unsubscribe();
  }

}
