import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes  } from '@angular/router';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { HotkeyModule } from 'angular2-hotkeys';
//import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar'; 
import { WebViewerComponent } from './webviewer/webviewer.component';
import { LoggerService } from 'src/app/common/service/logger.service';
import { LoggerComponent } from './logger/logger.component';
import { ActionsComponent } from './webviewer/actions/actions.component';
import { AnnotationsComponent } from './webviewer/annotations/annotations.component';
import { ActionButtonsComponent } from './webviewer/action-buttons/action-buttons.component';
import { NgbModule, NgbDropdownModule, NgbDateNativeAdapter, NgbDateAdapter, NgbDateParserFormatter, NgbTimeAdapter, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AnnotationButtonsComponent } from './webviewer/annotation-buttons/annotation-buttons.component';
import { AnnotationDrawLineButtonComponent } from './webviewer/annotation-draw-line-button/annotation-draw-line-button.component';
import { FileViewComponent } from './webviewer/webviewer-client/file-view/file-view.component';
import { EnvelopeQueueComponent } from './webviewer-shared/envelope-queue/envelope-queue.component';
import { LocalizeDirective } from 'src/app/common/localization/localize.directive';
import { LocalizeTranslatePipe } from 'src/app/common/localization/localization.pipe';
//action components
import { AcceptActionComponent } from 'src/app/webviewer/actions/accept-action/accept-action.component';
import { FailActionComponent } from 'src/app/webviewer/actions/fail-action/fail-action.component';
import { ForwardActionComponent } from 'src/app/webviewer/actions/forward-action/forward-action.component';
import { ReturnActionComponent } from 'src/app/webviewer/actions/return-action/return-action.component';
import { StampButtonComponent } from './webviewer/stamp-button/stamp-button.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { StampViewComponent } from './webviewer-shared/stamp-view/stamp-view.component';
import { TestingViewComponent } from './webviewer-shared/testing-view/testing-view.component';
import { FilingStateHelper } from 'src/app/common/enum/filing-state';
import { SearchResultsSummaryComponent } from 'src/app/common/component/search-results-summary/search-results-summary.component';

import { ReviewQueueComponent } from './review/review-queue/review-queue.component';
import { ReviewQueueViewComponent } from './review/review-queue-view/review-queue-view.component';
import { ReviewQueueFilterComponent } from './review/review-queue-filter/review-queue-filter.component';
import { ReviewQueueFilterBarComponent } from './review/review-queue-filter-bar/review-queue-filter-bar.component';

//dfc components
import { TextFieldComponent } from 'src/app/common/component/data-fields/text-field/text-field.component';
import { CheckboxFieldComponent } from 'src/app/common/component/data-fields/checkbox-field/checkbox-field.component';
import { DateFieldComponent } from 'src/app/common/component/data-fields/date-field/date-field.component';
import { DfcWrapperComponent } from 'src/app/common/component/data-fields/dfc-wrapper/dfc-wrapper.component';
import { CodeDropdownComponent } from './common/component/data-fields/dropdown-field/code-dropdown.component';
import { DropdownFieldComponent } from 'src/app/common/component/data-fields/dropdown-field/dropdown-field.component';
import { EmailFieldComponent } from 'src/app/common/component/data-fields/email-field/email-field.component';
import { MultiEmailFieldComponent } from 'src/app/common/component/data-fields/multi-email-field/multi-email-field.component';
import { MaskedTextFieldComponent } from './common/component/data-fields/masked-text-field/masked-text-field.component';
import { TextAreaFieldComponent } from 'src/app/common/component/data-fields/text-area-field/text-area-field.component';
import { TimeFieldComponent } from './common/component/data-fields/time-field/time-field.component';
import { DataFieldMessageComponent } from './common/component/data-fields/data-field-message/data-field-message.component';
//envelope components
import { EditTabsComponent } from './webviewer-shared/edit-tabs/edit-tabs.component';
import { EnvelopeViewComponent } from './webviewer-shared/envelope-view/envelope-view.component';
import { CaseInformationComponent } from './webviewer-shared/envelope-view/case-information/case-information.component';
import { EditCrossReferenceComponent } from './webviewer-shared/envelope-view/case-information/edit-cross-reference/edit-cross-reference.component';
import { CrossReferenceInputComponent } from './webviewer-shared/envelope-view/case-information/edit-cross-reference/cross-reference-input/cross-reference-input.component';
import { DocumentInformationComponent } from './webviewer-shared/envelope-view/document-information/document-information.component';
import { PartyInformationComponent } from './webviewer-shared/envelope-view/party-information/party-information.component';
import { PartyInformationListComponent } from './webviewer-shared/envelope-view/party-information/party-information-list/party-information-list.component';
import { EditPartyComponent } from 'src/app/webviewer-shared/envelope-view/party-information/edit-party/edit-party.component';
import { VerifyPartyComponent } from 'src/app/webviewer-shared/envelope-view/party-information/verify-party/verify-party.component';
import { ExistingAttorneysComponent } from 'src/app/webviewer-shared/envelope-view/party-information/party-attorneys/existing-attorneys/existing-attorneys.component';
import { AddAttorneyComponent } from 'src/app/webviewer-shared/envelope-view/party-information/party-attorneys/add-attorney/add-attorney.component';
import { EnvelopeInformationComponent } from './webviewer-shared/envelope-view/envelope-information/envelope-information.component';
import { ChargesInformationComponent } from './webviewer-shared/envelope-view/charges-information/charges-information.component';
import { FileInformationComponent } from './webviewer-shared/envelope-view/file-information/file-information.component';
import { EditFileTabsComponent } from './webviewer-shared/envelope-view/file-information/edit-file-tabs/edit-file-tabs.component';
import { EditFileComponent } from './webviewer-shared/envelope-view/file-information/edit-file/edit-file.component';
import { EditAssociatedPartiesComponent } from './webviewer-shared/envelope-view/file-information/edit-associated-parties/edit-associated-parties.component';
import { EditFileServiceComponent } from './webviewer-shared/envelope-view/file-information/edit-file-service/edit-file-service.component';
import { ServeInformationComponent } from './webviewer-shared/envelope-view/serve-information/serve-information.component';
import { FeesInformationComponent } from './webviewer-shared/envelope-view/fees-information/fees-information.component';
import { NotesInformationComponent } from './webviewer-shared/envelope-view/notes-information/notes-information.component';
import { EnvelopeSidebarComponent } from './webviewer-shared/envelope-view/envelope-sidebar/envelope-sidebar.component';
import { RefreshComponent } from 'src/app/common/component/refresh/refresh.component';
//pipes
import { DateTimeFormatPipe } from 'src/app/common/pipe/date-time-format.pipe';
import { BooleanFilterPipe } from 'src/app/common/pipe/boolean-filter.pipe';
import { BooleanConditionalFilterPipe } from 'src/app/common/pipe/boolean-conditional-filter.pipe';
import { CodeDescriptionPipe } from 'src/app/common/pipe/code-description.pipe';
import { IsEqualToPipe } from 'src/app/common/pipe/is-equal-to.pipe';
import { IsNotEqualToPipe } from 'src/app/common/pipe/is-not-equal-to.pipe';
import { SafeUrlPipe } from './common/pipe/sanitize-url.pipe';
import { SafeHtmlPipe } from './common/pipe/sanitize-html.pipe';
import { DateFormatPipe } from './common/pipe/date-format.pipe';
//helpers
import { NgbTimeStringAdapter } from './common/helper/ngb-time-string-adapter';
import { NgbDateStringAdapter } from './common/helper/ngb-date-string-adapter';
import { NgbDateENUSParserFormatter } from 'src/app/common/helper/ngb-date-enus-parser-formatter';
import { FilterArray } from './common/helper/filter-array';
import { HtmlPropertyHelper } from './common/helper/html-property-helper';
import { ApiRequestHelper } from './common/helper/api-request-helper';
import { EditStateHandler } from './common/helper/edit-state-handler';
import { StampTabsComponent } from './webviewer-shared/stamp-tabs/stamp-tabs.component';
import { WebviewerClientComponent } from './webviewer/webviewer-client/webviewer-client.component';
//temp dev tool
import { TempEnvelopeListComponent } from 'src/app/webviewer/actions/temp-envelope-list/temp-envelope-list.component';
//UI Components
import { AddressComponent } from 'src/app/common/component/address/address.component';
import { CommTestComponent } from './testing/comm-test/comm-test.component';


import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MomentModule } from 'ngx-moment';


import { MatPaginatorModule } from '@angular/material/paginator';
import { ReviewQueueFilterEnvelopeIdComponent } from './review/review-queue-filter/review-queue-filter-envelope-id/review-queue-filter-envelope-id.component';
import { ReviewQueueFilterConfirmComponent } from './review/review-queue-filter/review-queue-filter-confirm/review-queue-filter-confirm.component';
import { ReviewQueueFilterCaseIdComponent } from './review/review-queue-filter/review-queue-filter-case-id/review-queue-filter-case-id.component';
import { ReviewQueueFilterDateRangeComponent } from './review/review-queue-filter/review-queue-filter-date-range/review-queue-filter-date-range.component';
import { LandingComponent } from './landing/landing.component';



// This part to get the base HREF is from Stack Overflow: https://stackoverflow.com/questions/39287444/angular2-how-to-get-app-base-href-programmatically/39287458#39287458
/**
 * This function is used internal to get a string instance of the `<base href="" />` value from `index.html`.
 * This function is used internal to get a string instance of the `<base href="" />` value from `index.html`.
 * This is an exported function, instead of a private function or inline lambda, to prevent this error:
 *
 * `Error encountered resolving symbol values statically.`
 * `Function calls are not supported.`
 * `Consider replacing the function or lambda with a reference to an exported function.`
 *
 * @param platformLocation an Angular service used to interact with a browser's URL
 * @return a string instance of the `<base href="" />` value from `index.html`
 */
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}






const routes: Routes = [
  { path: '', component: LandingComponent, pathMatch: 'full' },
  //{ path: 'wsfedsession', component: SessionComponent },
  { path: 'landing', component: LandingComponent, pathMatch: 'full' },
  //{ path: 'viewer/:envelopeId/:workItemId', component: WebViewerComponent },
  { path: 'viewer', component: WebViewerComponent },
  { path: 'queue', component: ReviewQueueViewComponent },
  { path: 'commtest', component: CommTestComponent },
  { path: 'refresh', component: RefreshComponent },
];

//library.add(fas);
@NgModule({
  declarations: [
    AppComponent,
    WebViewerComponent,
    LoggerComponent,
    ActionsComponent,
    AnnotationsComponent,
    ActionButtonsComponent,
    AnnotationButtonsComponent,
    AnnotationDrawLineButtonComponent,
    FileViewComponent,
    EnvelopeQueueComponent,
    AcceptActionComponent,
    ReturnActionComponent,
    FailActionComponent,
    ForwardActionComponent,
    StampButtonComponent,
    DocumentListComponent,
    EditTabsComponent,
    StampViewComponent,
    TestingViewComponent,
    EnvelopeViewComponent,
    SearchResultsSummaryComponent,
    LocalizeDirective,
    LocalizeTranslatePipe,
    TextFieldComponent,
    CheckboxFieldComponent,
    DateFieldComponent,
    DfcWrapperComponent,
    CodeDropdownComponent,
    DropdownFieldComponent,
    EmailFieldComponent,
    MultiEmailFieldComponent,
    MaskedTextFieldComponent,
    TextAreaFieldComponent,
    TimeFieldComponent,
    DataFieldMessageComponent,
    CaseInformationComponent,
    EditCrossReferenceComponent,
    CrossReferenceInputComponent,
    EnvelopeViewComponent,
    DocumentInformationComponent,
    PartyInformationComponent,
    PartyInformationListComponent,
    VerifyPartyComponent,
    AddAttorneyComponent,
    ExistingAttorneysComponent,
    EditPartyComponent,
    EnvelopeInformationComponent,
    ChargesInformationComponent,
    FileInformationComponent,
    EditFileTabsComponent,
    EditFileComponent,
    EditAssociatedPartiesComponent,
    EditFileServiceComponent,
    ServeInformationComponent,
    FeesInformationComponent,
    NotesInformationComponent,
    EnvelopeSidebarComponent,
    DateTimeFormatPipe,
    BooleanFilterPipe,
    BooleanConditionalFilterPipe,
    CodeDescriptionPipe,
    IsEqualToPipe,
    IsNotEqualToPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    DateFormatPipe,
    StampTabsComponent,
    WebviewerClientComponent,
    TempEnvelopeListComponent,
    AddressComponent,
    ReviewQueueComponent,
    ReviewQueueViewComponent,
    ReviewQueueFilterComponent,
    ReviewQueueFilterBarComponent,
    RefreshComponent,
    ReviewQueueFilterEnvelopeIdComponent,
    ReviewQueueFilterConfirmComponent,
    ReviewQueueFilterCaseIdComponent,
    ReviewQueueFilterDateRangeComponent,
    LandingComponent
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CommonModule,
    HotkeyModule.forRoot(),      
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    NgbModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    //DragDropModule,
    //MatCardModule,
    MatTreeModule,
    NgScrollbarModule,
    NgbDropdownModule,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatExpansionModule,
    CdkTableModule,
    CdkTreeModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgxDaterangepickerMd.forRoot(),
    MomentModule
    
  ],
  providers: [
    LoggerService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateAdapter, useClass: NgbDateStringAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateENUSParserFormatter },
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    FilterArray,
    NgbActiveModal,
    FilingStateHelper,
    HtmlPropertyHelper,
    ApiRequestHelper,
    EditStateHandler
    
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    VerifyPartyComponent,
  ],
  exports: [RouterModule]
})
export class AppModule { }
