import { Component, NgZone, OnInit, ViewChild, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';


@Component({
  selector: 'app-testing-view',
  templateUrl: './testing-view.component.html',
  styleUrls: ['./testing-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestingViewComponent implements OnInit {

  constructor(private ngZone: NgZone) { }


  events: string[] = [];
  opened: boolean;

  ngOnInit() {

  }


  ngAfterViewInit() {

  }

}
