import { BasePagingFilter } from './base-paging-filter';

export class PartyFilter extends BasePagingFilter {
  constructor(
    public nameFilter: string = '',
    public uniqueFilterName: string = '',
    perPage: number = 10,
    pageNumber: number = 1,
  ) {
    super(perPage, pageNumber);
  }
}
