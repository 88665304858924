import { Component, OnInit, Injectable } from '@angular/core';
import { LogMessage, LogLevel } from 'src/app/common/model/response/logging';

@Injectable()
@Component({
  selector: 'app-logger',
  templateUrl: './logger.component.html',
  styleUrls: ['./logger.component.css']
})
export class LoggerComponent implements OnInit {
  messages: Array<LogMessage>;
  private logLevel: LogLevel = LogLevel.Warning;
  isExpanded: boolean = false;


  constructor() {
    this.messages = new Array<LogMessage>();
  }


  ngOnInit() {
  }


  log(level: LogLevel, message: string, data: object = null): LogMessage {
    const messageToLog = LogMessage.create(level, message, data);
    this.logMessage(messageToLog);

    return messageToLog;
  }


  debug(message: string, data: object = null): LogMessage {
    return this.log(LogLevel.Debug, message, data);
  }


  info(message: string, data: object = null): LogMessage {
    return this.log(LogLevel.Information, message, data);
  }


  warning(message: string, data: object = null): LogMessage {
    return this.log(LogLevel.Warning, message, data);
  }


  error(message: string, data: object = null): LogMessage {
    return this.log(LogLevel.Error, message, data);
  }


  markTime(locator: string): void {
    console.timeStamp(locator);
  }


  expand(): void {
    this.isExpanded = true;
  }

  collapse(): void {
    this.isExpanded = false;
  }

  toggle(): boolean {
    this.isExpanded = !this.isExpanded;
    return this.isExpanded;
  }


  private logMessage(message: LogMessage): void {
    this.messages.push(message);
    this.writeLogMessageToConsole(message);
    this.publishMessage(message);
  }


  private writeLogMessageToConsole(message: LogMessage): void {

    switch (message.logLevel) {

      case LogLevel.Information:
        LoggerComponent.outputLogMessageToConsole(console.info, message);
        break;

      case LogLevel.Warning:
        LoggerComponent.outputLogMessageToConsole(console.warn, message);
        break;

      case LogLevel.Error:
        LoggerComponent.outputLogMessageToConsole(console.error, message);
        break;

      case LogLevel.Debug:
        LoggerComponent.outputLogMessageToConsole(console.debug, message);
        break;

      case LogLevel.Log:
      default:
        LoggerComponent.outputLogMessageToConsole(console.log, message);
    }

  }


  private static outputLogMessageToConsole(method: { (message?: any, ...optionalParams: any[]): void; (message?: any, ...optionalParams: any[]): void }, message: LogMessage) {
    method(message.message, message.additionalData);
  }


  private publishMessage(message: LogMessage): void {
    // TODO: Check to see if we have any listeners (and create listeners?)
    // which will publish any log messages to some other location, such as
    // a logging service, database, etc.
  }


}
