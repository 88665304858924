import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFeeAndPaymentInformation } from 'src/app/common/model/envelope/fee-and-payment-information';
import { iEnvelopeResponse } from 'src/app/common/model/response/envelope-response';

@Injectable({
  providedIn: 'root'
})
export class FeesService {
  public fees: BehaviorSubject<IFeeAndPaymentInformation | undefined>;

  constructor() {
    this.fees = new BehaviorSubject<IFeeAndPaymentInformation | undefined>(undefined);
  }
}
