export interface iDataFieldConfig  {
  isVisible: boolean;
  isReadOnly: boolean;
  isRequired: boolean;
  helpText: string;
  contextualHelp: string;
  defaultValue: string;
  regularExpression: string;
  ghostText: string;
  validationErrorMessage: string;
  codeId: number;
  code: string;
};

export class DataFieldConfigDefaults implements iDataFieldConfig {
  constructor(
    public isVisible: boolean,
    public isReadOnly: boolean = false,
    public isRequired: boolean = false,
    public helpText: string = '',
    public contextualHelp: string = '',
    public defaultValue: string = '',
    public regularExpression: string = '',
    public ghostText: string = '',
    public validationErrorMessage: string = '',
    public codeId: number = -1,
    public code: string = '',
  ){ }
};
