import { Component, OnInit, OnChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DataFieldBase } from '../data-field-base';
import { Strings } from '../../../localization/strings';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { textPattern } from '../text-field/text-regex-validator';

@Component({
  selector: 'app-masked-text-field',
  templateUrl: './masked-text-field.component.html',
  styleUrls: ['./masked-text-field.component.scss']
})
export class MaskedTextFieldComponent extends DataFieldBase implements OnInit, OnChanges, OnDestroy {
  @ViewChild('input')
  private input!: ElementRef;

  private originalValue: string | undefined;

  editing: boolean = false;

  constructor(strings: Strings) {
    super(strings);
  }

  ngOnInit() {
    super.ngOnInit();

    this.originalValue = this.value;
  }

  focus() {
    this.input.nativeElement.focus();
  }

  edit() {
    this.setFormValue(undefined);
    this.editing = true;

    setTimeout(() => this.focus());
  }

  reset() {
    this.setFormValue(this.originalValue);
    this.editing = false;
  }

  protected getPatternValidator(): ValidatorFn | undefined {
    if (this.config && this.config.regularExpression) {
      const validatorFn = textPattern(this.config.regularExpression);

      return (control: AbstractControl): ValidationErrors | null => {
        if (control.value === this.originalValue) return null;

        return validatorFn(control);
      };
    }
    return undefined;
  }
}
