import { Injectable } from '@angular/core';
import { LoggerComponent } from '../../logger/logger.component';
import { LogLevel } from 'src/app/common/model/response/logging';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

private readonly logger: LoggerComponent;

  constructor() {
    this.logger = new LoggerComponent();
  }

  log(message: string, data: object = null) {
    this.logger.log(LogLevel.Log, message, data);
  }

  info(message: string, data: object = null) {
    this.logger.info(message, data);
  }

  warn(message: string, data: object = null) {
    this.logger.warning(message, data);
  }

  error(message: string, data: object = null) {
    this.logger.error(message, data);
  }

  debug(message: string, data: object = null) {
    this.logger.debug(message, data);
  }

  markTime(locator: string) {
    console.timeStamp(locator);
  }

}
