import { Component, OnInit, Input } from '@angular/core';
//import {} from '../models/document';

@Component({
  selector: 'document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {

  @Input() documents: Array<Document>;

  constructor() {
  }

  ngOnInit() {
  }

}
