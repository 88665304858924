import { Component, OnInit } from '@angular/core';
import { QueueService } from '../../../common/service/queue.service';

@Component({
  selector: 'app-review-queue-filter-confirm',
  templateUrl: './review-queue-filter-confirm.component.html',
  styleUrls: ['./review-queue-filter-confirm.component.css']
})
export class ReviewQueueFilterConfirmComponent implements OnInit {

  constructor(private queueService: QueueService) { }

  ngOnInit(): void {
  }

  setFilter() {
    console.log(this.queueService.filterEnvelopeId);
    this.queueService.getQueue();
    this.queueService.queueSearchComplete.next(false);

  }

  clearFilter() {
    this.queueService.filterEnvelopeId = null;
    this.queueService.filterCaseNumber = null;
    this.queueService.filterStartDate = null;
    this.queueService.filterEndDate = null;
    this.queueService.clearFilter();
  }

}
