import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanConditionalFilter',
  pure: true
})

// Performs filter only if conditional property is true

// Returns items in an array based on a boolean property.
// For example, this pipe returns all parties that aren't deleted:
//  <tr *ngFor="let party of parties | booleanFilter: 'isDeleted' : false" >

export class BooleanConditionalFilterPipe implements PipeTransform {
  transform(items: any[], conditionalProperty: boolean, property: string, value: boolean): any[] {
    if (!conditionalProperty) return items;

    if (!items) return [];
    return items.filter(it =>
      it[property] === value);
  }
}


