import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { Message } from 'src/app/common/model/response/message';
import { LoggerService } from 'src/app/common/service//logger.service';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { EditTabsComponent } from '../webviewer-shared/edit-tabs/edit-tabs.component';
import { WebviewerClientComponent, ZoomModel } from './webviewer-client/webviewer-client.component';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConnectionService } from '../common/service/connection.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EnvelopeService } from '../common/service/envelope.service';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

declare const WebViewer: any;

let WebViewerComp: WebViewerComponent;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/xml',
    'Authorization': 'my-auth-token'
  })
};

@UntilDestroy()
@Component({
  selector: 'web-viewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.css']
})
export class WebViewerComponent implements OnInit, AfterViewInit, OnDestroy {

 @ViewChild('viewer', { static: true }) viewer: ElementRef;

  @ViewChild(EditTabsComponent, { static: true }) editTabs: EditTabsComponent;

  @ViewChild(WebviewerClientComponent, { static: true }) toolBar: WebviewerClientComponent;

  messages = new Array<Message>();
  message = new Message();
  httpCL: HttpClient;

  filings: Array<iFiling>;

  logger: LoggerService;
  baseUrl: string;
  isWebViewerReady: boolean = false;
  isFilingWithoutDocument: boolean = false;
  showOverlay: boolean = false;

  signalrSub: Subscription;

  private subscriptions: Subscription[] = [];
  //subStamp: Subscription;
  // stamp: Subscription;

  //subAnnotation: Subscription;
  // annotation: Subscription;

  leftPanelState: boolean = false;

  constructor(
    private webviewerService: WebviewerService,
    private _ngZone: NgZone,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    logger: LoggerService,
    private connectionService: ConnectionService,
    private route: ActivatedRoute,
    private envelopeService: EnvelopeService,
    private toaster: CustomToastrService
  ) {
    this.logger = logger;
    this.logger.debug(`Base URL: ${baseUrl}`);
    this.baseUrl = baseUrl;
    this.httpCL = http;
    this.filings = new Array<iFiling>();

    //this.signalrSub = this.connectionService.signalrSource$.subscribe(
    //  (data) => {
    //    console.log(">>> WebViewerComponent")
    //  }
    //)



  }

  wvInstance: any;
  annoMgr: any;
  opened: boolean = true;

  ngOnInit() {

    this.route.paramMap.subscribe(
      param => {
        const id = param.get("id");
      }
    )

    WebViewerComp = this;
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
    this.subscriptions.push(this.webviewerService.webviewerReady.subscribe(isWebViewerReady => {
      if (isWebViewerReady) {
        this.isWebViewerReady = isWebViewerReady;
        this.subscriptions.push(this.webviewerService.selectedDocument.subscribe(node => {
          if (node) {
            if (node.id == 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF') {
              this.isFilingWithoutDocument = true;
            }
            else {
              this.isFilingWithoutDocument = false;
            }
            if (this.isFilingWithoutDocument) {
              this.webviewerService.sendViewerStatus("active");
            }
            else {
              if (this.isWebViewerReady) {
                this.loadSelectedDocument(node);
              }
            }
          }
        }))
      }
    }));
    this.subscriptions.push(this.envelopeService.showOverlay.subscribe(s => this.showOverlay = s));
  }


  wvDocumentLoadedHandler(): void {
    const docViewer = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;
    const { Annotations } = this.wvInstance;
    this.webviewerService.sendViewerStatus("active");

    console.log(this.wvInstance.annotManager);

  }

  ngAfterViewInit(): void {


    WebViewer({
      licenseKey: "Tyler Technologies, Inc.(tylertech.com):OEM:Odyssey File & Serve/Warrants App::B+:AMS(20210421):DBA5471D04A7480AB360B13AC9A2737860612FA3C96EF592DB6C758A042489F65A8C31F5C7",
      path: 'lib/pdftron-viewer',
      useDownloader: true,
      fullAPI: true,
      disabledElements: ['header', 'outlinesPanel', 'notesPanel', 'outlinesPanelButton', 'notesPanelButton', 'thumbnailsPanelButton', 'thumbnailControl', 'leftPanelTabs', 'leftPanel.left-panel-header']
    }, this.viewer.nativeElement)
      .then(instance => {
        this.wvInstance = instance;
        this.webviewerService.webviewerReady.next(true);
        //this.loadSelectedDocument(undefined);
        //this.loadSelectedDocument({ filingId: undefined, id: '29c81289-590e-49bb-b2dd-02958527a693', fileName: '29c81289-590e-49bb-b2dd-02958527a693.pdf', description: '', children: [], isViewed: false });
       });
  }

  loadSelectedDocument(node: FileTree) {
    if (!this.wvInstance) {
      this.isViewerReady(0, node);
    }
    else {
      this.loadSelectedDocumentReady(node);
    }
  }

  isViewerReady(numberAttempts: number, node: FileTree) {
    if (!this.wvInstance) {
      if (numberAttempts > 200) {
        //this.toaster.pop('error', 'Error', "Document Viewer load failed");
        return;
      }
      setTimeout(() => {
        numberAttempts++;
        this.isViewerReady(numberAttempts, node);
      }, 200);
    }
    else {
      this.loadSelectedDocumentReady(node);
    }
  }


  loadSelectedDocumentReady(node: FileTree) {
    if (!node || !node.filingId) {
      //todo send toast
      return;
    }
    // instance.enableRedaction();

    this.logger.debug('WebViewer.viewer', this.viewer);
    this.webviewerService.sendViewerStatus("loadDocument");

    //instance.openElements(['thumbnailsPanel']);
    var annotManager = this.wvInstance.docViewer.getAnnotationManager();

    this.annoMgr = annotManager;

    if (node) {
      this.loadDocument(node);
    }

    this.viewer.nativeElement.addEventListener('pageChanged',
      (e) => {
        const [pageNumber] = e.detail;
        this.logger.debug(`Current page is ${pageNumber}`);
      });


    this.viewer.nativeElement.addEventListener('zoomChanged',
      (e) => {
        const [zoom] = e.detail;
        //console.log("zoom = " + instance.getZoomLevel());
        //console.log("zoom + " + zoom);
        this.toolBar.setZoomText(zoom);

      });



    /*
    instance.docViewer.on('annotationsLoaded',
      () => {
        this.logger.info('annotations loaded');
      });

    /*
    instance.setHeaderItems(function (header) {
      var xfdfString;
      header.push({
        type: 'actionButton',
        img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',

        onClick: function () {
          xfdfString = annotManager.exportAnnotations({ links: false, widgets: false });
          WebViewerComp.http.post(this.baseUrl + environment.annotationServiceEndpoint + '/Save/' + WebViewerComp.docID, xfdfString, httpOptions).subscribe();

        }
      });
    });*/

    this.wvInstance.docViewer.on('documentLoaded', this.wvDocumentLoadedHandler);




    this.subscriptions.push(this.webviewerService.annotationSource$.subscribe(
      (data) => {

        this.logger.info(data);
        this.wvInstance.setToolMode(data);

      }
    ));

    this.subscriptions.push(this.webviewerService.stampSource$.subscribe(
      (data) => {

        this.logger.info('subStamp', data);


        // instance.openElements(['menuOverlay', 'leftPanel']);
        this.wvInstance.setToolMode('AnnotationCreateFreeText');
      }
    ));
  }

  loadDocument(node: FileTree): void {
    this.logger.debug('webviewer.component.ts:loadDocument(' + node.id + ')');
    const documentDownloadApi = localStorage.getItem('documentDownloadApi');
    const headers = new HttpHeaders()
      .set('Authorization', "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImVucmlxdWUub3Rob25AdHlsZXJ0ZWNoLmNvbSIsIm5iZiI6MTYxMTA3MTg5MSwiZXhwIjoxNjUwMzgwMjkxLCJpc3MiOiJlZm06cmV2aWV3OmFwaSIsImF1ZCI6ImVmbTpyZXZpZXc6YXBpIn0.qcDKGgq43o7FcIpmnNewMTkibh_Sj0c9Mqynq1u1ciY");
    this.loadDocumentFromApi(0, node);
  }

  loadDocumentFromApi(numberAttempts: number, node: FileTree) {
    const documentDownloadApi = localStorage.getItem('documentDownloadApi');
    if (!documentDownloadApi || documentDownloadApi.indexOf('http') < 0) {
      if (numberAttempts > 300) {
        this.toaster.pop('error', 'Error', "Failed to get document download api");
        return;
      }
      setTimeout(() => {
        numberAttempts++;
        this.loadDocumentFromApi(numberAttempts, node);
      }, 200);
    }
    else {
      //this.wvInstance.loadDocument("https://d3fqczym2l4tfa.cloudfront.net/A17_FlightPlan.pdf");
      this.wvInstance.loadDocument(documentDownloadApi + node.id);
      node.isViewed = true;
    }
  }




  nextPage(): void {


  }




  z: number;
  onZoomEvent(zoom: ZoomModel) {

    if (zoom.mode === 'ftw') {
      this.wvInstance.setFitMode('FitWidth');
    } else if (zoom.mode === 'ftp') {
      this.wvInstance.setFitMode('FitPage');
    } else if (zoom.mode === 'increment') {
      this.z = this.wvInstance.getZoomLevel();
      this.wvInstance.setFitMode('Zoom');
      this.wvInstance.setZoomLevel('' + ((this.z * 100) + (zoom.zoom * 100)));
    } else {
      this.wvInstance.setFitMode('Zoom');
      this.wvInstance.setZoomLevel(zoom.zoom);
    }
  }


  onLeftPanelEvent() {

    

    if (!this.wvInstance.isElementOpen('leftPanel')) {
      this.wvInstance.openElements(['leftPanel']);
      this.wvInstance.openElements(['thumbnailsPanel']);

    } else {
      this.wvInstance.closeElements(['leftPanel']);
    }


    
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  


}

