import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalizedString } from './localizedString';
import { Language } from './language';
import { HttpClient } from '@angular/common/http';
import { AbsoluteUrlService } from 'src/app/common/service/absolute-url.service';


@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private initialized = false;
  private subscription: BehaviorSubject<LocalizedString[]> = new BehaviorSubject<LocalizedString[]>([]);
  private currentLanguageSubscription: BehaviorSubject<string|undefined> = new BehaviorSubject<string|undefined>(undefined);

  private languageInitialized = false;
  private languagesSubscription: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([]);

  constructor(private httpClient: HttpClient, private urlService: AbsoluteUrlService) { }

  public setLanguage(languageCode: string | undefined) {
    this.initialized = true;

    this.currentLanguageSubscription.next(languageCode);

    return this.httpClient.get<LocalizedString[]>(this.urlService.convert('api/gettext/'))
    .subscribe(localizedStrings => {
      this.subscription.next(localizedStrings);
   });
  }

  public getCurrentLanguage(): BehaviorSubject<string|undefined> {
    return this.currentLanguageSubscription;
  }

  public getLocalization(): BehaviorSubject<LocalizedString[]> {
    if (!this.initialized) {
      this.setLanguage(navigator.language);
    }
    return this.subscription;
  }

  private setLanguages() {
    this.languageInitialized = true;

    return this.httpClient.get<Language[]>(this.urlService.convert('api/getlanguages/'))
    .subscribe(languages => {
      this.languagesSubscription.next(languages);
   });
  }
  public getlanguages():  BehaviorSubject<Language[]> {
    if (!this.languageInitialized) {
      this.setLanguages();
    }

    return this.languagesSubscription;
  }
}
