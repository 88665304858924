import { Injectable } from '@angular/core';

@Injectable()
export class FilterArray {

  filterArray<T>(items: T[], keyName: string, propertiesToFilterOn: string[], filter: string) {
    if (!items) return;
    var found: T[] = [];
    if (filter && filter.trim().length) {
      var splitFilters = filter.toLowerCase().trim().split(" ", 4);
      items.forEach(item => {
        splitFilters.forEach(filterText => {
          propertiesToFilterOn.forEach(property => {
            if (item[property].toLowerCase().indexOf(filterText) > -1 && !item['isDeleted']) {
              //Ensure item isn't already added.
              var alreadyAdded = found.findIndex(f => {
                f[keyName] == item[keyName]
              });
              if (alreadyAdded < 0) {
                found.push(item);
              }
            }
          })
        })
      })
    }
    else {
      found = items;
    }
    return found;
  }
} 
