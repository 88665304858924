export class Stamp {
  

  constructor(
    public stampID: string,
    public stampType: string,
    public stampData: string,
    public stampName: string
    
  ) {
  }


}
