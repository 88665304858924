import { Injectable } from '@angular/core';
import { ElementRef } from '@angular/core';

@Injectable()
export class HtmlPropertyHelper {

  setPagerIds(elementRef: ElementRef, sectionName: string) {
    setTimeout(() => {
      var pagerLinks = elementRef.nativeElement.getElementsByClassName('page-link');
      if (pagerLinks && pagerLinks.length) {
        for (let i = 0; i < pagerLinks.length; i++) {
          const pagerLink = pagerLinks[i] as HTMLElement;
          if (pagerLink) {
            if (pagerLink.textContent == 'Prev') {
              pagerLink.id = 'pager' + sectionName + 'PrevPage';
            }
            else if (pagerLink.textContent == 'Next') {
              pagerLink.id = 'pager' + sectionName + 'NextPage';
            }
            else {
              if (pagerLink.textContent.indexOf("(current)") > -1) {
                var numberPage = pagerLink.textContent.replace("(current)", "");
                pagerLink.id = 'pager' + sectionName + 'Page' + numberPage.trim();
              }
              else if (pagerLink.textContent.indexOf("...") > -1) {
                pagerLink.id = 'pager' + sectionName + 'Elipsis';
              }
              else {
                pagerLink.id = 'pager' + sectionName + 'Page' + pagerLink.textContent.trim()
              }
            }
          }
        }
      }
    }, 1);
  }

  setScrollbarId(elementRef: ElementRef) {
    setTimeout(() => {
      var scrollbars = elementRef.nativeElement.getElementsByClassName('ng-scrollbar-thumb');
      if (scrollbars && scrollbars.length) {
        scrollbars[0].id = 'envelopeScrollbar';
      }
    }, 1000);
  }
}
