import { Directive, ElementRef, Renderer2, OnInit, Input } from '@angular/core';
import { Strings } from './strings';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[localize]'
})
export class LocalizeDirective implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('localize') applyTo: string | undefined;
  constructor(private el: ElementRef, private renderer: Renderer2, private strings: Strings) {
    this.applyTo = undefined;
  }

  ngOnInit() {
    this.render();
  }

  private render() {

    if (!this.applyTo) {
      this.localizeInnerText();
      this.localizeAttribute('placeholder');
      this.localizeAttribute('aria-label');
      this.localizeAttribute('label');
      this.localizeAttribute('title');
      this.localizeAttribute('header-name');
      this.localizeAttribute('alt');
    } else {
      this.applyTo.split(',').forEach((attribute) => {
        if (attribute === 'innerText') {
          this.localizeInnerText();
        } else {
          this.localizeAttribute(attribute);
        }
      });
    }
  }

  private localizeAttribute(attribute: string) {
    if (this.el.nativeElement.getAttribute && this.el.nativeElement.getAttribute(attribute)) {
      this.strings.T(this.el.nativeElement.getAttribute(attribute)).subscribe(newText => this.renderer.setAttribute(this.el.nativeElement, attribute, newText));
    }
  }

  private localizeInnerText() {
    if (this.el.nativeElement.innerHTML && this.el.nativeElement.innerHTML.trim && this.el.nativeElement.innerHTML.trim()) {
      if (this.el.nativeElement.innerHTML.indexOf('<') === -1 && this.el.nativeElement.innerHTML.indexOf('>') === -1) {
        this.strings.T(this.el.nativeElement.innerHTML).subscribe(newText => this.renderer.setProperty(this.el.nativeElement, 'innerHTML', newText));
      }
    }
  }
}
