import { OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';
import { iFiling } from 'src/app/common/model/envelope/filing';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { iConfigCode } from 'src/app/common/model/config/config-code';
import { iCase } from 'src/app/common/model/envelope/case';
import { iFilingCode } from 'src/app/common/model/config/filing-code';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { QueueItem } from 'src/app/common/model/queue/queue-item';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

export class ActionBase implements OnInit, OnDestroy {
  @Input() rejectReasons: iConfigCode[];
  @Input() rejectReasonCodeId: number;
  @Input() filingCode: iFilingCode | undefined;
  @Input() filing: iFiling;
  @Input() filings: iFiling[];
  @Input() fileTree: FileTree;
  @Input() filingDescription: string;
  @Input() queueCodes: iConfigCode[];

  @Output() actionEvent: EventEmitter<iFiling> = new EventEmitter<iFiling>();

  modalReference: any;

  caseData: iCase;


  rejectComments: string = '';

  rejectReasonConfig: iDataFieldConfig = new DataFieldConfigDefaults(true, false, true);
  required: iDataFieldConfig = new DataFieldConfigDefaults(true, false, true);

  queueList: QueueItem[] = [];

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  constructor(public webviewerService: WebviewerService, public toaster: CustomToastrService) { }

  ngOnInit() {
    this.subscriptions.push(this.webviewerService.selectedDocumentFilingId.subscribe(id => {
      if (!id) {
        return;
      }
      this.closeModal();
    }));
  }

  doActionsCompleteProcessing(filing: iFiling) {
    this.actionEvent.emit(filing);
  }

  closeModal() {
    if (this.modalReference) {
      this.modalReference.close();
      this.modalReference = undefined;
    }
  }

  isModalOpen() {
    return this.modalReference != undefined;
  }

  exit() {
    this.modalReference = undefined;
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
