import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';

@Component({
  selector: 'app-annotation-draw-line-button',
  templateUrl: './annotation-draw-line-button.component.html',
  styleUrls: ['./annotation-draw-line-button.component.css'],
  
})
export class AnnotationDrawLineButtonComponent implements OnInit {

  state = "close";
  @Input() btnText: string;
  @Input() btnImage: string;


  constructor() { }

  ngOnInit() {
  }


  onClickBtn() {
    console.log("onClickBtn");
    if (this.state == "close") {
      this.state = "open";
    } else {
      this.state = "close";
    }
  }


  onClickClose() {
    this.state = "close";

    console.log("onClickTest");

  }

  onClickLineType(type: number) {
    this.state = "close";

    console.log("onClickLineType");

  }



}
