import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '../../common/service/connection.service';
import { CustomToastrService } from '../../common/service/custom-toastr.service';

@Component({
  selector: 'app-comm-test',
  templateUrl: './comm-test.component.html',
  styleUrls: ['./comm-test.component.css']
})
export class CommTestComponent implements OnInit {

  constructor(
    private connectionService: ConnectionService,
    private toaster: CustomToastrService,
    

  ) { }

  ngOnInit(): void {
  }


  onSendJson(json: string) {

    this.connectionService.sendApiMessage(json);
   
    //console.log(json);
  }

}
