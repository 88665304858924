import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {
  constructor(private modalService: NgbModal, public router: Router) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  onLoginClicked() {
    //this.modalService.open(LoginModalComponent, { size: 'lg', centered: true });
    this.router.navigate(['/queue']);
  }

}
