import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanFilter',
  pure: true
})

// Returns items in an array based on a boolean property.
// For example, this pipe returns all parties that aren't deleted:
//  <tr *ngFor="let party of parties | booleanFilter: 'isDeleted' : false" >

export class BooleanFilterPipe implements PipeTransform {
  transform(items: any[], property: string, value: boolean): any[] {
    if (!items) return [];
    return items.filter(it =>
      it[property] == undefined || it[property] === value);
  }
}


