export enum ActionType {
  Selected = 0,
  Cancel = 1,
  Accept = 2,
  ManualAccept = 3,
  Reject = 4,
  FailSubmission = 5,
  Routed = 6,
  EndReview = 7,
  SendToFiler = 8,
  Submitted = 9,
  ClickedEdit = 10,
  ClosedEditWithoutSaving = 11,
  ClosedEditWithSaving = 12,
  DeletedFilingInReview = 13,
  QuickEditedWithoutSaving = 14,
  QuickEditedWithSaving = 15,
  RedactFromReviewTool = 16,
  SaveCaseInformation = 17,
  SaveEnvelopeInformation = 18,
  SaveParty = 19,
  SaveFiling = 20,
  SaveFeeInformation = 21
}
