import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AbsoluteUrlService {

  constructor(private location: Location) { }

  convert(relativeUrl: string) {

    if (window.location.origin.indexOf('44352') > 0) {
      return this.location.prepareExternalUrl('../' + relativeUrl);
    }

     return this.location.prepareExternalUrl(environment.applicationBaseUrl + relativeUrl);
  }
}
