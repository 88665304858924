import { BaseRequest } from 'src/app/common/request/base-request';

export class SaveActionsInput implements BaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public filingActionList: FilingActionList
  ) { }
}

export class FilingActionList {
  constructor(
    public caseDataId: number,
    public envelopeId: number,
    public workItemId: number,
    public locationId: number,
    public caseCategoryId: number,
    public caseTypeId: number,
    public forwardedWorkQueueCodeId: number,
    public actions: Action[],
    public isEndReview: boolean
  ) { }
}

export class Action {
  constructor(
    public filingId: number,
    public actionType: number,
    public comments: string,
    public rejectReasonCodeID: number,
  ) { }
}


