import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { textPattern } from '../text-field/text-regex-validator';
import { DataFieldBase } from '../data-field-base';
import { ViewChild, ElementRef } from '@angular/core';
import { Strings } from '../../../localization/strings';

@Component({
  selector: 'app-text-area-field',
  templateUrl: './text-area-field.component.html',
  styleUrls: ['./text-area-field.component.scss']
})
export class TextAreaFieldComponent extends DataFieldBase implements OnInit, OnChanges, OnDestroy {
  constructor(strings: Strings) { super(strings) }

  @ViewChild('input')
  private input!: ElementRef;

  @Input()
  public forceRegex: string | undefined = undefined;

  @Input()
  public maxlength : number | undefined = undefined;

  @Input()
  public hideLabel: boolean = false;

  public focus() {
    this.input.nativeElement.focus();
  }

  protected getPatternValidator(): ValidatorFn | undefined {
    if (this.forceRegex) {
      return textPattern(this.forceRegex);
    }
    if (this.config && this.config.regularExpression) {
      return textPattern(this.config.regularExpression);
    }
    return undefined;
  }
}
