import { iCode } from 'src/app/common/model/envelope/code';

export class CaseCrossReference implements iCaseCrossReference {
  constructor(
    public codeId: number,
    public caseCrossReferenceNumber: string,
    public description: string = undefined,
    public isRequired: boolean = false,
    public isCreated: boolean = false,
    public isDeleted: boolean = false,
    public isDisabled: boolean = false,

    //viewmodel
    public validationRegex: string = undefined,
    public validationFailureCustomMessage: string = undefined
  ) { }
}

export interface iCaseCrossReference {
  codeId: number;
  caseCrossReferenceNumber: string;
  description: string;
  isRequired: boolean;
  isCreated: boolean;
  isDeleted: boolean;
  isDisabled: boolean;
  validationRegex: string;
  validationFailureCustomMessage: string;
}

