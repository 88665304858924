import { Component, OnInit } from '@angular/core';
import { EnvelopeService } from 'src/app/common/service/envelope.service';

@Component({
  selector: 'app-review-queue-view',
  templateUrl: './review-queue-view.component.html',
  styleUrls: ['./review-queue-view.component.css']
})
export class ReviewQueueViewComponent implements OnInit {

  constructor(private envelopeService: EnvelopeService) { }

  ngOnInit() {
    this.envelopeService.envelopeMetadata.next(undefined);
  }

}
