import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { DfcService } from 'src/app/common/service/dfc.service';
import { iFilingDetail } from 'src/app/common/model/envelope/filing-detail';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { iFilingCode } from 'src/app/common/model/config/filing-code';
import { iFilingComponentCode } from 'src/app/common/model/config/filing-component-code';
import { iDocument } from 'src/app/common/model/envelope/document';
import { FormGroup } from '@angular/forms';
import { iDocumentTypeCode } from 'src/app/common/model/config/document-type-code';
import { FilingService } from 'src/app/common/service/filing.service';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { FileTree } from 'src/app/common/model/envelope/file-tree';
import { FilingComponentCodeInput } from 'src/app/common/request/filing-component-code-input';
import { DocumentTypeInput } from 'src/app/common/request/document-type-input';
import { ApiRequestHelper } from 'src/app/common/helper/api-request-helper';
import { iMessage } from 'src/app/common/response/message';
import { ConnectionService } from 'src/app/common/service/connection.service';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

@Component({
  selector: 'app-edit-file',
  templateUrl: './edit-file.component.html',
  styleUrls: ['./edit-file.component.scss']
})
export class EditFileComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});

  @Input() filingBeforeEdits: iFilingDetail | undefined;
  @Input() isEditFileTabActive: boolean = false;
  @Input() showEditablePage: boolean = false;

  locationId: number;
  filingId: number;
  filing: iFilingDetail;
  fileTrees: FileTree[] = [];

  filingComponentCodes: iFilingComponentCode[] | undefined;

  filingCode: iFilingCode | undefined; 
  filingCodes: iFilingCode[] | undefined;
  filingCodeConfig: iDataFieldConfig | undefined;
  //todo: filter filings by inital or subsequent

  documentTypeCodes: iDocumentTypeCode[] | undefined;
  documentTypeCode: iDocumentTypeCode | undefined;
  documentTypeConfig: iDataFieldConfig | undefined;

  filingDescriptionConfig: iDataFieldConfig | undefined;
  refNumberConfig: iDataFieldConfig | undefined;
  commentsConfig: iDataFieldConfig | undefined;
  courtesyCopiesConfig: iDataFieldConfig | undefined;
  preliminaryCopiesConfig: iDataFieldConfig | undefined;
  documentDescriptionConfig: iDataFieldConfig | undefined;
  dueDateAvailableForReviewersConfig: iDataFieldConfig | undefined;

  removeCalendarPlacement: string = '';
  isFilingCodeInvalid: boolean;
  invalidFilingCode: string;
  isDirty: boolean;
  isValid: boolean = true;
  public stopDirtyCheck: boolean;
  isGetDocumentTypesComplete: boolean;

  subscriptions: Subscription[] = [];
  signalrSub: Subscription;

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, private filingService: FilingService, private webviewerService: WebviewerService,
    private dfcService: DfcService, private toaster: CustomToastrService, private connectionService: ConnectionService, private apiRequestHelper: ApiRequestHelper) { }

  ngOnInit() {
    this.getApiMessage();

    this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
      if (!_case) {
        return;
      }
      this.locationId = _case.location.nodeId;
      this.subscriptions.push(this.filingService.selectedFilingDetail.subscribe(_filing => {
        if (!_filing) {
          return;
        }
        this.filing = _filing;

        this.getFilingConfigs();
        this.selectFirstDocument();
        this.stopDirtyCheck = false;
        this.detectChanges();
      }))
    }));

    this.subscriptions.push(this.filingService.filingCodes.subscribe(codes => {
      if (!codes) {
        return;
      }
      this.filingCodes = codes;
      this.setEditablePage();
    }));

    this.subscriptions.push(this.webviewerService.documentFileTree.subscribe(result => {
      if (result) {
        this.fileTrees = result;
      }
    }));

    this.subscriptions.push(this.dfcService.isDfcReady.subscribe(isReady => {
      if (isReady) {
        this.initializeDataFieldConfigs();
      }
    }));
  }

  getApiMessage() {
    this.signalrSub = this.connectionService.signalrSource$.subscribe(
      (data) => {
        const message = JSON.parse(data) as iMessage;
        switch (message.action) {
          case 'getfilingcomponentcodes': {
            if (message.isSuccess === true) {
              var filingComponentCodes = message.taskresult.items as iFilingComponentCode[];
              if (filingComponentCodes && filingComponentCodes.length && filingComponentCodes[0].isAdditionalService) {
                  return;
              }
              this.filingBeforeEdits.documents.forEach(d => {
                //Validate that current filing's filingComponent have an entry in the updated filingCodeId's filingComponents
                var filingComponentCodeId = d.filingComponentCode ? d.filingComponentCode.codeId : 0;
                var found = filingComponentCodes.findIndex(c => c.codeId == filingComponentCodeId);
                if (found < 0) {
                  //"Unable to update Filing Code since it requires different document types." error is displayed. Filing code is reset to original value.
                  this.isFilingCodeInvalid = true;
                  if (this.filingCode) {
                    this.invalidFilingCode = this.filingCode.description;
                  }
                  this.filing.filingCode.codeId = this.filingBeforeEdits.filingCode.codeId;
                }
              })
              this.setEditablePage();
            }
            else {
              this.toaster.pop('error', 'Error', 'Unable to get filing component codes: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
          case 'getdocumenttypecodes': {
            if (message.isSuccess === true) {
              if (message.taskresult.totalItems > 0) {
                this.documentTypeCodes = message.taskresult.items as iDocumentTypeCode[];
                this.isGetDocumentTypesComplete = true;
                this.setEditablePage();
              }
            }
            else {
              this.toaster.pop('error', 'Error', 'An error occurred getting document codes: ' + message.error.code + ': ' + message.error.cause);
            }
            break;
          }
        }
      });
  }

  //page is read-only until all the configs are loaded.This prevents a false "cannot proceed until the error is fixed" toast if user selects a different page too soon.
  setEditablePage() {
    if (this.filingCodes && this.documentTypeCodes && this.isGetDocumentTypesComplete) {
      this.showEditablePage = true;
    }
  }

  filingCodeChanged(filingCode: iFilingCode) {
    if (this.filingCode == filingCode) {
      return;
    }
    this.filingCode = filingCode;
    if (filingCode && this.filingBeforeEdits && this.filingBeforeEdits.filingCode.codeId && this.filingBeforeEdits.filingCode.codeId != filingCode.codeId) {
      //event is called twice. Reset isFilingCodeInvalid only once
      if (this.filingCode && this.filingCode.codeId != this.filingBeforeEdits.filingCode.codeId) {
        this.isFilingCodeInvalid = false;
      }
      if (this.filing) {
        this.getFilingConfigs();
      }
    }
  }

  documentTypeChanged(document: iDocument, documentType: iDocumentTypeCode) {
    if (documentType) {
      document.isSecure = documentType.isConfidential;
    }
    else {
      document.isSecure = false;
    }
  }

  getFilingConfigs() {
    this.isGetDocumentTypesComplete = false;

    //filingComponents
    var inputFilingComponent = new FilingComponentCodeInput(null, null, null, 'getfilingcomponentcodes', this.locationId, this.filing.filingCode.codeId, false);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputFilingComponent));

    //additionalServices
    var inputAdditionalService = new FilingComponentCodeInput(null, null, null, 'getfilingcomponentcodes', this.locationId, this.filing.filingCode.codeId, true);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputAdditionalService));

    const inputDocumentType = new DocumentTypeInput(null, null, null, 'getdocumenttypecodes', this.locationId, this.filing.filingCode.codeId);
    this.connectionService.sendApiMessage(this.apiRequestHelper.buildMessage(inputDocumentType));
  }

  closeFilingCodeInvalid() {
    this.isFilingCodeInvalid = false;
  }

  detectChanges() {
    this.form.valueChanges.subscribe(change => {
      if (this.stopDirtyCheck) {
        return;
      }
      this.filing.isUpdated = this.isDirty = this.form.dirty;
      this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
    });

    this.subscriptions.push(this.form.statusChanges.subscribe(status => {
      if (this.stopDirtyCheck || !this.showEditablePage) {
        return;
      }
      if (status == "INVALID") {
        this.isValid = false;
        this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
      }
      else {
        this.isValid = true;
        this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
      }
    }));
  }

  selectFirstDocument() {
    var idx = this.fileTrees.findIndex(t => t.filingId == this.filing.filingId)
    if (idx > -1) {
      if (this.fileTrees[idx].children) {
        var tree = this.fileTrees[idx].children[0];
        tree.isViewed = true;
        this.webviewerService.selectedDocument.next(tree);
      }
    }
  }

  selectDocument(document: iDocument) {
    var idx = this.fileTrees.findIndex(t => t.filingId == this.filing.filingId)
    if (idx > -1) {
      if (this.fileTrees[idx].children) {
        var idx2 = this.fileTrees[idx].children.findIndex(c => c.id == document.securityToken);
        if (idx2 > -1) {
          var tree = this.fileTrees[idx].children[idx2];
          tree.isViewed= true;
          this.webviewerService.selectedDocument.next(tree);
          this.webviewerService.selectedDocumentFilingId.next(tree.filingId)
        }
      }
    }
  }

  getFilingCodeDescription() {
    if (this.filingCodes) {
      var filingCode = this.filingCodes.find(fc => fc.codeId == this.filing.filingCode.codeId);
      if (filingCode) {
        return filingCode.description;
      }
    }
    else {
      return null;
    }
  }

  initializeDataFieldConfigs() {
    this.filingCodeConfig = this.dfcService.getConfig('FilingFilingCode', true, false, true);
    this.filingDescriptionConfig = this.dfcService.getConfig('FilingFilingDescription', true, false, false);
    this.refNumberConfig = this.dfcService.getConfig('FilingReferenceNumber', true, false, true);
    this.commentsConfig = this.dfcService.getConfig('FilingFilingComments', true, false, true);
    this.courtesyCopiesConfig = this.dfcService.getConfig('FilingCourtesyCopies', true, false, false);
    this.preliminaryCopiesConfig = this.dfcService.getConfig('FilingPreliminaryNotifications', true, false, false);
    this.documentTypeConfig = this.dfcService.getConfig('DocumentType', true, false, true);
    this.documentDescriptionConfig = this.dfcService.getConfig('DocumentDescription', true);
    this.dueDateAvailableForReviewersConfig = this.dfcService.getConfig('DueDateAvailableForReviewers', true);
  }

  getUpdates() {
    return this.filing;
  }

  resetForm() {
    this.form.reset();
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }   
    this.signalrSub.unsubscribe();
  }
}
