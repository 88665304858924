import { Component, OnInit, Input } from '@angular/core';
import { iDataFieldConfig } from 'src/app/common/model/config/data-field-config';

@Component({
  selector: 'app-dfc-wrapper',
  templateUrl: './dfc-wrapper.component.html',
  styleUrls: ['./dfc-wrapper.component.scss']
})
export class DfcWrapperComponent implements OnInit {

  @Input()
  public config: iDataFieldConfig | undefined;

  constructor() { }

  ngOnInit() {
  }

}
