import { BaseRequest } from 'src/app/common/request/base-request';
import { iFilingDetail } from 'src/app/common/model/envelope/filing-detail';

export class SaveFileInput implements BaseRequest {
  constructor(
    public tenant: string,
    public messageId: string,
    public connectionId: string,
    public action: string,
    public filingDetail: iFilingDetail
  ) { }
}



