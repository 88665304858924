import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { iEnvelope } from 'src/app/common/model/envelope/envelope';

export class ZoomModel {

  private subscriptions: Subscription[] = [];

  constructor(public zoom: number, public mode : string, public text: string ) {
  }

}


@Component({
  selector: 'app-webviewer-client',
  templateUrl: './webviewer-client.component.html',
  styleUrls: ['./webviewer-client.component.css']
})


export class WebviewerClientComponent implements OnInit {


  currentZoom: ZoomModel = new ZoomModel(1, "ftp", "Fit To Page");
  //currentZoomMode: string = "";
  //currentZoomText: string = "Fit To Page";
  @Output() zoomEvent: EventEmitter<ZoomModel> = new EventEmitter<ZoomModel>();
  @Output() leftPanelEvent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('fileNameButton') fileNameButton: ElementRef;
  fileName: string;
  isWebViewerReady: boolean;

  envelope: iEnvelope;
  noteCount: string = '8';

  subViewerStatus: Subscription;
  subscriptions: Subscription[] = [];


  constructor(
    private webviewerService: WebviewerService, private envelopeService: EnvelopeService
  )
  {}


  ngOnInit() {
    this.subscriptions.push(this.webviewerService.selectedDocument.subscribe(node => {
      if (!node) {
        this.fileName = undefined;
      }
      else {
        this.fileName = node.fileName;
        if (node.isManuallySelected) {
          this.fileNameButton.nativeElement.click();
        }
        this.webviewerService.selectedDocumentFilingId.next(node.filingId);
        node.isManuallySelected = false;
      }
    }));

    this.subscriptions.push(this.webviewerService.webviewerReady.subscribe(isWebViewerReady => {
      if (isWebViewerReady) {
        this.isWebViewerReady = true;
      }
    }));
  }


  ngAfterViewInit(): void {
    this.subViewerStatus = this.webviewerService.viewerStatusSource$.subscribe(
      (data) => {
        console.log(data);

      }
    );
  }

  setZoomText(zoom: number) {
    if (this.currentZoom.mode === "increment") {
      this.currentZoom.text = (zoom * 100).toFixed(2) + " %";
    }
  }


  setZoom(zoom: number, mode: string) {
    
    this.currentZoom.mode = mode;

    if (mode === "ftp") {
      this.currentZoom.text = "Fit To Page";
    }
    else if (mode === "ftw") {
      this.currentZoom.text = "Fit To Width";
    } else if ((mode === "zoom") || (mode === "increment")) {
      this.currentZoom.text = (zoom * 100) + " %";
    }
    
    this.zoomEvent.emit(new ZoomModel(zoom, mode, this.currentZoom.text));
  }

  onClickNotes() {

  }

  setLeftPanel() {
    this.leftPanelEvent.emit();
  }
  

}
