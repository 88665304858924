import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActionType } from 'src/app/common/enum/action-type';
import { FormGroup } from '@angular/forms';
import { ActionBase } from 'src/app/webviewer/actions/action-base.component';
import { WebviewerService } from 'src/app/common/service/webviewer.service';
import { CustomToastrService } from 'src/app/common/service/custom-toastr.service';

@Component({
  selector: 'app-accept-action',
  templateUrl: './accept-action.component.html',
  styleUrls: ['./accept-action.component.css']
})

export class AcceptActionComponent extends ActionBase {
  public form: FormGroup = new FormGroup({});
  @ViewChild('acceptModal') acceptModal;

  constructor(private modalService: NgbModal, private config: NgbModalConfig, public webviewerService: WebviewerService, public toaster: CustomToastrService) {
    super(webviewerService, toaster)
    config.backdrop = false;
    config.keyboard = false;
    config.windowClass = 'custom-modal';
  }

  showModal() {
    this.modalReference = this.modalService.open(this.acceptModal);
    this.modalReference.result.then(save => {
      this.save();
    }, (exit) => {
      this.exit();
    });
  }

  save() {
    if (!this.isModalOpen()) {
      return;
    }

    this.fileTree.actionType = this.filing.actionType = ActionType.Accept;
    this.toaster.pop('success', 'Saved', 'Filing "' + this.filingDescription + '" has been Accepted.');
    this.modalReference.close();
    this.modalReference = undefined;
    this.doActionsCompleteProcessing(this.filing);
  }

}
