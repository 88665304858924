import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notes-information',
  templateUrl: './notes-information.component.html',
  styleUrls: ['./notes-information.component.css']
})
export class NotesInformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
