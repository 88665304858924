import { BasePagingFilter } from './base-paging-filter';

export class FilingFilter extends BasePagingFilter {
  constructor(
    perPage: number = 10,
    pageNumber: number = 1,
  ) {
    super(perPage, pageNumber);
  }
}
