import { Pipe, PipeTransform } from '@angular/core';
import { Strings } from './strings';

@Pipe({
  name: 'localizeTranslate',
  pure: true
})
export class LocalizeTranslatePipe implements PipeTransform {

  constructor(private strings: Strings) { }

  transform(value: string, args: object[] | undefined): any {
    return this.strings.T(value, args).getValue();
  }
}
