import { Component, OnInit } from '@angular/core';
import { QueueService } from '../../../common/service/queue.service';

@Component({
  selector: 'app-review-queue-filter-envelope-id',
  templateUrl: './review-queue-filter-envelope-id.component.html',
  styleUrls: ['./review-queue-filter-envelope-id.component.css']
})
export class ReviewQueueFilterEnvelopeIdComponent implements OnInit {

  constructor(private queueService: QueueService ) { }

  envelopeId: number;

  ngOnInit(): void {
  }

  setEnvelopeIdFilter(eid: any) {
    console.log(event);
    this.queueService.filterEnvelopeId = eid;
  }
  


}
