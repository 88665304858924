import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvelopeService } from 'src/app/common/service/envelope.service';
import { CaseService } from 'src/app/common/service/case.service';
import { DfcService } from 'src/app/common/service/dfc.service';
import { iEnvelope } from 'src/app/common/model/envelope/envelope';
import { iFilingDetail } from 'src/app/common/model/envelope/filing-detail';
import { FilingAssociatedParty } from 'src/app/common/model/envelope/filing-associated-party';
import { FormGroup, FormControl } from '@angular/forms';
import { PartyService } from 'src/app/common/service/party.service';
import { iParty } from 'src/app/common/model/envelope/party';
import { FilingService } from 'src/app/common/service/filing.service';
import { PartyFilter } from 'src/app/common/service/filter/party-filter';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ComponentPage } from 'src/app/common/model/observable/edit-status';
import { iDataFieldConfig, DataFieldConfigDefaults } from 'src/app/common/model/config/data-field-config';
import { HtmlPropertyHelper } from 'src/app/common/helper/html-property-helper';
import { Code } from 'src/app/common/model/envelope/code';

@Component({
  selector: 'app-edit-associated-parties',
  templateUrl: './edit-associated-parties.component.html',
  styleUrls: ['./edit-associated-parties.component.css']
})
export class EditAssociatedPartiesComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  searchField: FormControl;

  @Input() isAssocPartyTabActive: boolean = false;

  envelope: iEnvelope;
  filing: iFilingDetail;
  partyFilter: PartyFilter = new PartyFilter();
  parties: iParty[];
  partiesAll: iParty[];
  newId: number = 0;
  showSelectedPartiesOnly: boolean = false;
  isSelectedConfig: iDataFieldConfig | undefined;
  isAssociatedPartyRequired: boolean = false;
  isAssociatedPartySelected: boolean = false;

  isDirty: boolean;
  isValid: boolean = true;
  public stopDirtyCheck: boolean;

  numberOfResults: number = 0;
  get currentPage(): number {
    return this.partyService.currentPage.value;
  }
  set currentPage(val: number) {
    this.filter(val);
  }

  private subscriptions: Subscription[] = [];

  constructor(private envelopeService: EnvelopeService, private caseService: CaseService, private partyService: PartyService, private dfcService: DfcService,
    private filingService: FilingService, private htmlPropertyHelper: HtmlPropertyHelper, public element: ElementRef) { }

  ngOnInit() {
    this.isSelectedConfig = new DataFieldConfigDefaults(true, false, false);

    this.subscriptions.push(this.filingService.selectedFilingDetail.subscribe(_filing => {
      if (!_filing) {
        return;
      }
      this.filing = _filing;
      this.stopDirtyCheck = false;
      this.detectChanges();
      this.subscriptions.push(this.caseService.caseToReview.subscribe(_case => {
        if (!_case) {
          return;
        }
        this.parties = _case.parties;
        this.partiesAll = _case.parties.concat();
        this.setSelectedParties();
        this.selectionChanged();
        this.subscribeToFilter();
        this.subscribeToFilterResults();
      }));
    }));


    this.searchField = new FormControl('');
    this.searchField.valueChanges
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        tap((text) => {
          this.filter();
        })
      )
      .subscribe(res => console.log(res))
  }

  subscribeToFilter() {
    this.subscriptions.push(this.partyService.partyFilter.subscribe(filter => { 
      this.partyService.getFilteredCaseParties(this.parties, this.partyFilter);
    }));

    this.subscriptions.push(this.dfcService.isDfcReady.subscribe(isReady => {
      if (isReady) {
        var associatedPartiesConfig = this.dfcService.getConfig('FilingEventCaseParties', true, false, false);
        this.isAssociatedPartyRequired = associatedPartiesConfig.isRequired;
      }
    }));

  }

  subscribeToFilterResults() {
    this.subscriptions.push(this.partyService.filterCasePartyResult.subscribe(result => {
      this.numberOfResults = result.totalItems;
      if (result.items) {
        this.parties = result.items.concat();
      }
      else {
        this.parties = undefined;
      }
      this.htmlPropertyHelper.setPagerIds(this.element, '');
    }));
  }

  setSelectedParties() {
    this.parties.forEach(party => {
      party.isVisible = true;
      var index = this.filing.associatedParties.findIndex(ap => ap.casePartyId == party.casePartyId && !ap.isDeleted);
      if (index > -1) {
        party.isSelected = true;
      }
      else {
        party.isSelected = false;
      }
    });
  }

  //Returns all parties if filter is empty
  filter(pageNumber: number = 1) {
    this.partyService.getFilteredCaseParties(this.partiesAll, this.partyFilter, pageNumber);
  }

  showSelectedOnly() {
    this.showSelectedPartiesOnly = !this.showSelectedPartiesOnly;
    this.numberOfResults = 0;
    if (this.showSelectedPartiesOnly) {
      var selected = this.parties.filter(cp => cp.isSelected);
      if (selected) {
        this.numberOfResults = selected.length;
      }
    }
    else {
      this.numberOfResults = this.parties.length;
    }
  }

  selectionChanged() {
    var found = this.parties.filter(p => p.isSelected);
    this.isAssociatedPartySelected = !!(found && found.length);
    if (this.isAssociatedPartyRequired && !this.isAssociatedPartySelected) {
      this.isValid = false;
      this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
    }
    else {
      this.isValid = true;
      this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
    }
  }

  resetForm() {
    this.showSelectedPartiesOnly = false;
    this.form.reset();
  }

  getUpdates() {
    this.parties.forEach(party => {
      var index = this.filing.associatedParties.findIndex(ap => ap.casePartyId == party.casePartyId);
      if (index > -1) {
        if (party.isSelected) {
          //Remove isDeleted
          this.filing.associatedParties[index].isDeleted = false;
        }
        else {
          //Delete associatedParty
          this.filing.associatedParties[index].isDeleted = true;
        }
      }
      else {
        if (party.isSelected) {
          //Add new associatedParty
          this.newId--;
          //todo get partyName/partyType below
          var newAssociatedParty = new FilingAssociatedParty(party.casePartyId, "", new Code(undefined, undefined), true, false)
          this.filing.associatedParties.push(newAssociatedParty);
        }
      }
    });
    return this.filing.associatedParties;
  }

  detectChanges() {
    this.form.valueChanges.subscribe(change => {
      if (this.stopDirtyCheck) {
        return;
      }
      this.isDirty = this.form.dirty;
      this.envelopeService.editStatus.next({ isValid: this.isValid, isDirty: this.isDirty, currentComponent: ComponentPage.Filing, errorMessage: '' });
    });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}
